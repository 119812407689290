import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TimeConfigForm from './TimeConfigForm';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Paper from '@mui/material/Paper';
import { DeleteTimeScheduleExceptionDate, DeleteTimeScheduleSlot, getTimescheduleExceptonDateApi, getTimescheduleslotsApi } from '../../../../services/TimeConfigServices';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import AddSpecialDayHoliday from './AddSpecialDayHolidayModel';

const TimeConfig = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch()
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [isEditException, setIsEditException] = useState(false);
  const [collectionSlots, setCollectionSlots] = useState([])
  const [deliverySlots, setDeliverySlots] = useState([])
  const [openclosetimeSlots, setOpenclosetimeSlots] = useState([])
  const [openclosetime, setOpenclosetime] = useState([])
  const [collectionTime, setCollectionTime] = useState([])
  const [deliveryTime, setDeliveryTime] = useState([])
  const [exceptionData, setExceptionData] = useState(null);
  const [serviceName, setServiceName] = useState(null);

  const handleAddClick = (name) => {
    setIsFormExpanded(true);
    setEditItemIndex(null);
    setEditData([]);
    setServiceName(name)
  };

  const handleEditClick = (index) => {
    setIsFormExpanded(true);
    setEditItemIndex(index.slot_id);
    setEditData(index);
  };

  const handleFormClose = () => {
    setIsFormExpanded(false);
    setEditItemIndex(null);
    setEditData([]);
  };

  useEffect(() => {
    dispatch(getTimescheduleExceptonDateApi((res) => {
      setCollectionSlots(res.data.collection)
      setDeliverySlots(res.data.delivery)
      setOpenclosetimeSlots(res.data.openclosetime)
    }))
  }, [modalShow])

  useEffect(() => {
    dispatch(getTimescheduleslotsApi((res) => {
      setOpenclosetime(res.data.openclosetime)
      setCollectionTime(res.data.collection)
      setDeliveryTime(res.data.delivery)
    }))
  }, [isFormExpanded])

  const handleDeleteException = (row) => {
    const id = row.id
    swal({
      title: "Are you sure?",
      text:
        "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: {
        confirm: 'Ok',
        cancel: 'Cancel'
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Exception has been deleted!", {
          icon: "success",
        });
        dispatch(DeleteTimeScheduleExceptionDate({ id }, (result) => {
          dispatch(getTimescheduleExceptonDateApi((res) => {
            setCollectionSlots(res.data.collection)
            setDeliverySlots(res.data.delivery)
            setOpenclosetimeSlots(res.data.openclosetime)
          }))
        }))
      }
    })
  }

  const handleDeleteTimeScheduleSlot = (row) => {
    const slot_id = row.slot_id
    swal({
      title: "Are you sure?",
      text:
        "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: {
        confirm: 'Ok',
        cancel: 'Cancel'
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Slot has been deleted!", {
          icon: "success",
        });
        dispatch(DeleteTimeScheduleSlot({ slot_id }, (result) => {
          dispatch(getTimescheduleslotsApi((res) => {
            setOpenclosetime(res.data.openclosetime)
            setCollectionTime(res.data.collection)
            setDeliveryTime(res.data.delivery)
          }))
        }))
      }
    })
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">{t('timeschedulescreen.title')}</h4>
      </div>
      <div className="card-body">
        <h5>{t('timeconfig.openclosetime')}</h5>
        {openclosetime &&
          openclosetime.length > 0 &&
          <div className="table-responsive">
            <table className='timeConfigTable table mb-0 ' >
              <tbody>
                {openclosetime.map((item, index) => {
                  let weekDays = Object.keys(item.WeekDay).filter(day => item.WeekDay[day]);
                  let week = weekDays.map(day => moment().weekday(day).format('dddd'));
                  item.week = week.join(',');
                  const isEditOpen = isFormExpanded && editItemIndex == item.slot_id;
                  return (
                    isEditOpen ? (
                      <tr key={index}>
                        <td colSpan={4} className='p-0'>
                          <TimeConfigForm
                            setServiceName={setServiceName}
                            service={'openclosetime'}
                            setIsFormExpanded={setIsFormExpanded}
                            onClose={handleFormClose}
                            editData={editData}
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td className='col-lg-9'>{item.week}</td>
                        <td className='col-1'>{item.StartTime}</td>
                        <td className='col-1'>{item.EndTime}</td>
                        <td className='text-end col-1'>
                          <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEditClick(item)} > <i className="fas fa-pencil-alt"></i></button>
                          <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => handleDeleteTimeScheduleSlot(item)} > <i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                      // <Accordion expanded={expanded === index} key={index} onChange={handleChange(index)} >
                      //   <AccordionSummary >
                      //     <Typography sx={{ width: '50%', flexShrink: 0 }} className='mt-2 '>{item.week}</Typography>
                      //     <Typography sx={{ width: '20%', flexShrink: 0 }} className='mt-2'>{item.StartTime}</Typography>
                      //     <Typography sx={{ width: '20%', flexShrink: 0 }} className='mt-2'>{item.EndTime}</Typography>
                      //     <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEditClick(index)} > <i className="fas fa-pencil-alt"></i></button>
                      //     <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => handleDeleteTimeScheduleSlot(item)} > <i className="fa fa-trash"></i></button>
                      //   </AccordionSummary>
                      //   <AccordionDetails>
                      //     {/* Additional details for the accordion */}
                      //   </AccordionDetails>
                      // </Accordion>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
        }
        {(!isFormExpanded || editItemIndex !== null || serviceName != 'openclosetime') && (
          <button className="btn addhourButton p-0" onClick={() => handleAddClick('openclosetime')}>
            <u>{t('timeconfig.addopenclosehours')}</u>
          </button>
        )}

        {isFormExpanded && editItemIndex === null && serviceName == 'openclosetime' && (
          <TimeConfigForm
            setServiceName={setServiceName}
            service={'openclosetime'}
            setIsFormExpanded={setIsFormExpanded}
            onClose={handleFormClose}
            editData={editData}
          />
        )}

        <h5 className='pt-3'>{t('timeconfig.collectiontime')}</h5>
        {collectionTime &&
          collectionTime.length > 0 &&
          <div className="table-responsive">
            <table className='timeConfigTable table mb-0 ' >
              <tbody>
                {collectionTime.map((item, index) => {

                  let weekDays = Object.keys(item.WeekDay).filter(day => item.WeekDay[day]);

                  let week = weekDays.map(day => moment().weekday(day).format('dddd'));

                  item.week = week.join(',');

                  const isEditOpen = isFormExpanded && editItemIndex === item.slot_id;
                  return (
                    isEditOpen ? (
                      <tr key={index}>
                        <td colSpan={4} className='p-0'>
                          <TimeConfigForm
                            setServiceName={setServiceName}
                            service={'collection'}
                            setIsFormExpanded={setIsFormExpanded}
                            onClose={handleFormClose}
                            editData={editData}
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td className='col-lg-9' >{item.week}</td>
                        <td className='col-1'>{item.StartTime}</td>
                        <td className='col-1'>{item.EndTime}</td>
                        <td className='text-end col-1'>
                          <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEditClick(item)} > <i className="fas fa-pencil-alt"></i></button>
                          <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => handleDeleteTimeScheduleSlot(item)} > <i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                      // <Accordion expanded={expanded === index} key={index} onChange={handleChange(index)}>
                      //     <AccordionSummary >
                      //         <Typography sx={{ width: '50%', flexShrink: 0 }} className='mt-2'>{item.week}</Typography>
                      //         <Typography sx={{ width: '20%', flexShrink: 0 }} className='mt-2'>{item.StartTime}</Typography>
                      //         <Typography sx={{ width: '20%', flexShrink: 0 }} className='mt-2'>{item.EndTime}</Typography>
                      //         <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEditClick(index)} > <i className="fas fa-pencil-alt"></i></button>
                      //         <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => handleDeleteTimeScheduleSlot(item)} > <i className="fa fa-trash"></i></button>

                      //     </AccordionSummary>
                      //     <AccordionDetails>
                      //         {/* Additional details for the accordion */}
                      //     </AccordionDetails>
                      // </Accordion>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>}

        {(!isFormExpanded || editItemIndex !== null || serviceName != 'collection') && (
          <button className="btn addhourButton p-0" onClick={() => handleAddClick('collection')}>
            <u>{t('timeconfig.addcollectionhours')}</u>
          </button>
        )}

        {isFormExpanded && editItemIndex === null && serviceName == 'collection' && (
          <TimeConfigForm
            setServiceName={setServiceName}
            service={'collection'}
            setIsFormExpanded={setIsFormExpanded}
            onClose={handleFormClose}
            editData={editData}
          />
        )}

        <h5 className='pt-3'>{t('timeconfig.deliverytime')}</h5>
        {deliveryTime &&
          deliveryTime.length > 0 &&
          <div className="table-responsive">
            <table className='timeConfigTable table mb-0 ' >
              <tbody>
                {deliveryTime.map((item, index) => {
                  let weekDays = Object.keys(item.WeekDay).filter(day => item.WeekDay[day]);

                  let week = weekDays.map(day => moment().weekday(day).format('dddd'));

                  item.week = week.join(',');

                  const isEditOpen = isFormExpanded && editItemIndex === item.slot_id;
                  return (
                    isEditOpen ? (
                      <tr key={index}>
                        <td colSpan={4} className='p-0'>
                          <TimeConfigForm
                            setServiceName={setServiceName}
                            service={'delivery'}
                            setIsFormExpanded={setIsFormExpanded}
                            onClose={handleFormClose}
                            editData={editData}
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td className='col-9' >{item.week}</td>
                        <td className='col-1'>{item.StartTime}</td>
                        <td className='col-1'>{item.EndTime}</td>
                        <td className='col-1 text-end'>
                          <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEditClick(item)} > <i className="fas fa-pencil-alt"></i></button>
                          <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => handleDeleteTimeScheduleSlot(item)} > <i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                      // <Accordion expanded={expanded === index} key={index} onChange={handleChange(index)}>
                      //     <AccordionSummary >
                      //         <Typography sx={{ width: '50%', flexShrink: 0 }} className='mt-2'>{item.week}</Typography>
                      //         <Typography sx={{ width: '20%', flexShrink: 0 }} className='mt-2'>{item.StartTime}</Typography>
                      //         <Typography sx={{ width: '20%', flexShrink: 0 }} className='mt-2'>{item.EndTime}</Typography>
                      //         <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEditClick(index)} > <i className="fas fa-pencil-alt"></i></button>
                      //         <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => handleDeleteTimeScheduleSlot(item)} > <i className="fa fa-trash"></i></button>
                      //     </AccordionSummary>
                      //     <AccordionDetails>
                      //         {/* Additional details for the accordion */}
                      //     </AccordionDetails>
                      // </Accordion>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>}

        {(!isFormExpanded || editItemIndex !== null || serviceName != 'delivery') && (
          <button className="btn addhourButton p-0" onClick={() => handleAddClick('delivery')}>
            <u>{t('timeconfig.adddeliveryhours')}</u>
          </button>
        )}

        {isFormExpanded && editItemIndex === null && serviceName == 'delivery' && (
          <TimeConfigForm
            setServiceName={setServiceName}
            service={'delivery'}
            setIsFormExpanded={setIsFormExpanded}
            onClose={handleFormClose}
            editData={editData}
          />
        )}
      </div>
      {/* <div className="card-body pt-0">
        <PickupService />
      </div>
      <div className="card-body pt-0">
        <DeliveryService />
      </div> */}

      <hr />

      <div className="card-body pt-0">
        <div><b>{t('timeconfig.exceptions')}</b> <InfoOutlinedIcon /></div>
        <Paper elevation={3} className='.MuiPaper-elevation24'>
          <div className='card-body mt-2 table-responsive'>
            {collectionSlots && collectionSlots.length > 0 ? <h5>{t('timeconfig.collection')}</h5> : ""}
            {collectionSlots &&
              collectionSlots.length > 0 ?
              <table className='timeConfigTable table' >
                <tbody>
                  {collectionSlots.map((item, index) => (
                    <tr key={index}>
                      <td >{item.exception_title} ({item.start_date} - {item.end_date})</td>
                      <td className='text-end'>
                        <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => { setIsEditException(true); setExceptionData(item); setModalShow(true) }} > <i className="fas fa-pencil-alt"></i></button>
                        <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => { handleDeleteException(item) }} > <i className="fa fa-trash"></i></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> : " "}

            {deliverySlots && deliverySlots.length > 0 ? <h5>{t('timeconfig.delivery')}</h5> : ""}
            {deliverySlots &&
              deliverySlots.length > 0 ?
              <table className='timeConfigTable table'>
                <tbody>
                  {deliverySlots.map((item, index) => (
                    <tr key={index}>
                      <td>{item.exception_title} ({item.start_date} - {item.end_date})</td>
                      <td className='text-end'>
                        <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => { setIsEditException(true); setExceptionData(item); setModalShow(true) }} > <i className="fas fa-pencil-alt"></i></button>
                        <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => { handleDeleteException(item) }} > <i className="fa fa-trash"></i></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> : " "}

            {openclosetimeSlots && openclosetimeSlots.length > 0 ? <h5>{t('timeconfig.openclosetime')}</h5> : ""}
            {openclosetimeSlots &&
              openclosetimeSlots.length > 0 ?
              <table className='timeConfigTable table'>
                <tbody>
                  {openclosetimeSlots.map((item, index) => (
                    <tr key={index}>
                      <td>{item.exception_title} ({item.start_date} - {item.end_date})</td>
                      <td className='text-end'>
                        <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => { setIsEditException(true); setExceptionData(item); setModalShow(true) }} > <i className="fas fa-pencil-alt"></i></button>
                        <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => { handleDeleteException(item) }} > <i className="fa fa-trash"></i></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> : " "}
          </div>
        </Paper>

        <button className='pb-0 addhourButton ps-0 btn' onClick={() => setModalShow(true)}> <u>{t('timeconfig.addspecialdayholiday')}</u> </button>
        {modalShow && <AddSpecialDayHoliday
          modalShow={modalShow}
          setModalShow={setModalShow}
          isEditException={isEditException}
          exceptionData={exceptionData}
          setExceptionData={setExceptionData}
        />}
      </div> 
    </div >
  );
};
export default TimeConfig;






