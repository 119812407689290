import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

const Mailchimpafterconnect = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    localStorage.setItem('obj', JSON.stringify({ state: searchParams.get('state'), code: searchParams.get('code') }))
    navigate('/mailchimp/redirect')
    return (
        <div>
        </div>
    )
}

export default Mailchimpafterconnect
