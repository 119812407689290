import { Checkbox, FormControl, FormControlLabel, InputLabel, ListSubheader, Menu, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { addEditCategoryApi, getCategoryListForDpApi } from '../../../../services/CategoryServices';
import ImageInput from '../../../../helpers/fileUploader/ImageInput'
import { S3ImageDelete, S3Uploads } from '../../../../helpers/S3Upload/S3upload';
import { S3ImageUpload } from '../../../../constants/constants';


const AddEditCategoryModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData, restaurantId } = props
    const [options, setOptions] = useState([])
    const [deleteCategoryImagePath, setDeleteCategoryImagePath] = useState('')
    const [deleteCategoryBannerPath, setdeleteCategoryBannerPath] = useState('')
    const [imageVal, setImageVal] = useState({
        categoryimage: editData?.Image ? editData?.Image : '',
        categoryImageSrc: editData?.Image ? `${S3ImageUpload}${restaurantId}/category/icon/${editData?.Image}` : '',
        bannerImage: editData?.Banner ? editData?.Banner : '',
        bannerImageSrc: editData?.Banner ? `${S3ImageUpload}${restaurantId}/category/banner/${editData?.Banner}` : ''
    })


    useEffect(() => {
        dispatch(getCategoryListForDpApi((res) => {
            setOptions(res.data)
        }))
    }, [])


    const onsubmit = (values) => {
        let currentDate = Date.now()
        if (deleteCategoryImagePath != '') {
            S3ImageDelete(deleteCategoryImagePath)
        }
        if (deleteCategoryBannerPath != '') {
            S3ImageDelete(deleteCategoryBannerPath)
        }
        if (editData && editData.ID) {
            values.CategoryId = editData.ID
        }
        if (typeof imageVal.categoryimage == 'object') {
            const fileNameForCategory = currentDate + '_' + imageVal.categoryimage.name.replace(/ /g, "_")
            values.categoryImage = fileNameForCategory
        } else {
            values.categoryImage = imageVal.categoryimage
        }
        if (typeof imageVal.bannerImage == 'object') {
            const fileNameForBanner = currentDate + '_' + imageVal.bannerImage.name.replace(/ /g, "_")
            values.bannerImage = fileNameForBanner
        }
        else {
            values.bannerImage = imageVal.bannerImage
        }
        dispatch(addEditCategoryApi(values, async (result) => {
            if (typeof imageVal.categoryimage == 'object') {
                const fileNameForCategory = currentDate + '_' + imageVal.categoryimage.name.replace(/ /g, "_")
                const filePathForCategoty = `${restaurantId}/category/icon/${fileNameForCategory}`
                await S3Uploads(imageVal.categoryimage, filePathForCategoty, fileNameForCategory)
            }
            if (typeof imageVal.bannerImage == 'object') {
                const fileNameForBanner = currentDate + '_' + imageVal.bannerImage.name.replace(/ /g, "_")
                const filePathForBanner = `${restaurantId}/category/banner/${fileNameForBanner}`
                await S3Uploads(imageVal.bannerImage, filePathForBanner, fileNameForBanner)
            }
            handleModalClose()
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
        setImageVal(prevState => ({
            ...prevState,
            categoryimage: '',
            categoryImageSrc: '',
            bannerImage: '',
            bannerImageSrc: ''
        }))
    }




    const handleChangeForCategoryImage = (e) => {
        if (editData?.Image == imageVal.categoryimage) {
            const path = `${restaurantId}/category/icon/${imageVal.categoryimage}`
            setDeleteCategoryImagePath(path)
        }
        setImageVal(prevState => ({
            ...prevState,
            categoryimage: e.photo.data,
            categoryImageSrc: e.photo.src
        }))
    }
    const handleChangeForBanner = (e) => {
        if (editData?.Banner == imageVal.bannerImage) {
            const path = `${restaurantId}/category/banner/${imageVal.bannerImage}`
            setdeleteCategoryBannerPath(path)
        }
        setImageVal(prevState => ({
            ...prevState,
            bannerImage: e.photo.data,
            bannerImageSrc: e.photo.src
        }))
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    CategoryName: editData ? editData.CategoryName : '',
                    Name_Display_On_Till: editData ? editData.Name_Display_On_Till : '',
                    CategoryCode: editData ? editData.CategoryCode : '',
                    IsActive: editData ? (editData.IsActive == '1' ? true : false) : true,
                    ParentId: editData ? editData.parent_id : null,
                    CategoryDescription: editData ? editData.CategoryDescription : ''
                }}

                validationSchema={
                    Yup.object().shape({
                        CategoryName: Yup.string().required("Category Name is required."),
                        Name_Display_On_Till: Yup.string().required("Name Display On Till is required.").nullable(),
                        CategoryCode: Yup.string().required("Category Code is required.").nullable(),
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, setFieldValue, handleBlur, handleChange, handleSubmit } = props;

                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('categoryscreen.updatecategory') : t('categoryscreen.addcategory')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.CategoryName) && touched.CategoryName}
                                                helperText={(Boolean(errors.CategoryName) && touched.CategoryName) ? errors.CategoryName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="CategoryName"
                                                label={t('categoryscreen.categoryname')}
                                                value={values.CategoryName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.Name_Display_On_Till) && touched.Name_Display_On_Till}
                                                helperText={(Boolean(errors.Name_Display_On_Till) && touched.Name_Display_On_Till) ? errors.Name_Display_On_Till : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Name_Display_On_Till"
                                                label={t('categoryscreen.displaynameontill')}
                                                value={values.Name_Display_On_Till}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.CategoryCode) && touched.CategoryCode}
                                                helperText={(Boolean(errors.CategoryCode) && touched.CategoryCode) ? errors.CategoryCode : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="CategoryCode"
                                                label={t('categoryscreen.categorycode')}
                                                value={values.CategoryCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            {<FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    value={values.ParentId}
                                                    label={t('categoryscreen.parentcategory')}
                                                    name='ParentId'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={null}>none</MenuItem>
                                                    {options && options.length > 0 && options.map((item, index) => {
                                                        let count = (item.CategoryName.match(/ —/g) || []).length;
                                                        if (count >= 1 || (editData && item.ID == editData.ID)) {
                                                            return <MenuItem disabled value={item.ID} key={index}>{item.CategoryName}</MenuItem>
                                                        } else {
                                                            return <MenuItem value={item.ID} key={index}>{item.CategoryName}</MenuItem>
                                                        }
                                                    })}

                                                </TextField>
                                            </FormControl>}
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: {
                                                        items: [
                                                            'undo', 'redo',
                                                            '|', 'heading',
                                                            '|', 'bold', 'italic',
                                                            '|', 'link', 'blockQuote',
                                                            '|', 'bulletedList', 'numberedList'
                                                        ],
                                                        shouldNotGroupWhenFull: false
                                                    },
                                                }}
                                                data={values.CategoryDescription}
                                                onChange={(event, editor) => {
                                                    setFieldValue('CategoryDescription', editor.getData());
                                                }}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <InputLabel>{t('categoryscreen.categoryimage')}</InputLabel>
                                            <div className="d-flex">
                                                <ImageInput
                                                    showWidth={'100%'}
                                                    showHeight={'100%'}
                                                    imageWidth={800}
                                                    imageHeight={534}
                                                    imageData={imageVal.categoryImageSrc}
                                                    type="category Image"
                                                    name="photo"
                                                    label="Add Photo"
                                                    showPreview
                                                    onChange={(files) => { handleChangeForCategoryImage(files) }}
                                                />
                                                {imageVal.categoryImageSrc &&
                                                    <div className='ps-3'>
                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                            onClick={() => {
                                                                if (editData?.Image == imageVal.categoryimage) {
                                                                    const path = `${restaurantId}/category/icon/${imageVal.categoryimage}`
                                                                    setDeleteCategoryImagePath(path)
                                                                    setImageVal({
                                                                        categoryimage: '',
                                                                        categoryImageSrc: '',
                                                                        bannerImage: imageVal.bannerImage,
                                                                        bannerImageSrc: imageVal.bannerImageSrc
                                                                    })
                                                                } else {
                                                                    setImageVal({
                                                                        categoryimage: '',
                                                                        categoryImageSrc: '',
                                                                        bannerImage: imageVal.bannerImage,
                                                                        bannerImageSrc: imageVal.bannerImageSrc
                                                                    })
                                                                }
                                                            }}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <InputLabel>{t('categoryscreen.bannerimage')}</InputLabel>
                                            <div className="d-flex">
                                                <ImageInput
                                                    showWidth={'100%'}
                                                    showHeight={'100%'}
                                                    imageWidth={200}
                                                    imageHeight={200}
                                                    imageData={imageVal.bannerImageSrc}
                                                    type=" Category Banner Image"
                                                    name="photo"
                                                    label="Add Photo"
                                                    showPreview
                                                    onChange={(files) => { handleChangeForBanner(files) }}
                                                />
                                                {imageVal.bannerImageSrc &&
                                                    <div className='ps-3'>
                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                            onClick={() => {
                                                                if (editData?.Banner == imageVal.bannerImage) {
                                                                    const path = `${restaurantId}/category/banner/${imageVal.bannerImage}`
                                                                    setdeleteCategoryBannerPath(path)
                                                                    setImageVal({
                                                                        bannerImage: '',
                                                                        bannerImageSrc: '',
                                                                        categoryimage: imageVal.categoryimage,
                                                                        categoryImageSrc: imageVal.categoryImageSrc
                                                                    })
                                                                } else {
                                                                    setImageVal({
                                                                        bannerImage: '',
                                                                        bannerImageSrc: '',
                                                                        categoryimage: imageVal.categoryimage,
                                                                        categoryImageSrc: imageVal.categoryImageSrc
                                                                    })
                                                                }
                                                            }}>

                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.IsActive} />}
                                                label={t('isactive')}
                                                name="IsActive"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default AddEditCategoryModal
