import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { getOrderForActivity } from "../../../services/DashboardServices";
import { useTranslation } from "react-i18next";
//import ApexCharts from 'apexcharts';
//import { Link } from "react-router-dom";

const ApexBarChartforWeeklyAvg = (props) => {
    const { graphData } = props;
    const dispatch = useDispatch()
    const { t } = useTranslation('common')
    const [xaxisData, setXAxisData] = useState([])
    const [yaxisData, setYaxisData] = useState([])

    useEffect(() => {
        let tempName = []
        let TempValue = []
        if (graphData.length > 0) {
            for (let i = 0; i < graphData.length; i++) {
                const element = graphData[i];
                tempName.push(element.day_of_week)
                TempValue.push(element.average_orders)
            }
            setXAxisData(TempValue)
            setYaxisData(tempName)
        }
    }, [graphData])


    return (
        <div id="chartBar5">
            <ReactApexChart options={{
                chart: {
                    type: "bar",
                    height: 400,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        endingShape: 'rounded',
                        columnWidth: '70%',
                        borderRadius: 0,
                        dataLabels: {
                            position: 'top'
                        }
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -30,
                    formatter: function (value) {
                        if (value > 0) {
                            return t('reporting.ordercount', { order: value })
                        } else {
                            return ''
                        }
                    }
                },
                colors: ['#1FBF75'],

                markers: {
                    shape: "circle",
                },


                legend: {
                    show: false,
                },
                grid: {
                    show: false,
                },
                xaxis: {
                    categories: yaxisData,
                    labels: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        offsetX: 0,
                        style: {
                            colors: '#787878',
                            fontSize: '13px',
                            fontFamily: 'poppins',
                            fontWeight: 500,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: ['#1FBF75'],
                },
                tooltip: {
                    enabled: false
                },
                responsive: [{
                    breakpoint: 575,
                    options: {
                        series: [
                            {
                                name: t('reporting.averageweeklyorders'),
                                data: xaxisData,
                            },
                        ],
                        plotOptions: {
                            bar: {
                                columnWidth: '70%',
                            },

                        },
                        xaxis: {
                            categories: yaxisData,
                        }

                    }
                }]

            }} series={[
                {
                    name: t('reporting.averageweeklyorders'),
                    data: xaxisData,
                },
            ]} type="bar" height={300} />
        </div>
    );
}

export default ApexBarChartforWeeklyAvg;