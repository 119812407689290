import { combineReducers } from "redux";
import { AuthReducer } from "./AuthReducer";
import Common from "./Common";
import { localizationSettings } from "./localizationReducer";
import { Permissions } from "./PermissionsReducer";
import orderMasterReducer from "./orderMasterReducer";


const reducers = combineReducers({
    auth: AuthReducer,
    commonData: Common,
    permission: Permissions,
    localization: localizationSettings,
    orderMaster: orderMasterReducer
    //form: reduxFormReducer,	

});

export default reducers;