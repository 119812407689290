import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { deleteItemApi, getItemListApi } from '../../../../services/foodmenuServices';
import { getCategoryListForDpApi } from '../../../../services/CategoryServices';
import FoodMenuAddEditModal from './FoodMenuAddEditModal';
import { COLUMNSFORFOODMENU } from './FoodMenuColumns';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import TableSearch from '../../../../helpers/TableSearch';
import TablePaginationHelper from '../../../../helpers/Pagination';
import swal from 'sweetalert';
import ViewFoodMenuModal from './ViewFoodMenuModal';
import { Empty } from 'antd';
import { Backdrop, Rating } from '@mui/material';

const FoodMenu = () => {
    const dispatch = useDispatch()
    const { auth } = useSelector((state) => state.auth);
    const { t } = useTranslation('common');
    const permissions = JSON.parse(auth.role_permissions)
    const [itemList, setItemList] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const [options, setOptions] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const [loading, setLoading] = useState(false);
    let foodmenuId = '';
    const { loading } = useSelector((state) => state.commonData)

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        foodmenuId = params.get('id');
    }, [])

    useEffect(() => {
        dispatch(getCategoryListForDpApi((res) => {
            setOptions(res.data)
        }))
    }, [])

    useEffect(() => {
        // setLoading(true)
        dispatch(getItemListApi((res) => {
            setItemList(res.data)
            if (foodmenuId != '') {
                let data = res.data.filter((item) => item.ID == foodmenuId)[0]
                if (data) {
                    setViewData(data)
                    setOpenViewModal(true)
                }
            }
            // setLoading(false)
        }))
    }, [openModal])




    const columns = COLUMNSFORFOODMENU
    const data = itemList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }

    const handleEdit = (data) => {
        setEditData(data)
        setOpenModal(true)
    }

    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }

    const handleDelete = (row) => {
        const ID = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteItemApi({ ID }, (res) => {
                    dispatch(getItemListApi((res) => {
                        setItemList(res.data)
                    }))
                }))
                swal("Product has been deleted!", {
                    icon: "success",
                });

            }
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('foodmenuscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {((permissions && permissions.foodmenu && permissions.foodmenu.add) || permissions == null || !permissions.foodmenu) && <Button onClick={handleAddOpen}>{t('buttons.additem')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {!loading &&
                                    tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td>{row.original.ItemName ? row.original.ItemName : '-'}</td>

                                                <td>{row.original.Price ? t("currency", { Number: parseFloat(row.original.Price) }) : '-'}</td>
                                                <td>{row.original.ParentCategoryName ? row.original.ParentCategoryName : '-'}</td>
                                                <td>{row.original.CategoryName ? row.original.CategoryName : '-'}</td>
                                                <td>{row.original.SortDescription ? row.original.SortDescription : '-'}</td>
                                                <td> <Rating name="read-only" value={row.original.UserRating} readOnly />  </td>
                                                {/* <td><i className={`fa-solid fa-star color-primary fs-20 pe-2 ${row.original.UserRating == 0 ? 'd-none' : ''}`} ></i>{row.original.UserRating != 0 ? row.original.UserRating : t('foodmenuscreen.noratings')} </td> */}
                                                <td>
                                                    {((permissions && permissions.foodmenu && permissions.foodmenu.view) || permissions == null || !permissions.foodmenu) && <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>}
                                                    {((permissions && permissions.foodmenu && permissions.foodmenu.edit) || permissions == null || !permissions.foodmenu) && <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row.original)} > <i className="fas fa-pencil-alt"></i></button>}
                                                    {((permissions && permissions.foodmenu && permissions.foodmenu.delete) || permissions == null || !permissions.foodmenu) && <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)}  > <i className="fa fa-trash"></i></button>}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            {!loading && <td colSpan={7} className='text-center p-5'><Empty /></td>}
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <img src="/images/burger.gif" alt="" style={{ width: '15%' }} />
                    </Backdrop>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div>
            {openModal && <FoodMenuAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} options={options} />}
            {openViewModal && <ViewFoodMenuModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}

        </>
    )
}

export default FoodMenu
