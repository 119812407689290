import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { chartColors } from "../../../constants/constants";
//import ApexCharts from 'apexcharts';
//import { Link } from "react-router-dom";

const PopularPieChartReporting = (props) => {
  const { topItem } = props;
  const [seriesData, setSeriesData] = useState([])
  const [foodNames, setFoodNames] = useState([])

  useEffect(() => {
    if (topItem && topItem.length > 0) {
      let tempData = []
      let tempItemName = []
      for (let index = 0; index < topItem.length; index++) {
        const element = topItem[index];
        let Percentage = (element.order_count / 100) * 100
        tempData.push(parseInt(Percentage))
        tempItemName.push(element.ItemName)
      }
      setFoodNames(tempItemName)
      setSeriesData(tempData)
    }
  }, [topItem])


  return (
    <div id="piechart">
      <ReactApexChart options={{
        chart: {
          //id: 'assetDistribution',
          type: "donut",
          offsetY: 0,
          height: 250,

        },
        labels: foodNames,
        dataLabels: {
          enabled: false
        },
        legend: {

          show: false
        },
        stroke: {

          width: 0,
        },
        fill: {
          colors: chartColors,
        },
        responsive: [{
          breakpoint: 601,
          options: {
            chart: {
              width: 200,
              height: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        },
        {
          breakpoint: 361,
          options: {
            chart: {
              width: 270,
              height: 200

            },
            legend: {
              position: 'bottom'
            }
          }

        }],
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const value = series[seriesIndex];
            const label = w.globals.labels[seriesIndex];
            const color = chartColors[seriesIndex]
            return (
              '<div class="custom-tooltip" style="background-color: ' + color + '">' +
              '<span>' + label + ': ' + value + '</span>' +
              '</div>'
            );
          },
        },

      }} series={seriesData} type="pie" height={250} />
    </div>
  );
}

export default PopularPieChartReporting;