import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import swal from 'sweetalert';
import { modifierGroupType } from '../../../../constants/constants';
import TablePaginationHelper from '../../../../helpers/Pagination';
import TableSearch from '../../../../helpers/TableSearch';
import { DeleteModifierGroupApi, getModdifierGroupListApi, getModifierTypeListApi } from '../../../../services/modifierservices';
import ModifierdetailModal from './ModifierdetailModal';
import ModifierGroupAddEditModal from './ModifierGroupAddEditModal';
import { COLUMNSFORMODIFIERGROUP } from './modifiergroupColumns';
import ViewModifierGroupModal from './ViewModifierGroupModal';
import { Empty } from 'antd';

const ModifierGroup = () => {
    const dispatch = useDispatch()
    const [modifiergroupList, setmodifierGroupList] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [modifierGroupDetails, setModifierGroupDetails] = useState({});
    const [editData, setEditData] = useState({});
    const { auth } = useSelector((state) => state.auth);
    const { t } = useTranslation('common');
    const permissions = JSON.parse(auth.role_permissions)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modifierTypeList, setmodifierTypeList] = useState([])

    useEffect(() => {
        dispatch(getModdifierGroupListApi((res) => {
            setmodifierGroupList(res.data)
        }))
    }, [openModal, openDetailsModal])

    useEffect(() => {
        dispatch(getModifierTypeListApi((res) => {
            setmodifierTypeList(res.data)
        }))
    }, [])

    const columns = COLUMNSFORMODIFIERGROUP
    const data = modifiergroupList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state


    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }
    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
        setModifierGroupDetails(row.original)
    }

    const handleDelete = (row) => {
        const id = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Modifier Group has been deleted!", {
                    icon: "success",
                });
                dispatch(DeleteModifierGroupApi({ id }, (res) => {
                    dispatch(getModdifierGroupListApi((res) => {
                        setmodifierGroupList(res.data)
                    }))
                }))
            }
        })
    }

    const handledetailsModalOpen = (data) => {
        setOpenDetailsModal(true)
        data.original.Items.map((item) => {
            item.Flag = true;
            item.IsActive = item.IsActive == '1' ? true : false
        })
        setModifierGroupDetails(data.original)
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('modifiergroupscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {((permissions && permissions.modifiergroup && permissions.modifiergroup.add) || permissions == null || !permissions.modifiergroup) && <Button onClick={handleAddOpen}>{t('buttons.addmodifiergroup')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        prepareRow(row)
                                        row.original.typeName = modifierGroupType.filter((item) => item.id == parseInt(row.original.Type))[0].name
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td> {row.original.Name} </td>
                                                <td> {row.original.Description} </td>
                                                <td> {row.original.typeName} </td>
                                                <td> {row.original.Name_Display_On_Till} </td>
                                                <td className='text-end'>
                                                    {((permissions && permissions.modifiergroup && permissions.modifiergroup.view) || permissions == null || !permissions.modifiergroup) && <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>}
                                                    {((permissions && permissions.modifiergroup && permissions.modifiergroup.edit) || permissions == null || !permissions.modifiergroup) && <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>}
                                                    {((permissions && permissions.modifiergroup && permissions.modifiergroup.delete) || permissions == null || !permissions.modifiergroup) && <button className='btn btn-danger shadow btn-xs sharp  me-1' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>}
                                                    <button className='btn btn-info shadow btn-xs sharp' onClick={() => handledetailsModalOpen(row)} > <i className="fa fa-users"></i></button>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}

                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div>
            {openModal && <ModifierGroupAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} />}
            {openDetailsModal && <ModifierdetailModal openDetailsModal={openDetailsModal} setOpenDetailsModal={setOpenDetailsModal} modifierGroupDetails={modifierGroupDetails} modifierTypeList={modifierTypeList} setmodifierTypeList={setmodifierTypeList} />}
            {openViewModal && <ViewModifierGroupModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} modifierGroupDetails={modifierGroupDetails} modifierTypeList={modifierTypeList} setmodifierTypeList={setmodifierTypeList} />}
        </>
    )
}

export default ModifierGroup;