import { TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addOpenCloseTime } from '../../../../services/TimeConfigServices';
import * as Yup from 'yup';

const TimeConfigForm = (props) => {
    const { setIsFormExpanded, editData, service, setServiceName } = props;
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const initialValues = {
        1: editData.WeekDay && editData.WeekDay['1'] === false ? false : true,
        2: editData.WeekDay && editData.WeekDay['2'] === false ? false : true,
        3: editData.WeekDay && editData.WeekDay['3'] === false ? false : true,
        4: editData.WeekDay && editData.WeekDay['4'] === false ? false : true,
        5: editData.WeekDay && editData.WeekDay['5'] === false ? false : true,
        6: editData.WeekDay && editData.WeekDay['6'] === false ? false : true,
        7: editData.WeekDay && editData.WeekDay['7'] === false ? false : true,
        startTime: editData ? editData.StartTime : '10:00',
        endTime: editData ? editData.EndTime : '20:00'
    };

    // const validationSchema = Yup.object().shape({
    //     startTime: Yup.string().required('Start time is required'),
    //     endTime: Yup.string().required('End time is required'),
    //   });

    return (
        <Formik
            validateOnChange={false}
            enableReinitialize
            validationSchema={Yup.object().shape({
                startTime: Yup.string().required("Start time is required."),
                endTime: Yup.string().required('End time is required.'),
                // date:Yup.string().required('Date is required.')
            })}
            initialValues={initialValues}
            onSubmit={(values) => {
                let weekArray = []
                if (values['1']) {
                    weekArray.push('1');
                }
                if (values['2']) {
                    weekArray.push('2');
                }
                if (values['3']) {
                    weekArray.push('3');
                }
                if (values['4']) {
                    weekArray.push('4');
                }
                if (values['5']) {
                    weekArray.push('5');
                }
                if (values['6']) {
                    weekArray.push('6');
                }
                if (values['7']) {
                    weekArray.push('7');
                }

                values.WeekDay = weekArray.join(',')

                let selectedWeekdays = values.WeekDay
                let openCloseTimeData = {
                    "slot_id": editData.slot_id ? editData.slot_id : null,
                    "StartTime": values.startTime,
                    "EndTime": values.endTime,
                    "ScheduleType": service,
                    "WeekDay": {}
                }
                for (let i = '1'; i <= '7'; i++) {
                    openCloseTimeData.WeekDay[i] = selectedWeekdays.includes(i) ? true : false;
                }

                dispatch(addOpenCloseTime(openCloseTimeData, (res) => {
                    setServiceName(null)
                    setIsFormExpanded(false);

                }))
            }}
        >
            {(props) => {
                const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                // console.log(values, "values on submit")

                return (
                    <>
                        <div className='card container mt-2'>
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className='row pt-3'>
                                    <div className='col-lg-7'>
                                        <FormGroup className='d-flex flex-row'>
                                            <FormControlLabel control={<Checkbox
                                                checked={values['1']}
                                                name='1'
                                                onChange={handleChange} />} label={t('timeconfig.monday')} />
                                            <FormControlLabel control={<Checkbox
                                                checked={values['2']}
                                                name='2'
                                                onChange={handleChange} />} label={t('timeconfig.tuesday')} />
                                            <FormControlLabel control={<Checkbox
                                                checked={values['3']}
                                                name='3'
                                                onChange={handleChange} />} label={t('timeconfig.wednesday')} />
                                            <FormControlLabel control={<Checkbox
                                                checked={values['4']}
                                                name='4'
                                                onChange={handleChange} />} label={t('timeconfig.thursday')} />
                                            <FormControlLabel control={<Checkbox
                                                checked={values['5']}
                                                name='5'
                                                onChange={handleChange} />} label={t('timeconfig.friday')} />
                                            <FormControlLabel control={<Checkbox
                                                checked={values['6']}
                                                name='6'
                                                onChange={handleChange} />} label={t('timeconfig.saturday')} />
                                            <FormControlLabel control={<Checkbox
                                                checked={values['7']}
                                                name='7'
                                                onChange={handleChange} />} label={t('timeconfig.sunday')} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-2'>
                                        <label className='mt-2'>{t('timeconfig.starttime')}</label>
                                        <Field
                                            as={TextField}
                                            type="time"
                                            name={`startTime`}
                                            error={Boolean(errors.startTime) && touched.startTime}
                                            helperText={(Boolean(errors.startTime) && touched.startTime) ? errors.startTime : ''}
                                        />
                                    </div>
                                    <div className='col-lg-2'>
                                        <label className='mt-2'>{t('timeconfig.endtime')}</label>
                                        <Field
                                            as={TextField}
                                            type="time"
                                            name={`endTime`}
                                            error={Boolean(errors.endTime) && touched.endTime}
                                            helperText={(Boolean(errors.endTime) && touched.endTime) ? errors.endTime : ''}
                                        />
                                    </div>
                                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            size="large"
                                        // disabled={!isValid || isDisabled}
                                        >
                                            {t('buttons.save')}
                                        </button>
                                        <Button
                                            className='ms-2'
                                            type="reset"
                                            variant="danger light"
                                            onClick={() => { setIsFormExpanded(false); setServiceName(null) }}
                                        >
                                            {t('buttons.cancel')}
                                        </Button>
                                    </div>
                                    <div className="col-lg-2 col-xl-2  mb-3 ">
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                );
            }}
        </Formik>
    );
};

export default TimeConfigForm;
