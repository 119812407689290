import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { FieldArray, Form, Formik, getIn } from 'formik';
import React from 'react'
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup'
import { symbolsArr } from '../../../../constants/constants';
import { Button } from 'react-bootstrap';
import { URLSlug } from '../../../../api';
import { useDispatch } from 'react-redux';
import { domainPrefixValidation } from '../../../../services/RestaurantServices';
import { useState } from 'react';

const RestaurantProfile = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { InitialValues, onsubmit } = props
    const [domainError, setDomainError] = useState('')
    const [disableFlag, setDisableFlag] = useState(false)


    const validatedomain = (e) => {
        setDisableFlag(true)
        let obj = {
            domain: e.target.value
        }
        dispatch(domainPrefixValidation(obj, (res) => {
            if (res.status) {
                setDomainError('')
            } else {
                setDomainError(res.message)
            }
            setDisableFlag(false)
        }))
    }

    return (
        <div className="pt-3">
            <div className="settings-form">
                <h4 className="text-primary p-3">{t('profile.restaurantprofile.restaurantbasicdetails')}</h4>
                <Formik
                    // enableReinitialize
                    initialValues={InitialValues}
                    validationSchema={Yup.object().shape({
                        RestaurantName: Yup.string().required("Restaurant Name is required."),
                        RestaurantExternalURL: Yup.string().required("Restaurant External URL is required."),
                        ExternalURLSlug: Yup.string().required("External URL Slug is required."),
                        Email: Yup.string('Enter your email')
                            .email('Enter a valid email')
                            .required('Email is required'),
                        // AlternativeEmail: Yup.string('Enter your email').email('Enter a valid email'),

                        AlternativeEmail: Yup.string()
                            .email('Invalid email address')
                            .test('email-match', 'Alternative email must not be the same as Email', function (value) {
                                const { Email } = this.parent; // Access the value of 'email'
                                return value !== Email; // Return true if alternativeEmail is different from email
                            }),

                        MobileNo: Yup.array().of(
                            Yup.object().shape({
                                // number: Yup.string().required('Phone number is required').max(15, "Invalid phone number").min(6, "Invalid phone number"),
                                number: Yup.string()
                                    .required('Phone number is required')
                                    .test(
                                        'is-valid-phone-number',
                                        'Invalid phone number. It should start with 0 and be 11 characters long',
                                        value => {
                                            if (!value) {
                                                return false; // Empty value is considered invalid
                                            }
                                            return /^0\d{10}$/.test(value);
                                        }
                                    )
                            })
                        ),
                        // MobileNo: Yup.string().required('Mobile Number is required').max(15, "Invalid mobile no.").min(6, "Invalid mobile no."),
                        ShortDescription: Yup.string().required("Short Description is required."),
                        LongDescription: Yup.string().required("Long Description is required."),
                    })}
                    onSubmit={onsubmit}
                >
                    {(props) => {
                        const { values, touched, errors, isValid, isDisabled, setFieldValue, handleBlur, handleChange, handleSubmit } = props;
                        return (
                            <Form onSubmit={handleSubmit} noValidate>
                                {InitialValues.RestaurantName &&
                                    <>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <TextField
                                                    className='disabledTextfield'
                                                    disabled
                                                    required
                                                    error={Boolean(errors.RestaurantName) && touched.RestaurantName}
                                                    helperText={(Boolean(errors.RestaurantName) && touched.RestaurantName) ? errors.RestaurantName : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="RestaurantName"
                                                    label={t('profile.restaurantprofile.restaurantname')}
                                                    value={values.RestaurantName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <TextField
                                                    required
                                                    error={(Boolean(errors.ExternalURLSlug) && touched.ExternalURLSlug) || domainError}
                                                    helperText={(Boolean(errors.ExternalURLSlug) && touched.ExternalURLSlug) ? errors.ExternalURLSlug : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="ExternalURLSlug"
                                                    label={t('profile.restaurantprofile.externalurlslug')}
                                                    value={values.ExternalURLSlug}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setFieldValue('RestaurantExternalURL', e.target.value
                                                            .toLowerCase()
                                                            .replace(/[^a-zA-Z0-9_ ]/g, '')
                                                            .replace(/ /g, '-'),);
                                                        validatedomain(e)

                                                    }}
                                                />
                                                <FormHelperText className="Mui-error">{domainError ? domainError : ""}</FormHelperText>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <TextField
                                                    // inputProps={
                                                    //     { readOnly: true, }
                                                    // }
                                                    disabled
                                                    required
                                                    error={Boolean(errors.RestaurantExternalURL) && touched.RestaurantExternalURL}
                                                    helperText={(Boolean(errors.RestaurantExternalURL) && touched.RestaurantExternalURL) ? errors.RestaurantExternalURL : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="RestaurantExternalURL"
                                                    label={t('profile.restaurantprofile.restaurantexternalurl')}
                                                    value={values.RestaurantExternalURL + URLSlug}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setFieldValue('ExternalURLSlug', e.target.value
                                                            .toLowerCase()
                                                            .replace(/[^a-zA-Z0-9_ ]/g, '')
                                                            .replace(/ /g, '-'),)

                                                    }}
                                                />
                                            </div>

                                        </div>
                                        <div className="row py-4">
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <TextField
                                                    required
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                    error={Boolean(errors.Email) && touched.Email}
                                                    helperText={(Boolean(errors.Email) && touched.Email) ? errors.Email : ''}
                                                    type='email'
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="Email"
                                                    label={t('profile.restaurantprofile.email')}
                                                    value={values.Email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <TextField
                                                    error={Boolean(errors.AlternativeEmail) && touched.AlternativeEmail}
                                                    helperText={(Boolean(errors.AlternativeEmail) && touched.AlternativeEmail) ? errors.AlternativeEmail : ''}
                                                    type='email'
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="AlternativeEmail"
                                                    label={t('profile.restaurantprofile.alternativeemail')}
                                                    value={values.AlternativeEmail}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                                <TextField
                                                    required
                                                    error={Boolean(errors.MobileNo) && touched.MobileNo}
                                                    helperText={(Boolean(errors.MobileNo) && touched.MobileNo) ? errors.MobileNo : ''}
                                                    type='number'
                                                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="MobileNo"
                                                    label={t('profile.restaurantprofile.mobileno')}
                                                    value={values.MobileNo}
                                                    onChange={handleChange}
                                                />
                                            </div> */}

                                            <FieldArray
                                                name="MobileNo"
                                                render={arrayHelpers => (
                                                    <>
                                                        {values.MobileNo && values.MobileNo.length > 0 && (
                                                            values.MobileNo.map((item, index) => {
                                                                const MinSelection = `MobileNo.${index}.number`;
                                                                const touchedMinSelection = getIn(touched, MinSelection);
                                                                const errorMinSelection = getIn(errors, MinSelection);

                                                                return <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <FormControl fullWidth className={`${index > 0 && 'mt-3'}`}>
                                                                        <InputLabel htmlFor="outlined-adornment-weight">{t('profile.restaurantprofile.mobileno')}</InputLabel>
                                                                        <OutlinedInput
                                                                            id="outlined-adornment-weight"
                                                                            endAdornment={
                                                                                <InputAdornment position="start">
                                                                                    <IconButton
                                                                                        aria-label="toggle number visibility"
                                                                                        onClick={() => {
                                                                                            if (index == 0) {
                                                                                                if (values.MobileNo.length < 4) {
                                                                                                    arrayHelpers.push({
                                                                                                        number: ""
                                                                                                    })
                                                                                                }
                                                                                            } else {
                                                                                                arrayHelpers.remove(index)
                                                                                            }
                                                                                        }}
                                                                                        onMouseDown={(e) => { e.preventDefault() }}
                                                                                        edge="end"
                                                                                    >
                                                                                        {index == 0 ? <i class="bi bi-plus-circle"></i> : <i class="bi bi-x-circle"></i>}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                            type="text"
                                                                            inputProps={{
                                                                                inputProps: {
                                                                                    inputMode: 'numeric',
                                                                                },
                                                                                onInput: (e) => {
                                                                                    e.target.value = e.target.value.replace(/\D/g, '');
                                                                                },
                                                                            }}
                                                                            onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                            value={item.number}
                                                                            onChange={(e) => {
                                                                                handleChange(e, index)
                                                                            }}
                                                                            label={t('profile.restaurantprofile.mobileno')}
                                                                            name={`MobileNo.${index}.number`}
                                                                            error={Boolean(touchedMinSelection && errorMinSelection)}
                                                                        />
                                                                    </FormControl>
                                                                    <FormHelperText className="Mui-error">{Boolean(touchedMinSelection && errorMinSelection) ? errorMinSelection : ""}</FormHelperText>
                                                                </div>
                                                            })
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <TextField
                                                    required
                                                    error={Boolean(errors.ShortDescription) && touched.ShortDescription}
                                                    multiline
                                                    rows={4}
                                                    helperText={(Boolean(errors.ShortDescription) && touched.ShortDescription) ? errors.ShortDescription : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="ShortDescription"
                                                    label={t('profile.restaurantprofile.shortdescription')}
                                                    value={values.ShortDescription}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <TextField
                                                    required
                                                    error={Boolean(errors.LongDescription) && touched.LongDescription}
                                                    multiline
                                                    rows={4}
                                                    helperText={(Boolean(errors.LongDescription) && touched.LongDescription) ? errors.LongDescription : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="LongDescription"
                                                    label={t('profile.restaurantprofile.longdescription')}
                                                    value={values.LongDescription}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-3 d-flex float-right align-items-center justify-content-end">
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                                size="large"
                                                disabled={domainError != '' || disableFlag ? true : false}
                                            >
                                                {t('buttons.save')}
                                            </button>
                                        </div>
                                    </>}
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default RestaurantProfile
