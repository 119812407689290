import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ModifierGroupDetailsForm from './ModifierGroupDetailsForm'
import { getModifieritemListApi } from '../../../../services/modifierservices'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { Empty } from 'antd'

const ViewModifierGroupModal = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData, modifierGroupDetails, modifierTypeList, setmodifierTypeList } = props
    const [modifierItemList, setmodifierItemList] = useState([]);

    useEffect(() => {
        dispatch(getModifieritemListApi((res) => {
            setmodifierItemList(res.data)
        }))
    }, [])

    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('modifiergroupscreen.viewmodifiergroup')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-4 p-2 text-end"><h6>{t('modifiergroupscreen.name')}</h6></div>
                    <div className="col-lg-1 p-2 text-center">:</div>
                    <div className="col p-2">{viewData.Name}</div>
                </div>
                <div className="row">
                    <div className="col-lg-4 p-2 text-end"><h6>{t('modifiergroupscreen.displaynameontill')}</h6></div>
                    <div className="col-lg-1 p-2 text-center">:</div>
                    <div className="col p-2">{viewData.Name_Display_On_Till}</div>
                </div>
                <div className="row">
                    <div className="col-lg-4 p-2 text-end"><h6>{t('modifiergroupscreen.type')}</h6></div>
                    <div className="col-lg-1 p-2 text-center">:</div>
                    <div className="col p-2">{viewData.typeName}</div>
                </div>
                <div className="row">
                    <div className="col-lg-4 p-2 text-end"><h6>{t('modifiergroupscreen.description')}</h6></div>
                    <div className="col-lg-1 p-2 text-center">:</div>
                    <div className="col p-2">{viewData.Description}</div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{t('modifiergroupscreen.modifierType')}</th>
                            <th>{t('modifiergroupscreen.modifier')}</th>
                            <th>{t('modifiergroupscreen.description')}</th>
                            <th>{t('modifiergroupscreen.displayorder')}</th>
                            <th>{t('modifiergroupscreen.available')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewData && viewData.Items.length > 0 ? viewData.Items.map((item) => {
                            item.ProductTypeName = modifierTypeList.length > 0 && modifierTypeList.filter((data) => data.ID == item.ProductTypeId)[0].Type
                            item.modifierItemName = modifierItemList.length > 0 && modifierItemList.filter((data) => data.ID == item.ItemId)[0].ItemName
                            return (
                                <tr key={item.ID}>
                                    <td>{item.ProductTypeName}</td>
                                    <td>{item.modifierItemName}</td>
                                    <td>{item.Description}</td>
                                    <td>{item.DisplayOrder}</td>
                                    <td>{item.IsActive == '1' ? "true" : 'false'}</td>
                                </tr>
                            )
                        })
                            :
                            <tr>
                                <td colSpan={5}>
                                    <Empty />
                                </td>
                            </tr>

                        }
                    </tbody>
                </table>
                {/* <ModifierGroupDetailsForm viewData={viewData} modifierGroupDetails={modifierGroupDetails} modifierTypeList={modifierTypeList} setmodifierTypeList={setmodifierTypeList} /> */}
            </Modal.Body>
        </Modal>
    )
}

export default ViewModifierGroupModal
