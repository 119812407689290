import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useState } from 'react';
import { S3ImageDelete, S3Uploads } from '../../../helpers/S3Upload/S3upload';
import { S3ImageUpload, symbolsArr } from '../../../constants/constants';
import { AddEditBannerApi } from '../../../services/BannerServices';


const HomeBannerAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData, restaurantId } = props
    const [deleteImagePath, setDeleteImagePath] = useState('')
    let inputElement;
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'video/mp4'];
    const validSize = 200 * 1024 * 1024;

    const onsubmit = (values) => {
        let currentDate = Date.now()
        if (deleteImagePath != '') {
            S3ImageDelete(deleteImagePath)
        }
        if (editData && editData.ID) {
            values.ID = editData.ID
        }
        if (typeof values.BannerImageName == 'object') {
            const fileNameForBannerImage = currentDate + '_' + values.BannerImageName.name.replace(/ /g, "_")
            values.BannerImage = fileNameForBannerImage
        } else {
            values.BannerImage = values.BannerImageName
        }
        dispatch(AddEditBannerApi(values, async (res) => {
            if (typeof values.BannerImageName == 'object') {
                let id = editData && editData.ID ? editData.ID : res.data
                const fileNameForBannerImage = currentDate + '_' + values.BannerImageName.name.replace(/ /g, "_")
                const filePathForBannerImage = `${restaurantId}/homebanner/${fileNameForBannerImage}`
                await S3Uploads(values.BannerImageName, filePathForBannerImage, fileNameForBannerImage)
            }
            setOpenModal(false);
            setEditData({})
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }


    file: Yup.object().shape({
        name: Yup.string().required()
    }).required('File required')


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={
                    {
                        Title: editData && editData.Title ? editData.Title : '',
                        Description: editData && editData.Description ? editData.Description : '',
                        BannerImageName: editData && editData.BannerImage ? editData.BannerImage : '',
                        BannerImageSrc: editData && editData.BannerImage ? `${S3ImageUpload}${restaurantId}/homebanner/${editData.BannerImage}` : '',
                        IsActive: editData && editData.IsActive == '1' ? true : false
                    }
                }

                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, setFieldValue, setFieldError, setTouched, setErrors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('homebannerScreen.updatehomebanner') : t('homebannerScreen.addhomebanner')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                error={Boolean(errors.Title) && touched.Title}
                                                helperText={(Boolean(errors.Title) && touched.Title) ? errors.Title : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Title"
                                                label={t('homebannerScreen.titlefield')}
                                                value={values.Title}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                multiline
                                                rows={4}
                                                error={Boolean(errors.Description) && touched.Description}
                                                helperText={(Boolean(errors.Description) && touched.Description) ? errors.Description : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Description"
                                                label={t('homebannerScreen.description')}
                                                value={values.Description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3 '>
                                            <InputLabel>{t('homebannerScreen.homebannerimage')}</InputLabel>
                                            <p className='d-table'>
                                                <span className="pt-3 pl-2" style={{ display: 'table-cell', verticalAlign: 'middle' }}>   {t('homebannerScreen.note')} : &nbsp;</span>
                                                <span className="help" style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: t('homebannerScreen.notetext') }}>
                                                </span>
                                            </p>
                                            <div className='d-flex'>
                                                <div className='w-50'>
                                                    {values.BannerImageSrc && (
                                                        (values.BannerImageName.type == 'video/mp4' || values.BannerImageSrc.slice(values.BannerImageSrc.lastIndexOf('.') + 1) == 'mp4') ?
                                                            <video width="320" height="240" autoPlay loop="loop" muted>
                                                                <source src={values.BannerImageSrc} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                            :
                                                            <img
                                                                src={values.BannerImageSrc}
                                                                style={{ width: '100%' }}
                                                                alt="No Image"
                                                            />
                                                    )}
                                                    <div className={"upload-image"} onClick={() => inputElement.click()}>
                                                        <button type="button" className="uploadButton">{typeof values.BannerImageName == 'object' && values.BannerImageSrc ? values.BannerImageName.name : t('profile.restaurantimages.attechfile')}</button>
                                                    </div>
                                                    <input
                                                        required
                                                        ref={input => (inputElement = input)}
                                                        accept="image/*, video/mp4"
                                                        type="file"
                                                        name='BannerImageName'
                                                        style={{ display: "none" }}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            if (editData?.BannerImage == values.BannerImageName) {
                                                                const path = `${restaurantId}/homebanner/${values.BannerImageName}`
                                                                setDeleteImagePath(path)
                                                            }
                                                            if (e.target.files.length > 0) {
                                                                const file = e.target.files;
                                                                var url = URL.createObjectURL(file[0]);
                                                                const fileType = file[0].type;
                                                                const fileSize = file[0].size;
                                                                if (!validTypes.includes(fileType)) {
                                                                    setFieldError('BannerImageName', 'Invalid file type');
                                                                } else if (fileSize > validSize) {
                                                                    setFieldError('BannerImageName', 'File size is too large');
                                                                } else {
                                                                    setFieldValue('BannerImageName', file[0]);
                                                                    setFieldValue('BannerImageSrc', url);
                                                                    setFieldError(null);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {values.BannerImageSrc &&
                                                    <div className='ps-3'>
                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp me-1'
                                                            onClick={() => {
                                                                if (editData?.BannerImage == values.BannerImageName) {
                                                                    const path = `${restaurantId}/homebanner/${values.BannerImageName}`
                                                                    setDeleteImagePath(path)
                                                                    setFieldValue('BannerImageName', '');
                                                                    setFieldValue('BannerImageSrc', '');
                                                                } else {
                                                                    setFieldValue('BannerImageName', '');
                                                                    setFieldValue('BannerImageSrc', '');
                                                                }
                                                            }}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>}
                                            </div>
                                        </div>
                                        <FormHelperText className="Mui-error">{Boolean(errors.BannerImageName) || touched.BannerImageName ? errors.BannerImageName : ""}</FormHelperText>
                                        <div className='col-3 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.IsActive} />}
                                                label={t('isactive')}
                                                name="IsActive"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                        disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default HomeBannerAddEditModal
