import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { COLUMNFOREMAILMARKETING } from './EmailMarketingColumn'
import { useState } from 'react'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import { Empty } from 'antd';
import TablePaginationHelper from '../../../../helpers/Pagination'
import EmailSendModal from './EmailSendModal'
import { Button } from 'react-bootstrap'
import TableSearch from '../../../../helpers/TableSearch'
import EmailTemplate from './EmailTemplate'
import EmailTemplateViewModal from './EmailTemplateViewModal'
import { deleteTemplate, getTemplateList } from '../../../../services/EmailMarketingServices'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert'


const EmailMarketing = () => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const [templateList, setTemplateList] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openSendModal, setOpenSendModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [viewData, setViewData] = useState({});
    const [sendData, setSendData] = useState({});


    const columns = COLUMNFOREMAILMARKETING
    const data = templateList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    useEffect(() => {
        dispatch(getTemplateList((res) => {
            setTemplateList(res.data)
        }))
    }, [openModal])

    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }

    const handleSend = (row) => {
        let obj = {
            template_id: row.original.id,
            disableTemplate: true
        }
        setSendData(obj)
        setOpenSendModal(true)
    }

    const handleAddOpen = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleDelete = (row) => {
        let id = row.original.id
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteTemplate({ id }, (res) => {
                    swal("Template has been deleted!", {
                        icon: "success",
                    });
                    dispatch(getTemplateList((res) => {
                        setTemplateList(res.data)
                    }))
                }))
            }
        })
    }


    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('emailtemplate.title')}</h4>
                <div className='d-flex justify-content-md-around TableSerchInput'>
                    <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Button onClick={handleAddOpen}>{t('buttons.addemailtemplate')}</Button>
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <div className="dataTables_wrapper">
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {t(column.render('Header'))}
                                                <span className="ml-1">
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                    )
                                                        :
                                                        (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                    }
                                                </span>
                                            </th>
                                        ))}
                                        <th className='text-end'>{t('table.action')}</th>
                                    </tr>
                                ))}
                            </thead>
                            {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            <td > {row.original.template_name}  </td>
                                            <td className='text-end'>
                                                <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>
                                                <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>
                                                <button className='btn btn-danger shadow btn-xs sharp me-1' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>
                                                <button className='btn btn-primary shadow btn-xs sharp' onClick={() => handleSend(row)} > <i className="fa-solid fa-paper-plane"></i></button>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody> :
                                <tbody>
                                    <tr>
                                        <td colSpan={6} className='text-center p-5'><Empty /></td>
                                    </tr>
                                </tbody>}
                            {/* This is only for footer if u require */}

                        </table>
                    </div>
                </div>
                {openSendModal && <EmailSendModal openSendModal={openSendModal} setOpenSendModal={setOpenSendModal} sendData={sendData} setSendData={setSendData} templateList={templateList} />}
                {openModal && <EmailTemplate openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} />}
                {openViewModal && <EmailTemplateViewModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} setViewData={setViewData} />}
            </div>
            {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
        </div>
    )
}

export default EmailMarketing
