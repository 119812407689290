
import { Form } from "formik";
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { changepasswordApi } from "../../services/RestaurantServices";
import { useDispatch } from "react-redux";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



var values = { old_password: '', password: '', confirm_password: '', showPassword: false, showOldPassword: false, showConfirmPassword: false };
const ChangePassword = (props) => {
    const dispatch = useDispatch()
    const { openModal, setOpenModal } = props
    const { t } = useTranslation('common');

    const onChangeData = data => {
        dispatch(changepasswordApi(data, (res) => {
            setOpenModal(false);
            values = { old_password: '', password: '', confirm_password: '' }
        }))

    };

    const handleModalClose = () => {
        setOpenModal(false);
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={values}
                validationSchema={Yup.object().shape({
                    old_password: Yup.string('Enter your current password.').required('Current Password is required.'),
                    password: Yup.string('Enter your new password.')
                        .required('New password is required.')
                        .min(6, 'Password must contain atleast 6 characters.'),
                    confirm_password: Yup.string('Enter confirm password.').when('password', {
                        is: val => (val && val.length > 0 ? true : false),
                        then: Yup.string()
                            .required('Please re-enter new password.')
                            .oneOf([Yup.ref('password')], "Doesn't match with new password."),
                    }),
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={onChangeData}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, setFieldValue, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{t('changepassword.title')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={() => setOpenModal(false)}
                                >
                                </Button>
                            </Modal.Header>
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => { setFieldValue('showOldPassword', !values.showOldPassword) }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                edge="end"
                                                            >
                                                                {values.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}
                                                required
                                                fullWidth
                                                name="old_password"
                                                variant="outlined"
                                                label={t('changepassword.currentpassword')}
                                                className="mt-4"
                                                type={values.showOldPassword ? "text" : "password"}
                                                value={values.old_password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(errors.old_password) && touched.old_password}
                                                helperText={Boolean(errors.old_password) && touched.old_password ? errors.old_password : ''}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => { setFieldValue('showPassword', !values.showPassword) }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}
                                                required
                                                fullWidth
                                                variant="outlined"
                                                name="password"
                                                label={t('changepassword.password')}
                                                type={values.showPassword ? "text" : "password"}
                                                className="margin-30"
                                                value={values.password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(errors.password)}
                                                helperText={Boolean(errors.password) && touched.password ? errors.password : ''}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => { setFieldValue('showConfirmPassword', !values.showConfirmPassword) }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                edge="end"
                                                            >
                                                                {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}
                                                required
                                                fullWidth
                                                variant="outlined"
                                                label={t('changepassword.confirmpassword')}
                                                name="confirm_password"
                                                className="margin-30"
                                                type={values.showConfirmPassword ? "text" : "password"}
                                                value={values.confirm_password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(errors.confirm_password)}
                                                helperText={touched.confirm_password && Boolean(errors.confirm_password) ? errors.confirm_password : ''}
                                            />
                                        </div>

                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >
    );
};

export default ChangePassword;
