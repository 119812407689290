import { Box, FormControl, LinearProgress, MenuItem, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getReportData, getReportDataforLocation, getReportDataforrevenue, getTopItems, getTotalIncome, getTopCategory } from '../../../services/ReportingServices';
import moment from 'moment';
import ApexBarChartForReporting from './ApexBarChartforReporting';
import { Accordion, Nav, ProgressBar, Tab } from 'react-bootstrap';
import { Empty, Progress, Space } from 'antd';
import RevenuebyorderPieChart from './RevenuebyorderPieChart';
import { chartColors } from '../../../constants/constants';
import PopularPieChartReporting from './PopularPieChart';
import { Sparklines, SparklinesLine } from 'react-sparklines';


const sparklinedata = [4, 7, 4, 9, 5, 6, 8, 3, 1, 3, 5, 6];

const LiveReporting = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [catagoryAccordiankey, setCatagoryAccordiankey] = useState('')
  const [graphData, setGraphData] = useState({})
  const [incomeData, setIncomeData] = useState({})
  const [locationData, setLocationData] = useState([])
  const [revenueData, setRevenueData] = useState({})
  const [pieChartData, setPieChartData] = useState({})
  const [topCategory, setTopCategory] = useState([])
  const [topItems, setTopItems] = useState([])
  const [catagoryItems, setCatagoryItems] = useState([])

  const handleChangeRange = (e, setFieldValue, values) => {
    setCatagoryAccordiankey('')
    let range = e.target.value
    let obj = {
      StartDate: moment(new Date()).format('YYYY-MM-DD'),
      EndDate: moment(new Date()).format('YYYY-MM-DD')
    }
    if (range == 'today') {
      obj = {
        StartDate: moment(new Date()).format('YYYY-MM-DD'),
        EndDate: moment(new Date()).format('YYYY-MM-DD')
      }
      setFieldValue('StartDate', obj.StartDate)
      setFieldValue('EndDate', obj.EndDate)
    } else if (range == 'yesterday') {
      obj.StartDate = moment(new Date()).subtract(1, "days").format('YYYY-MM-DD')
      obj.EndDate = moment(new Date()).subtract(1, "days").format('YYYY-MM-DD')
      setFieldValue('StartDate', obj.StartDate)
      setFieldValue('EndDate', obj.EndDate)
    } else if (range == '7days') {
      obj.StartDate = moment(new Date()).subtract(6, "days").format('YYYY-MM-DD')
      setFieldValue('StartDate', obj.StartDate)
      setFieldValue('EndDate', obj.EndDate)
    } else if (range == '28days') {
      obj.StartDate = moment(new Date()).subtract(27, "days").format('YYYY-MM-DD')
      setFieldValue('StartDate', obj.StartDate)
      setFieldValue('EndDate', obj.EndDate)
    }

    obj.PaymentType = values.PaymentType
    obj.OrderType = values.OrderType
    obj.locationOrderType = values.locationOrderType

    if (range != 'custom') {
      dispatch(getReportData(obj, (res) => {
        setGraphData(res.data)
      }))
      dispatch(getTotalIncome(obj, (res) => {
        setIncomeData(res.data)
      }))
      dispatch(getReportDataforLocation(obj, (res) => {
        setLocationData(res.data)
      }))
      dispatch(getTopItems(obj, (res) => {
        setTopItems(res.data)
      }))
      dispatch(getTopCategory(obj, (res) => {
        setTopCategory(res.data)
      }))
      dispatch(getReportDataforrevenue(obj, (res) => {
        setRevenueData(res.data[0])
        if (res.data.length > 0) {
          let obj = {
            carddelivery: res.data[0].card_delivery_amount,
            cashdelivery: res.data[0].cash_delivery_amount,
            cardcollection: res.data[0].card_collection_amount,
            cashcollection: res.data[0].cash_collection_amount,
            carddinein: res.data[0].card_dinein_amount,
            cashdinein: res.data[0].cash_dinein_amount,
          }
          setPieChartData(obj)
        }
      }))
    }
  }

  let color = (value) => {
    switch (value) {
      case 'fullfilledOrder':
        return '#1FBF75';
      case 'unfullfilledorders':
        return '#d50f0f'
      case 'totalrevenue':
        return '#1FBF75'
      case 'lostrevenue':
        return '#d50f0f'
      default:
        return '#1FBF75'
    }
  }

  useEffect(() => {
    let obj = {
      StartDate: moment(new Date()).format('YYYY-MM-DD'),
      EndDate: moment(new Date()).format('YYYY-MM-DD'),
      locationOrderType: "fullfilled"
    }
    dispatch(getReportData(obj, (res) => {
      setGraphData(res.data)
    }))
    dispatch(getTotalIncome(obj, (res) => {
      setIncomeData(res.data)
    }))
    dispatch(getReportDataforLocation(obj, (res) => {
      setLocationData(res.data)
    }))
    dispatch(getTopItems(obj, (res) => {
      setTopItems(res.data)
    }))
    dispatch(getTopCategory(obj, (res) => {
      setTopCategory(res.data)
    }))
    dispatch(getReportDataforrevenue(obj, (res) => {
      setRevenueData(res.data[0])
      if (res.data.length > 0) {
        let obj = {
          carddelivery: res.data[0].card_delivery_amount,
          cashdelivery: res.data[0].cash_delivery_amount,
          cardcollection: res.data[0].card_collection_amount,
          cashcollection: res.data[0].cash_collection_amount,
          carddinein: res.data[0].card_dinein_amount,
          cashdinein: res.data[0].cash_dinein_amount,
        }
        setPieChartData(obj)
      }
    }))
  }, [])


  const onsubmit = (values) => {
    setCatagoryAccordiankey('')
    dispatch(getReportData(values, (res) => {
      setGraphData(res.data)
    }))
    dispatch(getTotalIncome(values, (res) => {
      setIncomeData(res.data)
    }))
    dispatch(getReportDataforLocation(values, (res) => {
      setLocationData(res.data)
    }))
    dispatch(getTopItems(values, (res) => {
      setTopItems(res.data)
    }))
    dispatch(getTopCategory(values, (res) => {
      setTopCategory(res.data)
    }))
    dispatch(getReportDataforrevenue(values, (res) => {
      setRevenueData(res.data[0])
      if (res.data.length > 0) {
        let obj = {
          carddelivery: res.data[0].card_delivery_amount,
          cashdelivery: res.data[0].cash_delivery_amount,
          cardcollection: res.data[0].card_collection_amount,
          cashcollection: res.data[0].cash_collection_amount,
          carddinein: res.data[0].card_dinein_amount,
          cashdinein: res.data[0].cash_dinein_amount,
        }
        setPieChartData(obj)
      }
    }))
  }

  const handleChangeType = (e, setFieldValue, values) => {
    setCatagoryAccordiankey('')
    let name = e.target.name
    let value = e.target.value
    console.log(values)
    let obj = {}

    if (name == 'PaymentType') {
      obj.StartDate = values.StartDate
      obj.EndDate = values.EndDate
      obj.PaymentType = value
      obj.OrderType = values.OrderType
    }

    if (name == 'OrderType') {
      obj.StartDate = values.StartDate
      obj.EndDate = values.EndDate
      obj.OrderType = value
      obj.PaymentType = values.PaymentType
    }
    dispatch(getReportData(obj, (res) => {
      setGraphData(res.data)
    }))
    dispatch(getTotalIncome(obj, (res) => {
      setIncomeData(res.data)
    }))
  }
  const handleChangeOrderType = (e, setFieldValue, values) => {
    setCatagoryAccordiankey('')
    let value = e.target.value
    let obj = {}

    obj.StartDate = values.StartDate
    obj.EndDate = values.EndDate
    obj.locationOrderType = value

    dispatch(getReportDataforLocation(obj, (res) => {
      setLocationData(res.data)
    }))
  }


  const handleAccordianClick = (item, values, index) => {
    let obj = {
      CategoryId: item.CategoryId,
      StartDate: values.StartDate,
      EndDate: values.EndDate,
    }
    setCatagoryAccordiankey(catagoryAccordiankey === index ? '' : index)
    dispatch(getTopItems(obj, (res) => {
      setCatagoryItems(res.data)
    }))
  }


  return (
    <div className='Livereporting'>

      <Formik
        enableReinitialize
        initialValues={{
          range: 'today',
          StartDate: moment(new Date()).format('YYYY-MM-DD'),
          EndDate: moment(new Date()).format('YYYY-MM-DD'),
          PaymentType: 'all',
          OrderType: 'all',
          locationOrderType: 'fullfilled'
        }}

        validationSchema={
          Yup.object().shape({

          })}
        onSubmit={onsubmit}
      >
        {(props) => {
          const { values, touched, errors, setFieldValue, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
          return (
            <>
              <Form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <FormControl fullWidth>
                      <TextField
                        select
                        fullWidth
                        required
                        value={values.range}
                        label={t('reporting.range')}
                        name='range'
                        onChange={(e) => { handleChange(e); handleChangeRange(e, setFieldValue, values) }}
                        error={Boolean(errors.range) && touched.range}
                        helperText={Boolean(errors.range) && touched.range ? errors.range : ""}
                      >
                        <MenuItem value='today'>{t('reporting.today')}</MenuItem>
                        <MenuItem value='yesterday'>{t('reporting.yesterday')}</MenuItem>
                        <MenuItem value='7days'>{t('reporting.last7days')}</MenuItem>
                        <MenuItem value='28days'>{t('reporting.last28days')}</MenuItem>
                        <MenuItem value='custom'>{t('reporting.custom')}</MenuItem>

                      </TextField>
                    </FormControl>
                  </div>
                  {values.range == 'custom' &&
                    <>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <FormControl size="small" variant="outlined" fullWidth>
                          <TextField
                            onKeyDown={() => { "return false" }}
                            fullWidth
                            inputProps={{ max: values.EndDate }}
                            type="date"
                            name='StartDate'
                            variant="outlined"
                            label={t('reporting.fromdate')}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={values.StartDate}
                            error={Boolean(errors.StartDate) && touched.StartDate}
                            helperText={(Boolean(errors.StartDate) && touched.StartDate) ? errors.StartDate : ''}
                          />
                        </FormControl>
                      </div>
                      <div className='col-lg-3 col-md-4 col-sm-12'>
                        <FormControl size="small" variant="outlined" fullWidth>
                          <TextField
                            onKeyDown={() => { "return false" }}
                            fullWidth
                            type="date"
                            inputProps={{ min: values.StartDate ? new Date(values.StartDate).toISOString().slice(0, 10) : "" }}
                            name='EndDate'
                            variant="outlined"
                            label={t('reporting.todate')}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={values.EndDate}
                            error={Boolean(errors.EndDate) && touched.EndDate}
                            helperText={(Boolean(errors.EndDate) && touched.EndDate) ? errors.EndDate : ''}
                          />
                        </FormControl>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-6">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          size="large"
                        // disabled={!isValid || isDisabled}
                        >
                          {t('buttons.filter')}
                        </button>
                      </div>
                    </>}
                </div>
                <div className="row">
                  <div className="col-lg-12 pt-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="default-tab">
                          <Tab.Container defaultActiveKey='fullfilledOrder'>
                            <div className="row">
                              <Nav as='ul' className="nav nav-tabs">
                                <div className="col-lg-3">
                                  <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#fullfilledOrder" eventKey='fullfilledOrder'>
                                      <div className='text-center p-tb15' >
                                        <p className='fs-18 mb-2'>
                                          <span className='fs-2 fw-bolder' style={{ color: color('fullfilledOrder') }}>
                                            {incomeData.fullfilled_order}
                                          </span>
                                          <br />
                                          {t('reporting.fullfilledorders')}</p>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="col-lg-3">
                                  <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#totalrevenue" eventKey='totalrevenue'>
                                      <div className='text-center p-tb15'>
                                        <p className='fs-18 mb-2'>
                                          <span className='fs-2 fw-bolder' style={{ color: color('totalrevenue') }}>
                                            {t("currency", { Number: parseFloat(incomeData && incomeData.total_income ? incomeData.total_income : 0) })}
                                          </span>
                                          <br />
                                          {t('reporting.totalrevenue')}</p>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="col-lg-3">
                                  <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#unfullfilledorders" eventKey='unfullfilledorders'>
                                      <div className='text-center p-tb15' >
                                        <p className='fs-18 mb-2'>
                                          <span className='fs-2 fw-bolder' style={{ color: color('unfullfilledorders') }}>
                                            {incomeData.unfullfilled_order}
                                          </span>
                                          <br />
                                          {t('reporting.unfullfilledorders')}</p>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                                <div className="col-lg-3">
                                  <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#lostrevenue" eventKey='lostrevenue'>
                                      <div className='text-center p-tb15' >
                                        <p className='fs-18 mb-2'>
                                          <span className='fs-2 fw-bolder' style={{ color: color('lostrevenue') }}>
                                            {t("currency", { Number: parseFloat(incomeData && incomeData.total_refund_amount ? incomeData.total_refund_amount : 0) })}
                                          </span>
                                          <br />
                                          {t('reporting.lostrevenue')}</p>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                </div>
                              </Nav>
                            </div>
                            <Tab.Content>
                              <div className="row p-3">
                                <div className='offset-lg-6 offset-md-4 col-lg-3 col-md-4 col-sm-12'>
                                  <FormControl fullWidth>
                                    <TextField
                                      select
                                      fullWidth
                                      required
                                      value={values.PaymentType}
                                      label={t('reporting.paymenttype')}
                                      name='PaymentType'
                                      onChange={(e) => { handleChange(e); handleChangeType(e, setFieldValue, values) }}
                                      error={Boolean(errors.PaymentType) && touched.PaymentType}
                                      helperText={Boolean(errors.PaymentType) && touched.PaymentType ? errors.PaymentType : ""}
                                    >
                                      <MenuItem value='all'>{t('reporting.allpaymenttype')}</MenuItem>
                                      <MenuItem value='card'>{t('reporting.card')}</MenuItem>
                                      <MenuItem value='cash'>{t('reporting.cash')}</MenuItem>
                                    </TextField>
                                  </FormControl>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-12 pt-lg-0 pt-md-0 pt-sm-2'>
                                  <FormControl fullWidth>
                                    <TextField
                                      select
                                      fullWidth
                                      required
                                      value={values.OrderType}
                                      label={t('reporting.ordertype')}
                                      name='OrderType'
                                      onChange={(e) => { handleChange(e); handleChangeType(e, setFieldValue, values) }}
                                      error={Boolean(errors.OrderType) && touched.OrderType}
                                      helperText={Boolean(errors.OrderType) && touched.OrderType ? errors.OrderType : ""}
                                    >
                                      <MenuItem value='all'>{t('reporting.allordertype')}</MenuItem>
                                      <MenuItem value='delivery'>{t('reporting.delivery')}</MenuItem>
                                      <MenuItem value='collection'>{t('reporting.collection')}</MenuItem>
                                      <MenuItem value='dine-in'>{t('reporting.dinein')}</MenuItem>
                                    </TextField>
                                  </FormControl>
                                </div>
                              </div>
                              <Tab.Pane id="fullfilledOrder" eventKey='fullfilledOrder'>
                                <ApexBarChartForReporting graphData={graphData} graphKey={'fullfilled'} color={color('fullfilledOrder')} />
                              </Tab.Pane>
                              <Tab.Pane id="totalrevenue" eventKey='totalrevenue'>
                                <ApexBarChartForReporting graphData={graphData} graphKey={'totalrevenue'} color={color('totalrevenue')} />
                              </Tab.Pane>
                              <Tab.Pane id="unfullfilledorders" eventKey='unfullfilledorders'>
                                <ApexBarChartForReporting graphData={graphData} graphKey={'unfullfilled'} color={color('unfullfilledorders')} />
                              </Tab.Pane>
                              <Tab.Pane id="lostrevenue" eventKey='lostrevenue'>
                                <ApexBarChartForReporting graphData={graphData} graphKey={'lostrevenue'} color={color('lostrevenue')} />
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body ">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <h4 className="cate-title mb-0">{t('reporting.orderbylocation')}</h4>
                              <FormControl>
                                <TextField
                                  select
                                  fullWidth
                                  required
                                  value={values.locationOrderType}
                                  label={t('reporting.paymenttype')}
                                  name='locationOrderType'
                                  onChange={(e) => { handleChange(e); handleChangeOrderType(e, setFieldValue, values) }}
                                  error={Boolean(errors.locationOrderType) && touched.locationOrderType}
                                  helperText={Boolean(errors.locationOrderType) && touched.locationOrderType ? errors.locationOrderType : ""}
                                >
                                  <MenuItem value='fullfilled'>{t('reporting.fullfilledorders')}</MenuItem>
                                  <MenuItem value='unfullfilled'>{t('reporting.unfullfilledorders')}</MenuItem>
                                </TextField>
                              </FormControl>
                            </div>
                            <table className="table table-borderless">
                              <tbody>
                                {locationData.length > 0 ? locationData.map(
                                  (data, i) => {
                                    if (values.locationOrderType == 'fullfilled') {
                                      return <tr key={i}>
                                        <th scope="row">{data.PostCode}</th>
                                        <td className='w-100'> <ProgressBar variant="determinate" now={data.delivered} /></td>
                                        <td> {`${t("reporting.acceptedcount", { order: data.delivered })}`}</td>
                                        <td>  | </td>
                                        <td> {t("currency", { Number: data.delivered_amount ? parseFloat(data.delivered_amount) : 0 })}</td>
                                      </tr>
                                    } else if (values.locationOrderType == 'unfullfilled') {
                                      return <tr key={i}>
                                        <th scope="row">{data.PostCode}</th>
                                        <td className='w-100'> <ProgressBar variant="determinate" now={data.cancel} /></td>
                                        <td> {`${t("reporting.rejectedcount", { order: data.cancel })}`}</td>
                                        <td>  | </td>
                                        <td> {t("currency", { Number: data.cancel_amount ? parseFloat(data.cancel_amount) : 0 })}</td>
                                      </tr>
                                    }

                                  })
                                  :
                                  <tr>
                                    <td colSpan={3}>
                                      <Empty />
                                    </td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-12 pb-2">
                                <h3>{t('reporting.revenuebyordertype')}</h3>
                              </div>
                              <div className="col-lg-5">
                                <RevenuebyorderPieChart pieChartData={pieChartData} revenueData={revenueData} />
                              </div>
                              <div className="col-lg-7">
                                <table className="table table-borderless">
                                  <tbody>
                                    <tr>
                                      <th scope="row">
                                        <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16" height="16" rx="4" fill={chartColors[0]} />
                                        </svg>
                                        {t('reporting.carddelivery')}
                                      </th>
                                      <td className='text-end'>
                                        {revenueData && revenueData.card_delivery_amount > 0 ?
                                          ((parseFloat(revenueData.card_delivery_amount) * 100) / parseFloat(revenueData.total_revanue_amount)).toFixed(2) + '%'
                                          : "0%"}
                                      </td>
                                      <td style={{ width: "1%" }}>|</td>
                                      <td className='text-end'> {t("currency", { Number: parseFloat(revenueData && revenueData.card_delivery_amount ? revenueData.card_delivery_amount : 0) })}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16" height="16" rx="4" fill={chartColors[1]} />
                                        </svg>
                                        {t('reporting.cashdelivery')}
                                      </th>
                                      <td className='text-end'>
                                        {revenueData && revenueData.cash_delivery_amount > 0 ?
                                          ((parseFloat(revenueData.cash_delivery_amount) * 100) / parseFloat(revenueData.total_revanue_amount)).toFixed(2) + '%'
                                          : "0%"}
                                      </td>
                                      <td>|</td>
                                      <td className='text-end'> {t("currency", { Number: parseFloat(revenueData && revenueData.cash_delivery_amount ? revenueData.cash_delivery_amount : 0) })}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16" height="16" rx="4" fill={chartColors[2]} />
                                        </svg>
                                        {t('reporting.cardcollection')}
                                      </th>
                                      <td className='text-end'>
                                        {revenueData && revenueData.card_collection_amount > 0 ?
                                          ((parseFloat(revenueData.card_collection_amount) * 100) / parseFloat(revenueData.total_revanue_amount)).toFixed(2) + '%'
                                          : "0%"}
                                      </td>
                                      <td>|</td>
                                      <td className='text-end'> {t("currency", { Number: parseFloat(revenueData && revenueData.card_collection_amount ? revenueData.card_collection_amount : 0) })}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16" height="16" rx="4" fill={chartColors[3]} />
                                        </svg>
                                        {t('reporting.cashcollection')}
                                      </th>
                                      <td className='text-end'>
                                        {revenueData && revenueData.cash_collection_amount > 0 ?
                                          ((parseFloat(revenueData.cash_collection_amount) * 100) / parseFloat(revenueData.total_revanue_amount)).toFixed(2) + '%'
                                          : "0%"}
                                      </td>
                                      <td>|</td>
                                      <td className='text-end'>{t("currency", { Number: parseFloat(revenueData && revenueData.cash_collection_amount ? revenueData.cash_collection_amount : 0) })}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16" height="16" rx="4" fill={chartColors[4]} />
                                        </svg>
                                        {t('reporting.carddinein')}
                                      </th>
                                      <td className='text-end'>
                                        {revenueData && revenueData.card_dinein_amount > 0 ?
                                          ((parseFloat(revenueData.card_dinein_amount) * 100) / parseFloat(revenueData.total_revanue_amount)).toFixed(2) + '%'
                                          : "0%"}
                                      </td>
                                      <td>|</td>
                                      <td className='text-end'> {t("currency", { Number: parseFloat(revenueData && revenueData.card_dinein_amount ? revenueData.card_dinein_amount : 0) })}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16" height="16" rx="4" fill={chartColors[5]} />
                                        </svg>
                                        {t('reporting.cashdinein')}
                                      </th>
                                      <td className='text-end'>
                                        {revenueData && revenueData.cash_dinein_amount > 0 ?
                                          ((parseFloat(revenueData.cash_dinein_amount) * 100) / parseFloat(revenueData.total_revanue_amount)).toFixed(2) + '%'
                                          : "0%"}
                                      </td>
                                      <td>|</td>
                                      <td className='text-end'>
                                        {t("currency", { Number: parseFloat(revenueData && revenueData.cash_dinein_amount ? revenueData.cash_dinein_amount : 0) })}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-xl-4">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="cate-title mb-0">{t('reporting.averageordervalue')}</h4>
                            <p className=' pt-3 mb-0 fs-30 fw-bold color-primary'>
                              {t("currency", { Number: parseFloat(incomeData && incomeData.average_order_value ? incomeData.average_order_value : 0) })}
                            </p>
                            {t("reporting.fromordercontaininganaverageofitems", { item: 2 })}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-sm-6">
                        <div className="card">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4 className="cate-title">{t('reporting.popularfooditem')}</h4>
                            </div>
                            {/* pie chart */}
                            {topItems && topItems.length > 0 && <PopularPieChartReporting topItem={topItems} />}
                            {/* pie chart */}
                            <h6 className="font-w700 mb-3">{t('reporting.legend')}</h6>
                            {topItems && topItems.length > 0 && topItems.map((item, index) => {
                              item.color = chartColors[index]
                              return <div key={index} className="d-flex justify-content-between mb-2">
                                <span>
                                  <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="16" height="16" rx="4" fill={item.color} />
                                  </svg>
                                  {item.ItemName}
                                </span>
                                <h6>{item.order_count}</h6>
                              </div>
                            })}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="cate-title mb-0">{t('reporting.topmenuitems')}</h4>
                            {topItems.length > 0 && topItems.map((item, i) => {
                              return <div key={i} className='d-flex justify-content-between pt-2 pb-2'>
                                <div>
                                  {item.ItemName}
                                </div>
                                <div className='color-primary'>
                                  {item.order_count}
                                </div>
                              </div>
                            })}
                          </div>
                        </div>
                      </div> */}
                      <div className="col-xl-12">
                        <div className="card h-100">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4 className="cate-title">{t('reporting.topsellingcategory')}</h4>
                            </div>
                            <div>
                              <p className="font-w500 mb-0">{t('reporting.totalitemdelivered')}</p>
                              <h3>{
                                topCategory.length > 0 && topCategory.reduce((accumulator, object) => {
                                  return parseInt(accumulator) + parseInt(object.order_count);
                                }, 0)}</h3>
                              <Accordion className="accordion accordion-no-gutter bg-transparant" activeKey={catagoryAccordiankey}>
                                {topCategory && topCategory.length > 0 && topCategory.map((item, index) => {
                                  return <Accordion.Item key={index} eventKey={index} onClick={(e) => handleAccordianClick(item, values, index)}>
                                    <Accordion.Header className="accordion-header">
                                      <div className={` d-flex justify-content-between align-items-center w-100`}>
                                        <span><strong>{item.CategoryName}</strong></span>
                                        <span><strong>{item.order_count}</strong></span>
                                      </div>
                                      {/* {item.CategoryName} */}
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={index}>
                                      <div className='ps-4'>
                                        {catagoryItems.length > 0 && catagoryItems.map((ele, eleIndex) => {
                                          return <div key={eleIndex} className={`d-flex justify-content-between align-items-center w-100`}>
                                            <span>{ele.ItemName}</span>
                                            <span>{ele.order_count}</span>
                                          </div>
                                        })}
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion.Item>
                                })}
                              </Accordion>
                              {/* {topCategory && topCategory.length > 0 && topCategory.map((item, index) => {
                                return <div key={index} className={`${index == topCategory.length - 1 ? 'border-0' : ''} data-content d-flex justify-content-between align-items-center  pb-2`}>
                                  <span>{item.CategoryName}</span>
                                  <h6 className="mb-0">{item.order_count}</h6>
                                </div>
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>


    </div>
  )
}

export default LiveReporting
