import { FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, MenuItem, Radio, RadioGroup,  TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditUserListApi } from '../../../services/RestaurantServices';
import { getRoleListAPi } from '../../../services/RoleServices';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const AddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData } = props
    const [role, setRole] = useState([])

    useEffect(() => {
        dispatch(getRoleListAPi((res) => {
            setRole(res.data)
        }))
    }, [])

    const onsubmit = (values) => {
        if (editData && editData.id) {
            values.id = editData.id
        }
        values.UserTypeId = values.role_id
        dispatch(addEditUserListApi(values, (res) => {
            setOpenModal(false)
        }))

    }
    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    first_name: editData ? editData.first_name : '',
                    last_name: editData ? editData.last_name : '',
                    job_title: editData ? editData.job_title : '',
                    gender: editData ? editData.gender : 'Male',
                    email: editData ? editData.email : '',
                    password: editData ? editData.password : '',
                    showPassword: false,
                    role_id: editData ? editData.role_id : '',
                    date_of_birth: editData && editData.date_of_birth != '0000-00-00' ? editData.date_of_birth : '',
                    date_of_joining: editData && editData.date_of_joining != '0000-00-00' ? editData.date_of_joining : '',
                    phone_number: editData ? editData.phone_number : '',
                }}

                validationSchema={editData ?
                    Yup.object().shape({
                        first_name: Yup.string().required("First Name is required."),
                        last_name: Yup.string().required("last Name is required."),
                        email: Yup.string('Enter your email')
                            .email('Enter a valid email')
                            .required('Email is required'),
                        role_id: Yup.number().required("Role is required."),
                        phone_number: Yup.string()
                            .test(
                                'is-valid-Phone Number',
                                'Invalid Phone Number. It should start with 0 and be 11 characters long',
                                value => {
                                    console.log(value)
                                    if (!value) {
                                        return false; // Empty value is considered invalid
                                    }
                                    return /^0\d{10}$/.test(value);
                                }
                            )
                            // .matches('^0\d{10}$', 'Invalid Phone Number. It should start with 0 and be 11 characters long')
                            .required("Phone Number is required."),
                        // phone_number: Yup.string().required('Phone Number is required').max(15, "Invalid phone no.").min(6, "Invalid phone no."),
                    })

                    :
                    Yup.object().shape({
                        first_name: Yup.string().required("First Name is required."),
                        last_name: Yup.string().required("last Name is required."),
                        email: Yup.string('Enter your email')
                            .email('Enter a valid email')
                            .required('Email is required'),

                        password: Yup.string().required('Password is required'),
                        role_id: Yup.number().required("Role is required."),
                        phone_number: Yup.string()
                            .test(
                                'is-valid-Phone Number',
                                'Invalid Phone Number. It should start with 0 and be 11 characters long',
                                value => {
                                    console.log(value)
                                    if (!value) {
                                        return false; // Empty value is considered invalid
                                    }
                                    return /^0\d{10}$/.test(value);
                                }
                            )
                            // .matches('^0\d{10}$', 'Invalid Phone Number. It should start with 0 and be 11 characters long')
                            .required("Phone Number is required."),
                        // phone_number: Yup.string().required('Phone Number is required').max(15, "Invalid phone no.").min(6, "Invalid phone no."),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, setFieldValue, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('userscreen.updateuser') : t('userscreen.adduser')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={() => setOpenModal(false)}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.first_name) && touched.first_name}
                                                helperText={(Boolean(errors.first_name) && touched.first_name) ? errors.first_name : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="first_name"
                                                label={t('userscreen.firstname')}
                                                value={values.first_name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.last_name) && touched.last_name}
                                                helperText={(Boolean(errors.last_name) && touched.last_name) ? errors.last_name : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="last_name"
                                                label={t('userscreen.lastname')}
                                                value={values.last_name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                error={Boolean(errors.job_title) && touched.job_title}
                                                helperText={(Boolean(errors.job_title) && touched.job_title) ? errors.job_title : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="job_title"
                                                label={t('userscreen.jobtitle')}
                                                value={values.job_title}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label" style={{ color: errors.gender && 'red' }}>{t('userscreen.gender')}</FormLabel>
                                                <FormHelperText className="Mui-error">{Boolean(errors.gender) || touched.gender ? errors.gender : ""}</FormHelperText>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="gender"
                                                    value={values.gender}
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                </RadioGroup>

                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                disabled={editData ? true : false}
                                                required
                                                error={Boolean(errors.email) && touched.email}
                                                helperText={(Boolean(errors.email) && touched.email) ? errors.email : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="email"
                                                label={t('userscreen.email')}
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                type="text"
                                                name="phone_number"
                                                InputProps={{
                                                    inputProps: {
                                                        inputMode: 'numeric',
                                                    },
                                                    onInput: (e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                    },
                                                }}
                                                required
                                                // type='number'
                                                // onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.phone_number) && touched.phone_number}
                                                helperText={(Boolean(errors.phone_number) && touched.phone_number) ? errors.phone_number : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                // name="phone_number"
                                                label={t('userscreen.phonenumber')}
                                                value={values.phone_number}
                                                onChange={handleChange}
                                            />
                                            {/* <TextField
                                                required
                                                error={Boolean(errors.phone_number) && touched.phone_number}
                                                helperText={(Boolean(errors.phone_number) && touched.phone_number) ? errors.phone_number : ''}
                                                type='number'
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="phone_number"
                                                label={t('userscreen.phonenumber')}
                                                value={values.phone_number}
                                                onChange={handleChange}
                                            /> */}
                                        </div>
                                        {!editData && <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => { setFieldValue('showPassword', !values.showPassword) }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}

                                                error={Boolean(errors.password) && touched.password}
                                                helperText={(Boolean(errors.password) && touched.password) ? errors.password : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                type={values.showPassword ? "text" : "password"}
                                                name="password"
                                                label={t('userscreen.password')}
                                                value={values.password}
                                                onChange={handleChange}
                                            />
                                        </div>}
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                fullWidth
                                                label={t('userscreen.dateofbirth')}
                                                name='date_of_birth'
                                                type="date"
                                                value={values.date_of_birth}
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                fullWidth
                                                label={t('userscreen.dateofjoining')}
                                                name='date_of_joining'
                                                type="date"
                                                value={values.date_of_joining}
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="demo-simple-select"
                                                    value={values.role_id}
                                                    label={t('userscreen.role')}
                                                    name='role_id'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.role_id) && touched.role_id}
                                                    helperText={Boolean(errors.role_id) && touched.role_id ? errors.role_id : ""}
                                                >
                                                    <MenuItem disabled value=''>Select Role</MenuItem>
                                                    {role && role.map((item, index) => {
                                                        return <MenuItem key={index} value={item.id}>{item.role_name}</MenuItem>
                                                    })}
                                                </TextField>

                                            </FormControl>
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default AddEditModal
