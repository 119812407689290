import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { S3ImageUpload } from '../../../../constants/constants'
import { getCustomerList } from '../../../../services/EmailMarketingServices'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const ViewCampaignModal = (props) => {
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData } = props
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getCustomerList((res) => {
            let tempName = []
            let tempCustomerIds = viewData.customer_ids.split(',')
            if (tempCustomerIds.length > 0) {
                tempCustomerIds.map((item) => {
                    if (res.data && res.data.length > 0) {
                        let tempdata = res.data.filter((items) => items.user_id == item)
                        if (tempdata.length > 0) {
                            tempName.push(tempdata[0].full_name)
                        }
                    }
                })
                viewData.customer_name = tempName.join(', ')
            }

        }))
    }, [])


    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('campaign.viewcampaign')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('campaign.name')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.name}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('campaign.customers')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.customer_name}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('campaign.templatename')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.template_name}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('campaign.status')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.status}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('campaign.schedule')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2"> {t("date_format", { date: viewData.schedule_at })} </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewCampaignModal
