import { TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup'

const RestaurantLinks = (props) => {
    const {t} =  useTranslation('common');
    const { InitialValues, onsubmit } = props
    return (
        <div className="pt-3">
            <div className="settings-form">
                <h4 className="text-primary p-3">{t('profile.tab.restaurantlinks')}</h4>
                <Formik
                 enableReinitialize
                    initialValues={InitialValues}
                    onSubmit={onsubmit}
                >
                    {(props) => {
                        const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                        return (
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <TextField
                                            error={Boolean(errors.AppStoreURL) && touched.AppStoreURL}
                                            helperText={(Boolean(errors.AppStoreURL) && touched.AppStoreURL) ? errors.AppStoreURL : ''}
                                            fullWidth
                                            onBlur={handleBlur}
                                            name="AppStoreURL"
                                            label={t('profile.restaurantlinks.appstoreurl')}
                                            value={values.AppStoreURL}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <TextField
                                            error={Boolean(errors.PlayStoreURL) && touched.PlayStoreURL}
                                            helperText={(Boolean(errors.PlayStoreURL) && touched.PlayStoreURL) ? errors.PlayStoreURL : ''}
                                            fullWidth
                                            onBlur={handleBlur}
                                            name="PlayStoreURL"
                                            label={t('profile.restaurantlinks.Playstoreurl')}
                                            value={values.PlayStoreURL}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <TextField
                                            error={Boolean(errors.LinkedInURL) && touched.LinkedInURL}
                                            helperText={(Boolean(errors.LinkedInURL) && touched.LinkedInURL) ? errors.LinkedInURL : ''}
                                            fullWidth
                                            onBlur={handleBlur}
                                            name="LinkedInURL"
                                            label={t('profile.restaurantlinks.linkedinurl')}
                                            value={values.LinkedInURL}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row pt-4">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <TextField
                                            error={Boolean(errors.FacebookURL) && touched.FacebookURL}
                                            helperText={(Boolean(errors.FacebookURL) && touched.FacebookURL) ? errors.FacebookURL : ''}
                                            fullWidth
                                            onBlur={handleBlur}
                                            name="FacebookURL"
                                            label={t('profile.restaurantlinks.facebookurl')}
                                            value={values.FacebookURL}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <TextField
                                            error={Boolean(errors.InstagramURL) && touched.InstagramURL}
                                            helperText={(Boolean(errors.InstagramURL) && touched.InstagramURL) ? errors.InstagramURL : ''}
                                            fullWidth
                                            onBlur={handleBlur}
                                            name="InstagramURL"
                                            label={t('profile.restaurantlinks.instagramurl')}
                                            value={values.InstagramURL}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <TextField
                                            error={Boolean(errors.TwitterURL) && touched.TwitterURL}
                                            helperText={(Boolean(errors.TwitterURL) && touched.TwitterURL) ? errors.TwitterURL : ''}
                                            fullWidth
                                            onBlur={handleBlur}
                                            name="TwitterURL"
                                            label={t('profile.restaurantlinks.twitterurl')}
                                            value={values.TwitterURL}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3 d-flex float-right align-items-center justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                        disabled={!isValid || isDisabled}
                                    >
                                       {t('buttons.save')}
                                    </button>
                                </div>

                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default RestaurantLinks
