
export const COLUMNSFORFOODMENU = [

	{
		Header: 'foodmenuscreen.menuitems',
		accessor: 'ItemName'
	},
	{
		Header: 'foodmenuscreen.price',
		accessor: 'Price'
	},
	{
		Header: 'foodmenuscreen.category',
		accessor: 'ParentCategoryName'
	},
	{
		Header: 'foodmenuscreen.subcategory',
		accessor: 'CategoryName'
	},
	{
		Header: 'foodmenuscreen.shortdescription',
		accessor: 'SortDescription',
	},
	{
		Header: 'foodmenuscreen.rating',
		accessor: 'UserRating'
	},

]
