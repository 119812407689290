
export const COLUMNSFORPROMOTIONS = [
	
	{
		Header : 'promotionscreen.promotion',
		accessor: 'Pro_Name'
	},
	{
		Header : 'promotionscreen.fromdate',
		accessor: 'Pro_FromDate'
	},
	{
		Header : 'promotionscreen.todate',
		accessor: 'Pro_ToDate',
	},
	{
		Header : 'promotionscreen.fromtime',
		accessor: 'Pro_FromTime'
	},
	{
		Header : 'promotionscreen.totime',
		accessor: 'Pro_ToTime'
	},
	{
		Header : 'promotionscreen.ondays',
		accessor: 'TimesPerUser'
	},
	{
		Header : 'promotionscreen.promotiontype',
		accessor: 'Pro_On_Days'
	},
	{
		Header : 'promotionscreen.status',
		accessor: 'IsActive'
	},
]
