import { Checkbox, FormControl, FormControlLabel, InputAdornment, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { symbolsArr } from '../../../../constants/constants';
import { useDispatch } from 'react-redux';
import { getPreOrderConfApi, updatePreOrderConfApi } from '../../../../services/PreOrdeServices';

const PreOrder = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const [settings, setSettings] = useState({})

    useEffect(() => {
        dispatch(getPreOrderConfApi((res) => {
            setSettings(res.data)
        }))
    }, [])

    const setValuesFor = (e, setFieldValue) => {
        const domain = e.target.value
        // e.persist()

        if (domain === "minutes") {
            setFieldValue('min_time_advance', '30')
        } else if (domain === "days") {
            setFieldValue('min_time_advance', '1')
        } else {
            setFieldValue('min_time_advance', '1')
        }
    }

    const setValuesForCollection = (e, setFieldValue) => {
        const domain = e.target.value

        if (domain === "minutes") {
            setFieldValue('min_time_advance_collection', '30')
        } else if (domain === "days") {
            setFieldValue('min_time_advance_collection', '1')
        } else {
            setFieldValue('min_time_advance_collection', '1')
        }
    }

    const onsubmit = (values) => {
        if (settings && settings.delivery && settings.delivery.id_delivery) {
            values.id_delivery = settings.delivery.id_delivery
        }

        if (settings && settings.collection && settings.collection.id_collection) {
            values.id_collection = settings.collection.id_collection
        }

        const data = {
            'id_delivery': values.id_delivery,
            'min_time_in': values.min_time_in,
            'min_time_advance': values.min_time_advance,
            'max_time_advance': values.max_time_advance,
            'interval_time': values.interval_time,
            'pre_order_on_open': values.pre_order_on_open,
            'pre_order_on_close': values.pre_order_on_close,
            'interval_time_in': "minutes",

            'id_collection': values.id_collection,
            'min_time_in_collection': values.min_time_in_collection,
            'min_time_advance_collection': values.min_time_advance_collection,
            'max_time_advance_collection': values.max_time_advance_collection,
            'interval_time_collection': values.interval_time_collection,
            'pre_order_on_open_collection': values.pre_order_on_open_collection,
            'pre_order_on_close_collection': values.pre_order_on_close_collection,
            'interval_time_in_collection': "minutes"
        };
        // console.log(data, "data")

        dispatch(updatePreOrderConfApi(data, (res) => {
            setTimeout(() => {
                dispatch(getPreOrderConfApi((res) => {
                    setSettings(res.data)
                }))
            }, 100);
        }))
    }
    return (
        <div className='card'>
            <div className="card-header">
                <h4 className="card-title">{t('preorderscreen.title')}</h4>
            </div>
            <Formik
                enableReinitialize
                initialValues={{
                    //delivery
                    min_time_advance: settings?.delivery?.min_time_advance ? settings.delivery.min_time_advance : '30',
                    min_time_in: settings?.delivery?.min_time_in ? settings.delivery.min_time_in : 'minutes',
                    max_time_advance: settings?.delivery?.max_time_advance ? settings.delivery.max_time_advance : '4',
                    interval_time: settings?.delivery?.interval_time ? settings.delivery.interval_time : '30',
                    pre_order_on_open: settings?.delivery?.pre_order_on_open == '1' ? true : false,
                    pre_order_on_close: settings?.delivery?.pre_order_on_close ? (settings.delivery.pre_order_on_close == '1' ? true : false) : true,
                    //collection
                    min_time_advance_collection: settings?.collection?.min_time_advance_collection ? settings.collection.min_time_advance_collection : '30',
                    min_time_in_collection: settings?.collection?.min_time_in_collection ? settings.collection.min_time_in_collection : 'minutes',
                    max_time_advance_collection: settings?.collection?.max_time_advance_collection ? settings.collection.max_time_advance_collection : '4',
                    interval_time_collection: settings?.collection?.interval_time_collection ? settings.collection.interval_time_collection : '15',
                    pre_order_on_open_collection: settings?.collection?.pre_order_on_open_collection == '1' ? true : false,
                    pre_order_on_close_collection: settings?.collection?.pre_order_on_close_collection ? (settings.collection.pre_order_on_close_collection == '1' ? true : false) : true,
                }}
                validationSchema={
                    Yup.object().shape({
                        min_time_advance_collection: Yup.number().when('min_time_in_collection', {
                            is: 'minutes',
                            then: Yup.number().required().min(30),
                            otherwise: Yup.number().when('min_time_in_collection', {
                                is: 'hours',
                                then: Yup.number().required().min(1),
                                otherwise: Yup.number().required().min(1),
                            }),
                        }),
                        min_time_in_collection: Yup.string().nullable().required(),
                        max_time_advance_collection: Yup.number().required().min(1),
                        // interval_time_collection: Yup.string().nullable().required("interval_time_collection is required."),

                        min_time_advance: Yup.number().when('min_time_in', {
                            is: 'minutes',
                            then: Yup.number().required().min(30),
                            otherwise: Yup.number().when('min_time_in', {
                                is: 'hours',
                                then: Yup.number().required().min(1),
                                otherwise: Yup.number().required().min(1),
                            }),
                        }),
                        min_time_in: Yup.string().nullable().required(),
                        max_time_advance: Yup.number().required().min(1),
                        // interval_time: Yup.string().nullable().required("interval_time is required."),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, setFieldValue, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <span><h4>{t('preorderscreen.settingfordelivery')}</h4></span>

                                                    <div className="row">
                                                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-12 pt-3'>
                                                            {t('preorderscreen.minimumtimeinadvance')} :
                                                        </div>
                                                        <div className='col-lg-3 col-md-3 col-sm-3 col-xs-6 pt-3'>
                                                            <TextField
                                                                required
                                                                type='number'
                                                                InputProps={{ inputProps: { min: 1 } }}
                                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                error={Boolean(errors.min_time_advance) && touched.min_time_advance}
                                                                fullWidth
                                                                onBlur={handleBlur}
                                                                name="min_time_advance"
                                                                value={values.min_time_advance}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className='col-lg-4 col-md-4 col-sm-4 col-xs-6  pt-3'>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    value={values.min_time_in}
                                                                    name='min_time_in'
                                                                    // onChange={handleChange}
                                                                    onChange={(e) => { handleChange(e); setValuesFor(e, setFieldValue) }}
                                                                    error={Boolean(errors.min_time_in) && touched.min_time_in}
                                                                >
                                                                    <MenuItem value='minutes'> {t('preorderscreen.minutes')}</MenuItem>
                                                                    <MenuItem value='hours'> {t('preorderscreen.hours')}</MenuItem>
                                                                    <MenuItem value='days'> {t('preorderscreen.days')}</MenuItem>
                                                                </TextField>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-12 pt-3'>
                                                            {t('preorderscreen.maximumtimeinadvance')}:
                                                        </div>
                                                        <div className='col-lg-7 col-md-7 col-sm-7 col-xs-12 pt-3' fullWidth>
                                                            <div className="input-group mb-3"  >
                                                                <FormControl fullWidth variant="outlined">
                                                                    <OutlinedInput
                                                                        id="outlined-adornment-weight"
                                                                        endAdornment={<InputAdornment position="end"> {t('preorderscreen.days')}</InputAdornment>}
                                                                        aria-describedby="outlined-weight-helper-text"
                                                                        // inputProps={{
                                                                        //     'aria-label': 'weight',
                                                                        // }}
                                                                        type='number'
                                                                        InputProps={{ inputProps: { min: 0 } }}
                                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                        value={values.max_time_advance}
                                                                        onChange={handleChange}
                                                                        name="max_time_advance"
                                                                        error={Boolean(errors.max_time_advance) && touched.max_time_advance}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-12 pt-3'> {t('preorderscreen.timeslot')}({t('preorderscreen.every')}): </div>

                                                        <div className='col-lg-7 col-md-7 col-sm-7 col-xs-12 pt-3' >
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                value={values.interval_time}
                                                                name='interval_time'
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value='15'> {t('preorderscreen.15minutes')}</MenuItem>
                                                                <MenuItem value='30'> {t('preorderscreen.30minutes')}</MenuItem>
                                                                <MenuItem value='45'> {t('preorderscreen.45minutes')}</MenuItem>
                                                                <MenuItem value='60'> {t('preorderscreen.1hour')}</MenuItem>
                                                                <MenuItem value='90'> {t('preorderscreen.1.5hour')}</MenuItem>
                                                                <MenuItem value='120'> {t('preorderscreen.2hour')}</MenuItem>
                                                                <MenuItem value='180'> {t('preorderscreen.3hour')}</MenuItem>
                                                                <MenuItem value='240'> {t('preorderscreen.4hour')}</MenuItem>
                                                                <MenuItem value='300'>{t('preorderscreen.5hour')}</MenuItem>
                                                                <MenuItem value='360'>{t('preorderscreen.6hour')}</MenuItem>
                                                            </TextField>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 pt-3'>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={values.pre_order_on_open} />}
                                                                label={t('preorderscreen.preorderonopen')}
                                                                name="pre_order_on_open"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 pt-3'>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={values.pre_order_on_close} />}
                                                                label={t('preorderscreen.preorderonclose')}
                                                                name="pre_order_on_close"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div className='card '>
                                                <div className='card-body'>
                                                    <span><h4>{t('preorderscreen.settingforcollection')}</h4></span>

                                                    <div className="row">
                                                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-12 pt-3'>
                                                            {t('preorderscreen.minimumtimeinadvance')}:
                                                        </div>
                                                        <div className='col-lg-3  col-md-3 col-sm-3 col-xs-6 pt-3'>
                                                            <TextField
                                                                required
                                                                type='number'
                                                                InputProps={{ inputProps: { min: 0 } }}
                                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                error={Boolean(errors.min_time_advance_collection) && touched.min_time_advance_collection}
                                                                fullWidth
                                                                onBlur={handleBlur}
                                                                name="min_time_advance_collection"
                                                                value={values.min_time_advance_collection}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className='col-lg-4 col-md-4 col-sm-4 col-xs-6  pt-3'>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    value={values.min_time_in_collection}
                                                                    name='min_time_in_collection'
                                                                    // onChange={handleChange}
                                                                    onChange={(e) => { handleChange(e); setValuesForCollection(e, setFieldValue) }}
                                                                    error={Boolean(errors.min_time_in_collection) && touched.min_time_in_collection}
                                                                >
                                                                    <MenuItem value='minutes'>{t('preorderscreen.minutes')}</MenuItem>
                                                                    <MenuItem value='hours'>{t('preorderscreen.hours')}</MenuItem>
                                                                    <MenuItem value='days'>{t('preorderscreen.days')}</MenuItem>
                                                                </TextField>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-12 pt-3'>
                                                            {t('preorderscreen.maximumtimeinadvance')}:
                                                        </div>
                                                        <div className='col-lg-7 col-md-7 col-sm-7 col-xs-12 pt-3' fullWidth>
                                                            <div className="input-group mb-3"  >
                                                                <FormControl fullWidth variant="outlined">
                                                                    <OutlinedInput
                                                                        id="outlined-adornment-weight"
                                                                        endAdornment={<InputAdornment position="end">{t('preorderscreen.days')}</InputAdornment>}
                                                                        aria-describedby="outlined-weight-helper-text"
                                                                        // inputProps={{
                                                                        //     'aria-label': 'weight',
                                                                        // }}
                                                                        type='number'
                                                                        InputProps={{ inputProps: { min: 0 } }}
                                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                        value={values.max_time_advance_collection}
                                                                        onChange={handleChange}
                                                                        name="max_time_advance_collection"
                                                                        error={Boolean(errors.max_time_advance_collection) && touched.max_time_advance_collection}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-12 pt-3'> {t('preorderscreen.timeslot')}({t('preorderscreen.every')}):</div>

                                                        <div className='col-lg-7 col-md-7 col-sm-7 col-xs-12 pt-3'>
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                value={values.interval_time_collection}
                                                                name='interval_time_collection'
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value='15'> {t('preorderscreen.15minutes')}</MenuItem>
                                                                <MenuItem value='30'> {t('preorderscreen.30minutes')}</MenuItem>
                                                                <MenuItem value='45'> {t('preorderscreen.45minutes')}</MenuItem>
                                                                <MenuItem value='60'> {t('preorderscreen.1hour')}</MenuItem>
                                                                <MenuItem value='90'> {t('preorderscreen.1.5hour')}</MenuItem>
                                                                <MenuItem value='120'> {t('preorderscreen.2hour')}</MenuItem>
                                                                <MenuItem value='180'> {t('preorderscreen.3hour')}</MenuItem>
                                                                <MenuItem value='240'> {t('preorderscreen.4hour')}</MenuItem>
                                                                <MenuItem value='300'>{t('preorderscreen.5hour')}</MenuItem>
                                                                <MenuItem value='360'>{t('preorderscreen.6hour')}</MenuItem>
                                                            </TextField>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 pt-3'>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={values.pre_order_on_open_collection} />}
                                                                label={t('preorderscreen.preorderonopen')}
                                                                name="pre_order_on_open_collection"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 pt-3'>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={values.pre_order_on_close_collection} />}
                                                                label={t('preorderscreen.preorderonclose')}
                                                                name="pre_order_on_close_collection"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer ">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </div>
                            </Form >
                        </>
                    );
                }}
            </Formik>
        </div >
    )
}

export default PreOrder
