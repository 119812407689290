import { TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup'
import { initiateRefundApi } from '../../../../services/orderService';
import { useDispatch, useSelector } from 'react-redux';
import { symbolsArr } from '../../../../constants/constants';

const RefundModal = (props) => {
    const { openRefundModal, setOpenRefundModal, orderDetails, refundData } = props;
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.commonData)

    const handleRefundModalClose = () => {
        setOpenRefundModal(false);
    }

    const onsubmit = (values) => {
        // values.OrderId =
        values.OrderId = orderDetails.OrderId;
        values.DeliveryMode = orderDetails.DeliveryMode;
        // values.CrossReference = orderDetails.CrossReference;
        values.UserId = orderDetails.Customer.CustomerId;
        values.RefundType = 2;
        dispatch(initiateRefundApi(values, (res) => {
            setOpenRefundModal(false);
        }))
    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openRefundModal}
            // onHide={handleRefundModalClose}
            size="md"
            centered
        >

            <Formik
                initialValues={{
                    // TotalAmount: orderDetails ? orderDetails.OrderTotal.GrandTotal : '',
                    // RemaingAmount: orderDetails ? (parseFloat(orderDetails.OrderTotal.GrandTotal) - parseFloat(refundData.RefundAmount)) : '',
                    Description: '',
                    AmountPaid: '',

                }}

                validationSchema={
                    Yup.object().shape({
                        Description: Yup.string().required("Reason is required."),
                        AmountPaid: Yup.number().required("Amount is required.").max((parseFloat(orderDetails.OrderTotal.GrandTotal) - parseFloat(refundData.RefundAmount)), "Amount must be less than or equal to Remaing Amount"),
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{t('ordermasterscreen.refund')}(#{orderDetails.TransactionId})</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleRefundModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                className='disabledTextfield'
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                                type='number'
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                required
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="TotalAmount"
                                                label={t('ordermasterscreen.totalamount')}
                                                value={orderDetails ? orderDetails.OrderTotal.GrandTotal : ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                className='disabledTextfield'
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                                type='number'
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                required
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="RemaingAmount"
                                                label={t('ordermasterscreen.remainingamount')}
                                                value={orderDetails ? (parseFloat(orderDetails.OrderTotal.GrandTotal) - parseFloat(refundData.RefundAmount)) : ''}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type='number'
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.AmountPaid) && touched.AmountPaid}
                                                helperText={(Boolean(errors.AmountPaid) && touched.AmountPaid) ? errors.AmountPaid : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="AmountPaid"
                                                label={t('ordermasterscreen.amounttopaid')}
                                                value={values.AmountPaid}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.Description) && touched.Description}
                                                helperText={(Boolean(errors.Description) && touched.Description) ? errors.Description : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Description"
                                                label={t('ordermasterscreen.reason')}
                                                value={values.Description}
                                                onChange={handleChange}
                                            />
                                        </div>

                                    </div>

                                </Modal.Body>
                                <Modal.Footer>

                                    <Button
                                        variant="danger light"
                                        onClick={handleRefundModalClose}
                                    >

                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary me-3"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {loading ?
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner> :
                                            t('buttons.refund')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >
    )
}

export default RefundModal
