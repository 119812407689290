import { FormControl, MenuItem, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup'
import { disconnectMailchimpAPi, getMailchimpAccountApi, getallAudience, pushCustomersApi } from '../../../../services/mailchimpServices';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import swal from 'sweetalert';

const MailchimpPushCust = () => {
    const { t } = useTranslation('common');
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [audience, setAudience] = useState([])
    let userData = JSON.parse(localStorage.getItem('userDetails'))
    const [mailchimpAccount, setMailchimpAccount] = useState({})

    useEffect(() => {
        let obj = {
            restaurant_id: userData.restaurant_id
        }
        dispatch(getMailchimpAccountApi(obj, (res) => {
            setMailchimpAccount(res.data)
        }))
    }, [])

    useEffect(() => {
        dispatch(getallAudience({ restaurant_id: userData.restaurant_id }, (res) => {
            setAudience(res.data)
        }))
    }, [])

    const onsubmit = (values) => {
        let obj = {
            list_id: values.audience,
            restaurant_id: userData.restaurant_id
        }
        dispatch(pushCustomersApi(obj, (res) => { }))
    }

    const handleBack = () => {
        navigate('/apps')
    }

    const handleDisconnect = () => {
        let obj = {
            user_id: userData.user_id
        }
        swal({
            title: `You are about to disconnect this Mailchimp account from ${userData.restaurant_name}.`,
            text:
                `This action is permanent and cannot be undone. To complete this disconnection, you will also need to disconnect ${userData.restaurant_name} from within your settings in Mailchimp. Your data will continue to exist in ${userData.restaurant_name} and Mailchimp, but updates will no longer sync.\n\nIf you want to use this Mailchimp account with ${userData.restaurant_name} in the future, you will need to connect and set it up again.`,
            buttons: {
                cancel: "Don't Disconnect",
                confirm: 'Disconnect Mailchimp'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Mailchimp successfully disconnected", {
                    icon: "success",
                });
                dispatch(disconnectMailchimpAPi(obj, (res) => {
                    navigate('/apps')
                }))
            }
        })

    }


    return (
        <div className='card'>
            <div className="card-header d-flex">
                <div>
                    <h4 className="card-title">
                        {t('mailchimpscreen.mailchimp')}
                    </h4>
                    {mailchimpAccount.last_synced_at ? <small>{t('mailchimpscreen.lastsyncedat')}:  {t("date_format", { date: (mailchimpAccount.last_synced_at) })} </small> : ''}
                </div>
                <Button onClick={handleBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
            </div>
            {mailchimpAccount ? <Formik
                enableReinitialize
                initialValues={{
                    audience: location.state ? location.state : (mailchimpAccount.mailchimp_list_id ? mailchimpAccount.mailchimp_list_id : '')
                }}

                validationSchema={
                    Yup.object().shape({
                        audience: Yup.string().required("audience is required.")
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>{t('mailchimpscreen.account')}</h4>
                                        </div>
                                        <div className='col-lg-4 col-sm-12 pt-3'>
                                            <p className='pe-2'>{t('mailchimpscreen.mailchimpconnected', { restaurantname: userData.restaurant_name })}</p>
                                        </div>
                                        <div className='col-lg-8 col-sm-12 pt-3'>
                                            <h6>{t('mailchimpscreen.mailchimpusername',)}</h6>
                                            <p>{mailchimpAccount.username}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row pt-3">
                                        <div className="col-12">
                                            <h4>{t('mailchimpscreen.synccustomers')}</h4>
                                        </div>
                                        <div className='col-lg-4 col-sm-12 pt-3'>
                                            <p>{t('mailchimpscreen.chooseaudience', { restaurantname: userData.restaurant_name })}</p>
                                        </div>
                                        <div className='col-lg-8 col-sm-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="demo-simple-select"
                                                    value={values.audience}
                                                    label={t('mailchimpscreen.audience')}
                                                    name='audience'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.audience) && touched.audience}
                                                    helperText={Boolean(errors.audience) && touched.audience ? errors.audience : ""}
                                                >
                                                    <MenuItem disabled value=''>Choose an audience</MenuItem>
                                                    {audience.length > 0 && audience.map((item, index) => {
                                                        return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                    })}

                                                </TextField>

                                            </FormControl>
                                            <div className='pt-4'>
                                                <Button type="submit" size="large" onClick={handleSubmit}>
                                                    {t('mailchimpscreen.pushallcustomer')}
                                                </Button>
                                            </div>
                                            <div className='pt-3'> {t('mailchimpscreen.sameemailwarning', { restaurantname: userData.restaurant_name })}</div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <div className="card-footer text-start">
                                <Button className='border-0' variant="outline-danger" size="large" onClick={handleDisconnect}>
                                    <i className="fa fa-trash"></i> &nbsp; {t('mailchimpscreen.disconnectmailchimp')}
                                </Button>
                            </div>

                        </>
                    );
                }}
            </Formik> :
                <div className="card-body">
                    <h4>Sorry! you are not connected please <Button className='border-0 p-1' variant="outline-primary" onClick={handleBack}>Connect.</Button></h4>

                </div>
            }

        </div>
    )
}

export default MailchimpPushCust
