import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useEffect } from "react";
import { getRestorantStatusApi, updateRestorantStatusApi } from "../../services/RestaurantServices";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { getOrderCountByStatusApi } from "../../services/orderService";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 60,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(34px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "&.Mui-checked" ? "green" : "black",
                opacity: 1,
                border: 0
            }
        }
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500
        })
    }
}));

export default function OfflineOnlineSwitch() {
    const dispatch = useDispatch()
    const [checked, setChecked] = React.useState(true);
    const [textFlag, setTextFlag] = React.useState(true);


    useEffect(() => {
        dispatch(getRestorantStatusApi((res) => {
            if (res == 0) {
                setChecked(true)
                setTextFlag(true)
            } else {
                setChecked(false)
                setTextFlag(false)
            }
        }))

    }, [])

    useEffect(() => {
        dispatch(getOrderCountByStatusApi((res) => {
        }))
    }, [])


    const handleChange = (e) => {
        setChecked(e.target.checked);
        let obj = {};
        if (e.target.checked) {
            obj.IsOffline = 0
            setTextFlag(true)
        } else {
            obj.IsOffline = 1
            setTextFlag(false)
        }
        dispatch(updateRestorantStatusApi(obj, (res) => { }))
    };
    return (
        <>
            {!textFlag && <div className="bg-danger text-white rounded mt-2 d-flex">
                <span className="p-2 align-self-center"> Restaurant is offline at the moment do you want to online this restaurant?</span>
                <span role="button"><i className=" p-2 fa-solid fa-xmark fs-24" onClick={() => setTextFlag(true)}></i></span>
            </div>}
            <Tooltip placement="bottom" title={checked ? "Click to offline" : "Click to online"}>
                <FormGroup>
                    <IOSSwitch
                        sx={{ m: 1 }}
                        checked={checked}
                        onChange={handleChange}
                    />
                </FormGroup>
            </Tooltip>
        </>
    );
}
