import { Checkbox } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import swal from 'sweetalert';
import TablePaginationHelper from '../../../../helpers/Pagination';
import TableSearch from '../../../../helpers/TableSearch';
import { DeleteModifierItemApi, getModifieritemListApi, getModifierTypeListApi } from '../../../../services/modifierservices';
import ModifierItemAddEditModal from './ModifierItemAddEditModal';
import { COLUMNSFORMODIFIERITEM } from './modifierItemColumns';
import ViewModifierItemModal from './ViewModifierItemModal';
import { Empty } from 'antd';

const ModifierItem = () => {
    const dispatch = useDispatch();
    const [modifierItemList, setModifierItemList] = useState([])
    const [modifierTypeList, setmodifierTypeList] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState({});
    const { auth } = useSelector((state) => state.auth);
    const { t } = useTranslation('common');
    const permissions = JSON.parse(auth.role_permissions)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});

    useEffect(() => {
        dispatch(getModifieritemListApi((res) => {
            setModifierItemList(res.data)
        }))
    }, [openModal])

    useEffect(() => {
        dispatch(getModifierTypeListApi((res) => {
            setmodifierTypeList(res.data)
        }))
    }, [])

    const columns = COLUMNSFORMODIFIERITEM
    const data = modifierItemList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state


    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }

    const handleView = (row) => {
       let data = JSON.parse(JSON.stringify(row.original))
        if (data && data.modifierType.length > 0) {
            data.modifierTypeName = row.original.modifierType[0].Type
        }
        setViewData(data)
        setOpenViewModal(true)
    }


    const handleDelete = (row) => {
        const id = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
              buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Modifier Item has been deleted!", {
                    icon: "success",
                });
                dispatch(DeleteModifierItemApi({ id }, (res) => {
                    dispatch(getModifieritemListApi((res) => {
                        setModifierItemList(res.data)
                    }))
                }))
            }
        })
    }


    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('modifieritemscreen.title')}</h4>
                <div className='d-flex justify-content-md-around TableSerchInput'>
                    <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    {((permissions && permissions.modifieritem && permissions.modifieritem.add) || permissions == null || !permissions.modifieritem )&& <Button onClick={handleAddOpen}>{t('buttons.addmodifieritem')}</Button>}
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive modifieritem">
                    <div className="dataTables_wrapper">
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {t(column.render('Header'))}
                                                <span className="ml-1">
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                    )
                                                        :
                                                        (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                    }
                                                </span>
                                            </th>
                                        ))}
                                        <th className='text-end'>{t('table.action')}</th>
                                    </tr>
                                ))}
                            </thead>
                            {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    prepareRow(row)
                                    row.original.modifierType = modifierTypeList.filter((item) => parseInt(item.ID) == parseInt(row.original.ProductTypeId))
                                    return (
                                        <tr {...row.getRowProps()}>
                                            <td > {row.original.ItemName} </td>
                                            <td > {t("currency", { Number: parseFloat(row.original.Price) })} </td>
                                            <td > {row.original.modifierType.length > 0 ? row.original.modifierType[0].Type : ""} </td>
                                            <td >
                                                <Checkbox
                                                    checked={row.original.IsActive == '1'}
                                                    inputProps={{ readOnly: true }}
                                                />
                                            </td>
                                            <td className='text-end'>
                                                {((permissions && permissions.modifieritem && permissions.modifieritem.view) || permissions == null || !permissions.modifieritem) && <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>}
                                                {((permissions && permissions.modifieritem && permissions.modifieritem.edit) || permissions == null || !permissions.modifieritem) && <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>}
                                                {((permissions && permissions.modifieritem && permissions.modifieritem.delete) || permissions == null || !permissions.modifieritem) && <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>}
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody> :
                                <tbody>
                                    <tr>
                                        <td colSpan={6} className='text-center p-5'><Empty /></td>
                                    </tr>
                                </tbody>}
                        </table>
                        {openModal && <ModifierItemAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} modifierTypeList={modifierTypeList} />}
                        {openViewModal && <ViewModifierItemModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
                    </div>
                </div>
            </div>
            {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
        </div>
    )
}

export default ModifierItem
