
export const COLUMNSFORMODIFIERGROUP = [
	
	{
		Header : 'modifiergroupscreen.name',
		accessor: 'Name'
	},
	{
		Header : 'modifiergroupscreen.description',
		accessor: 'Description'
	},
	{
		Header : 'modifiergroupscreen.type',
		accessor: 'Type',
	},
	{
		Header : 'modifiergroupscreen.displaynameontill',
		accessor: 'Name_Display_On_Till'
	},
]
