
export const COLUMNFORCAMPAIGN = [

	{
		Header: 'campaign.name',
		accessor: 'name'
	},
	{
		Header: 'campaign.customers',
		accessor: 'customer_ids'
	},
	{
		Header: 'campaign.templatename',
		accessor: 'template_name'
	},
	{
		Header: 'campaign.status',
		accessor: 'status'
	},
	{
		Header: 'campaign.schedule',
		accessor: 'schedule_at'
	},
]
