import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

/// Css
import "./index.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

import { ThemeContext } from "../context/ThemeContext";
import Setting from "./layouts/Setting";
import Settings from "./components/localizationsettings/settings";
import Auth from "../Auth";
import Error404 from "./pages/Error404";
import User from "./components/Users/User";
import RoleSettings from "./components/RoleSettings/RoleSettings";
import Category from "./components/Restaurant/Category/Category";
import ModifierGroup from "./components/Restaurant/ModifierGroup/ModifierGroup";
import ModifierType from "./components/Restaurant/ModifierType/ModifierType";
import Drivers from "./components/Drivers/List/Drivers";
import PageMaster from "./components/Restaurant/PageMaster/PageMaster";
import Voucher from "./components/Restaurant/vouchers/Voucher";
import Promocode from "./components/Restaurant/PromoCode/Promocode";
import ModifierItem from "./components/Restaurant/ModifierItem/ModifierItem";
import DeliveryCharges from "./components/Configuration/DeliveryCharges/DeliveryCharges";
import ImportExport from "./components/Configuration/importExport/importExport";
import TimeSchedule from "./components/Configuration/TimeSchedule/TimeSchedule";
import Promotions from "./components/Restaurant/Promotions/Promotions";
import FoodMenu from "./components/Restaurant/Menu/FoodMenu";
import OrderMaster from "./components/Restaurant/OrderMaster/OrderMaster";
import { Backdrop } from "@mui/material";
import { Logout, LogoutUser } from "../store/actions/AuthActions";
import { useCallback } from "react";
import AuthVerify from "../helpers/auth-verify";
import HomeBanner from "./components/HomeBanner/HomeBanner";
import PreOrder from "./components/Configuration/PreOrder/PreOrder";
import TimeConfig from "./components/Configuration/Timeconfig/TimeConfig";
import Mailchimp from "./components/Configuration/Mailchimp/Mailchimp";
import Mailchimpafterconnect from "./components/Configuration/Mailchimp/Mailchimpafterconnect";
import MailchimpConnected from "./components/Configuration/Mailchimp/MailchimpConnected";
import MailchimpPushCust from "./components/Configuration/Mailchimp/MailchimpPushCust";
import EmailMarketing from "./components/EmailMarketing/EmailTemplate/EmailMarketing";
import CampaignList from "./components/EmailMarketing/Campaign/CampaignList";
import Reporting from "./components/Reporting/Reporting";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import OrderHistory from "./components/Restaurant/OrderHistory/OrderHistory";
import Invoice from "./components/Restaurant/OrderHistory/Invoice";


const Markup = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logOut = useCallback(() => {
    dispatch(LogoutUser(navigate));
  }, [dispatch]);

  if (location.pathname == '/') {
    const userData = localStorage.getItem('token')
    if (!userData) {
      dispatch(LogoutUser(navigate));
    }
  }

  return (
    <>
      <Routes>

        <Route element={<LogoutLayout />} >
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/resetpassword/:slug' element={<ResetPassword />} />
        </Route>
        <Route element={<MainLayout />} >
          <Route exact path={'profile'} element={<AppProfile />} />

          {/* <Route index exact path={'/'} element={<Home />} />
          <Route exact path={'dashboard'} element={<Home />} /> */}
          <Route index exact path={'/'} element={<Reporting />} />
          <Route exact path={'dashboard'} element={<Reporting />} />
          {/* <Route exact path={'reporting'} element={<Reporting />} /> */}

          <Route element={<Auth allowedRoles={["admin", "restaurant"]} />}>
            <Route exact path={'orderMaster'} element={<OrderMaster />} />
            <Route exact path={'orderHistory'} element={<OrderHistory />} />
            <Route exact path={'invoice/:id'} element={<Invoice />} />
            <Route exact path={'category'} element={<Category />} />
            <Route exact path={'modifiergroup'} element={<ModifierGroup />} />
            <Route exact path={'modifieritem'} element={<ModifierItem />} />
            <Route exact path={'producttype'} element={<ModifierType />} />
            <Route exact path={'voucher'} element={<Voucher />} />
            <Route exact path={'promocode'} element={<Promocode />} />
            <Route exact path={'promotions'} element={<Promotions />} />
            <Route exact path={'foodmenu'} element={<FoodMenu />} />
            <Route exact path={'homebanner'} element={<HomeBanner />} />

          </Route>

          <Route element={<Auth allowedRoles={["admin", "driver"]} />}>
            <Route exact path={'drivers'} element={<Drivers />} />
          </Route>

          <Route element={<Auth allowedRoles={["admin", "restaurant"]} />}>
            <Route exact path={'localization-settings'} element={<Settings />} />
          </Route>

          <Route element={<Auth allowedRoles={["admin", "restaurant"]} />}>
            <Route exact path={'userlist'} element={<User />} />
          </Route>

          <Route element={<Auth allowedRoles={["admin"]} />}>
            <Route exact path={'role-settings'} element={<RoleSettings />} />
          </Route>

          <Route element={<Auth allowedRoles={["admin"]} />}>
            <Route exact path={'emailtemplate'} element={<EmailMarketing />} />
            <Route exact path={'campaign'} element={<CampaignList />} />
          </Route>

          <Route element={<Auth allowedRoles={["admin"]} />}>
            <Route exact path={'deliverycharges'} element={<DeliveryCharges />} />
            <Route exact path={'rest-api'} element={<ImportExport />} />
            <Route exact path={'timeschedule'} element={<TimeConfig />} />
            <Route exact path={'preorderconfig'} element={<PreOrder />} />
            <Route exact path={'apps'} element={<Mailchimp />} />
            <Route exact path={'mailchimp/connect'} element={<Mailchimpafterconnect />} />
            <Route exact path={'mailchimp/redirect'} element={<MailchimpConnected />} />
            <Route exact path={'mailchimp/customers'} element={<MailchimpPushCust />} />
          </Route>
        </Route>

        <Route path='404' element={<Error404 />} />
        <Route path="*" element={<Error404 />} />

      </Routes>
      <ScrollToTop />
      <AuthVerify logOut={logOut} />

    </>
  );
};


function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  const { loading } = useSelector((state) => state.commonData)
  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav />
      <Setting />
      <div className="content-body" style={{ minHeight: window.innerHeight - 45 }}>
        <div className="container">
          <Outlet />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <img src="/images/burger.gif" alt="" style={{ width: '15%' }} />
          </Backdrop>
        </div>
      </div>
      {/* <div className="content-body d-flex justify-content-center align-items-center" style={{ minHeight: window.innerHeight - 45 }}>
          <img src="/images/burger.gif" alt="" style={{ width: '15%' }} />
        </div> */}

      <Footer />
    </div>
  )

};

function LogoutLayout() {
  return (
    <div className="vh-100">
      <Outlet />
    </div >
  )

};

export default Markup;
