import { Form, Formik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableSearch from '../../../helpers/TableSearch';
import { COLUMNSFORHOMEBANNER } from './HomeBannerColumns';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import swal from 'sweetalert';
import TablePaginationHelper from '../../../helpers/Pagination';
import { Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { S3ImageUpload, symbolsArr } from '../../../constants/constants';
import ViewHomeBannerModal from './ViewHomeBannerModal';
import HomeBannerAddEditModal from './HomeBannerAddEditModal';
import { DeleteBannersApi, UpdateBannerSettingsApi, getBannerListApi, getBannerSettingsApi } from '../../../services/BannerServices';

const HomeBanner = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [bannerList, setBannerList] = useState([]);
    const [bannerSettings, setBannerSettings] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const { auth } = useSelector((state) => state.auth);
    const permissions = JSON.parse(auth.role_permissions)
    let userDetails = JSON.parse(localStorage.getItem('userDetails'))
    let restaurantId = userDetails.restaurant_id


    useEffect(() => {
        dispatch(getBannerListApi((res) => {
            setBannerList(res.data)
        }))
    }, [openModal])

    useEffect(() => {
        dispatch(getBannerSettingsApi((res) => {
            setBannerSettings(res.data)
        }))
    }, [])

    const columns = COLUMNSFORHOMEBANNER
    const data = bannerList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }

    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }


    const handleDelete = (row) => {
        const ID = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(DeleteBannersApi({ ID }, (res) => {
                    swal("Banner has been deleted!", {
                        icon: "success",
                    });
                    dispatch(getBannerListApi((res) => {
                        setBannerList(res.data)
                    }))
                }))
            }
        })
    }


    const onsubmit = (values) => {
        values.ID = bannerSettings.ID
        dispatch(UpdateBannerSettingsApi(values, (res) => {
        }))
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            TransitionTime: bannerSettings && bannerSettings.TransitionTime ? bannerSettings.TransitionTime : 0,
                            TransitionType: bannerSettings && bannerSettings.TransitionType ? bannerSettings.TransitionType : '',
                            TransitionAutoPlay: bannerSettings && bannerSettings.TransitionAutoPlay == '1' ? true : false,
                        }}
                        onSubmit={onsubmit}
                    >
                        {(props) => {
                            const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                            return (
                                <Form onSubmit={handleSubmit} noValidate>
                                    <div className="row">
                                        <div className='col-4 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.TransitionTime) && touched.TransitionTime}
                                                helperText={(Boolean(errors.TransitionTime) && touched.TransitionTime) ? errors.TransitionTime : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="TransitionTime"
                                                label={t('homebannerScreen.transitiontime')}
                                                value={values.TransitionTime}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-4 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="demo-simple-select"
                                                    value={values.TransitionType}
                                                    label={t('homebannerScreen.transitioneffect')}
                                                    name='TransitionType'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.TransitionType) && touched.TransitionType}
                                                    helperText={Boolean(errors.TransitionType) && touched.TransitionType ? errors.TransitionType : ""}
                                                >
                                                    <MenuItem value=''>{t('homebannerScreen.selecttransitioneffect')}</MenuItem>
                                                    <MenuItem value='carousel-fade'>{t('homebannerScreen.fadetransition')}</MenuItem>
                                                    <MenuItem value='slide'>{t('homebannerScreen.slidetransition')}</MenuItem>

                                                </TextField>

                                            </FormControl>
                                        </div>
                                        <div className='col-4 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.TransitionAutoPlay} />}
                                                label={t('homebannerScreen.transitionautoplay')}
                                                name="TransitionAutoPlay"
                                                labelPlacement="top"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mt-3 d-flex float-right align-items-center justify-content-end">
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                                size="large"
                                                disabled={bannerList && bannerList.length <= 0}
                                            >
                                                {t('buttons.update')}
                                            </button>
                                        </div>
                                        {bannerList && bannerList.length <= 0 && <div className="mt-3 d-flex float-right justify-content-end">
                                            <small className='text-warning'>
                                                Atleast one banner required for update transition settings
                                            </small>
                                        </div>}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('homebannerScreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {((permissions && permissions.banner && permissions.banner.add) || permissions == null || !permissions.banner) && <Button onClick={handleAddOpen}>{t('buttons.addbanner')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <tr key={index} {...row.getRowProps()}>
                                                <td>
                                                    {row?.original?.BannerImage.slice(row?.original?.BannerImage.lastIndexOf('.') + 1) == 'mp4'
                                                        ?
                                                        <video width="100" autoPlay loop="loop" muted>
                                                            <source src={`${S3ImageUpload}${restaurantId}/homebanner/${row?.original?.BannerImage}`} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        :
                                                        <img src={`${S3ImageUpload}${restaurantId}/homebanner/${row?.original?.BannerImage}`} alt="" className='w-25' />}
                                                </td>
                                                <td > {row.original.IsActive == '1' ? 'Active' : 'Inactive'} </td>
                                                <td className='text-end'>
                                                    {((permissions && permissions.banner && permissions.banner.view) || permissions == null || !permissions.banner) && <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>}
                                                    {((permissions && permissions.banner && permissions.banner.edit) || permissions == null || !permissions.banner) && <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>}
                                                    {((permissions && permissions.banner && permissions.banner.delete) || permissions == null || !permissions.banner) && <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div >
            {openModal && <HomeBannerAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} restaurantId={restaurantId}/>}
            {openViewModal && <ViewHomeBannerModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} restaurantId={restaurantId} />}
        </>
    )
}

export default HomeBanner
