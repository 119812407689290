import { Autocomplete, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Select, TimePicker } from 'antd';
import { createStyles, makeStyles } from '@mui/styles';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditPromotionApi, getPromotionItemListApi } from '../../../../services/promotionServices';
import { symbolsArr } from '../../../../constants/constants';
import moment from 'moment';

const { Option } = Select;



const PromotionsAddEditModal = (props) => {


    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData, categoryList, ItemList, setItemList } = props;
    const [flag, setFlag] = useState(false)
    const currencySymbol = localStorage.getItem('currencySymbol')

    useEffect(() => {

    }, [flag])

    const onsubmit = (values) => {
        if (values.FromDate == '') {
            values.FromDate = null
        }
        if (values.FromTime == '') {
            values.Pro_FromTime = null
        } else {
            values.Pro_FromTime = dayjs(values.FromTime).format('HH:mm')
        }
        if (values.ToDate == '') {
            values.ToDate = null
        }
        if (values.ToTime == '') {
            values.Pro_ToTime = null
        } else {
            values.Pro_ToTime = dayjs(values.ToTime).format('HH:mm')
        }
        if (editData && editData.ID) {
            values.PromotionId = editData.ID
        }
        let arr = [];
        if (values.Sun) {
            arr.push(0);
        }
        if (values.Mon) {
            arr.push(1);
        }
        if (values.Tue) {
            arr.push(2);
        }
        if (values.Wed) {
            arr.push(3);
        }
        if (values.Thu) {
            arr.push(4);
        }
        if (values.Fri) {
            arr.push(5);
        }
        if (values.Sat) {
            arr.push(6);
        }
        values.Pro_On_Days = arr.join(',')
        console.log(values)

        dispatch(addEditPromotionApi(values, (res) => {
            handleModalClose()
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()

    }

    const handleChangeType = (selectedCatagotyList, selectedItemList, cb) => {
        setFlag(true)
        let CategoryIds = selectedCatagotyList;
        if (selectedCatagotyList.length > 0) {
            dispatch(getPromotionItemListApi({ CategoryIds }, (res) => {
                if (res.data.length > 0) {
                    res.data.map((item, idx) => {
                        let filterRecord = selectedItemList.filter((dataItem) => dataItem != item.ID);
                        if (filterRecord.length > 0) {
                            const index = selectedItemList.indexOf(item.ID);
                            selectedItemList = selectedItemList.splice(index, 1);
                        }
                    })
                    setItemList(res.data)
                    if (cb) cb(selectedItemList)
                } else {
                    setItemList([])
                    if (cb) cb([])
                }
            }))
        } else {
            setItemList([])
            if (cb) cb([])
        }
        setFlag(false)
    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >
            <Formik
                initialValues={{
                    Pro_Name: editData && editData.Pro_Name ? editData.Pro_Name : '',
                    Pro_Desc: editData && editData.Pro_Desc ? editData.Pro_Desc : '',
                    FromDate: editData && editData.Pro_FromDate ? editData.Pro_FromDate : '',
                    ToDate: editData && editData.Pro_ToDate ? editData.Pro_ToDate : '',
                    FromTime: editData && editData.Pro_FromTime ? editData.Pro_FromTime : '',
                    ToTime: editData && editData.Pro_ToTime ? editData.Pro_ToTime : '',
                    IsActive: editData ? (editData.IsActive == '1' ? true : false) : true,
                    Mon: editData && editData.Pro_On_Days.length > 0 && editData.Pro_On_Days.includes("1") ? true : false,
                    Tue: editData && editData.Pro_On_Days.length > 0 && editData.Pro_On_Days.includes("2") ? true : false,
                    Wed: editData && editData.Pro_On_Days.length > 0 && editData.Pro_On_Days.includes("3") ? true : false,
                    Thu: editData && editData.Pro_On_Days.length > 0 && editData.Pro_On_Days.includes("4") ? true : false,
                    Fri: editData && editData.Pro_On_Days.length > 0 && editData.Pro_On_Days.includes("5") ? true : false,
                    Sat: editData && editData.Pro_On_Days.length > 0 && editData.Pro_On_Days.includes("6") ? true : false,
                    Sun: editData && editData.Pro_On_Days.length > 0 && editData.Pro_On_Days.includes("0") ? true : false,
                    Pro_Type: editData && editData.Pro_Type ? editData.Pro_Type : 'Basic',
                    Pro_On_Cart: editData && editData.Pro_On_Cart ? editData.Pro_On_Cart : 'Entire',
                    Pro_On_Type: editData && editData.Pro_On_Type ? editData.Pro_On_Type : 'Amount',
                    Pro_Get_Amount: editData && editData.Pro_Get_Amount ? editData.Pro_Get_Amount : "",
                    Pro_Get_Percent: editData && editData.Pro_Get_Percent ? editData.Pro_Get_Percent : "",
                    Pro_When: editData && editData.Pro_When ? editData.Pro_When : "",
                    Pro_On_Amount: editData && editData.Pro_On_Amount ? editData.Pro_On_Amount : "",
                    Pro_On_Qty: editData && editData.Pro_On_Qty ? editData.Pro_On_Qty : 0,
                    Pro_They_Will: editData && editData.Pro_They_Will ? editData.Pro_They_Will : "",
                    Get_Product: editData && (editData.Pro_On_Categories != null || editData.Pro_On_Items != null) ? "getspecific" : "getall",
                    Pro_On_Categories: editData && editData.Pro_On_Categories != null ? editData.Pro_On_Categories.split(',') : [],
                    Pro_On_Items: editData && editData.Pro_On_Items != null ? editData.Pro_On_Items.split(',') : [],


                }}

                validationSchema={
                    Yup.object().shape({
                        Pro_Name: Yup.string().required("Promotion Title is required."),
                        Pro_Desc: Yup.string().required("Promotion Description is required."),
                        Pro_Type: Yup.string().required("Promotion Type is required."),
                        Pro_Get_Amount: Yup.number()
                            .transform((value) => Number.isNaN(value) ? 0 : value)
                            .nullable()
                            .when(['Pro_On_Type', 'Pro_Type', 'Pro_They_Will'], {
                                is: (Pro_On_Type, Pro_Type, Pro_They_Will) => Pro_On_Type == 'Amount' && (Pro_Type == 'Basic' || Pro_They_Will == 'SaveAmount' || Pro_They_Will == 'GetDelivery'),
                                then: Yup.number()
                                    .required('Please Enter Amount'),
                                otherwise: Yup.number(),
                            }),
                        Pro_Get_Percent: Yup.number()
                            .transform((value) => Number.isNaN(value) ? 0 : value)
                            .nullable()
                            .when(['Pro_On_Type', 'Pro_Type', 'Pro_They_Will'], {
                                is: (Pro_On_Type, Pro_Type, Pro_They_Will) => Pro_On_Type == 'Percent' && (Pro_Type == 'Basic' || Pro_They_Will == 'SaveAmount' || Pro_They_Will == 'GetDelivery'),
                                then: Yup.number()
                                    .required('Please Enter Percenet'),
                                otherwise: Yup.number(),
                            }),
                        Pro_When: Yup.string()
                            .transform((value) => value == null ? '' : value)
                            .nullable()
                            .when('Pro_Type', {
                                is: (Pro_Type) => Pro_Type == 'Advanced',
                                then: Yup.string()
                                    .required('Please choose the condition'),
                                otherwise: Yup.string(),
                            }),
                        Pro_They_Will: Yup.string()
                            .transform((value) => value == null ? '' : value)
                            .nullable()
                            .when(['Pro_Type', 'Pro_When'], {
                                is: (Pro_Type, Pro_When) => Pro_Type == 'Advanced' && Pro_When != '',
                                then: Yup.string()
                                    .required('Please choose the reward'),
                                otherwise: Yup.string(),
                            }),
                        Pro_On_Qty: Yup.number()
                            .transform((value) => Number.isNaN(value) ? 0 : value)
                            .nullable()
                            .when('Pro_When', {
                                is: (Pro_When) => Pro_When == 'Buys',
                                then: Yup.number()
                                    .required('Please enter value'),
                                otherwise: Yup.number(),
                            }),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, setFieldValue, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('promotionscreen.updatepromotion') : t('promotionscreen.addpromotion')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row promotion">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.Pro_Name) && touched.Pro_Name}
                                                helperText={(Boolean(errors.Pro_Name) && touched.Pro_Name) ? errors.Pro_Name : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Pro_Name"
                                                label={t('promotionscreen.promotiontitle')}
                                                value={values.Pro_Name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.Pro_Desc) && touched.Pro_Desc}
                                                helperText={(Boolean(errors.Pro_Desc) && touched.Pro_Desc) ? errors.Pro_Desc : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Pro_Desc"
                                                label={t('promotionscreen.promotiondescription')}
                                                value={values.Pro_Desc}
                                                onChange={handleChange}
                                            />
                                        </div>


                                        <div className='col-lg-6 pt-3'>
                                            <FormControl size="small" variant="outlined" fullWidth>
                                                <TextField
                                                    onKeyDown={() => { "return false" }}
                                                    fullWidth
                                                    inputProps={{ max: values.ToDate }}
                                                    type="date"
                                                    name='FromDate'
                                                    variant="outlined"
                                                    label={t('promotionscreen.fromdate')}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleChange}
                                                    value={values.FromDate}
                                                    error={Boolean(errors.FromDate) && touched.FromDate}
                                                    helperText={(Boolean(errors.FromDate) && touched.FromDate) ? errors.FromDate : ''}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-lg-6 pt-3'>
                                            <FormControl size="small" variant="outlined" fullWidth>
                                                <TextField
                                                    onKeyDown={() => { "return false" }}
                                                    fullWidth
                                                    type="date"
                                                    inputProps={{ min: values.FromDate ? new Date(values.FromDate).toISOString().slice(0, 10) : "" }}
                                                    name='ToDate'
                                                    variant="outlined"
                                                    label={t('promotionscreen.todate')}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleChange}
                                                    value={values.ToDate}
                                                    error={Boolean(errors.ToDate) && touched.ToDate}
                                                    helperText={(Boolean(errors.ToDate) && touched.ToDate) ? errors.ToDate : ''}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-lg-6 pt-3'>
                                            <TimePicker
                                                style={{ width: "100%" }}
                                                name={`FromTime`}
                                                size={"large"}
                                                format={'HH:mm'}
                                                allowClear={false}
                                                showNow={false}
                                                value={values.FromTime ? dayjs(values.FromTime, 'HH:mm') : dayjs('00:00', 'HH:mm')}
                                                onChange={(time, timeString) => {
                                                    setFieldValue(`FromTime`, dayjs(timeString, 'HH:mm'));
                                                }}
                                            />
                                        </div>
                                        <div className='col-lg-6 pt-3'>
                                            <TimePicker
                                                style={{ width: "100%" }}
                                                name={`ToTime`}
                                                size={"large"}
                                                format={'HH:mm'}
                                                allowClear={false}
                                                showNow={false}
                                                value={values.ToTime ? dayjs(values.ToTime, 'HH:mm') : dayjs('00:00', 'HH:mm')}
                                                onChange={(time, timeString) => {
                                                    setFieldValue(`ToTime`, dayjs(timeString, 'HH:mm'));
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 pt-4">
                                            <p className='fs-16 mb-0'>{t('promotionscreen.selectdays')}</p>
                                            <FormGroup className='d-flex flex-row'>
                                                <FormControlLabel control={<Checkbox
                                                    checked={values.Mon}
                                                    name='Mon'
                                                    onChange={handleChange} />} label={t('promotionscreen.mon')} />
                                                <FormControlLabel control={<Checkbox
                                                    checked={values.Tue}
                                                    name='Tue'
                                                    onChange={handleChange} />} label={t('promotionscreen.tue')} />
                                                <FormControlLabel control={<Checkbox
                                                    checked={values.Wed}
                                                    name='Wed'
                                                    onChange={handleChange} />} label={t('promotionscreen.wed')} />
                                                <FormControlLabel control={<Checkbox
                                                    checked={values.Thu}
                                                    name='Thu'
                                                    onChange={handleChange} />} label={t('promotionscreen.thu')} />
                                                <FormControlLabel control={<Checkbox
                                                    checked={values.Fri}
                                                    name='Fri'
                                                    onChange={handleChange} />} label={t('promotionscreen.fri')} />
                                                <FormControlLabel control={<Checkbox
                                                    checked={values.Sat}
                                                    name='Sat'
                                                    onChange={handleChange} />} label={t('promotionscreen.sat')} />
                                                <FormControlLabel control={<Checkbox
                                                    checked={values.Sun}
                                                    name='Sun'
                                                    onChange={handleChange} />} label={t('promotionscreen.sun')} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-12 pt-3">
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="demo-simple-select"
                                                    name='Pro_Type'
                                                    value={values.Pro_Type}
                                                    label={t('promotionscreen.promotiontype')}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        values.Pro_On_Cart = editData ? editData.Pro_On_Cart : 'Entire';
                                                        values.Pro_On_Type = editData ? editData.Pro_On_Type : 'Amount';
                                                        values.Pro_Get_Amount = editData ? editData.Pro_Get_Amount : '';
                                                        values.Pro_Get_Percent = editData ? editData.Pro_Get_Percent : '';
                                                        values.Pro_When = editData ? editData.Pro_When : '';
                                                        values.Pro_On_Amount = editData ? editData.Pro_On_Amount : '';
                                                        values.Pro_On_Qty = editData ? editData.Pro_On_Qty : 0;
                                                        values.Pro_They_Will = editData ? editData.Pro_They_Will : '';
                                                        values.Get_Product = editData && (editData.Pro_On_Categories != [] || editData.Pro_On_Items != []) ? "getspecific" : "getall";
                                                        values.Pro_On_Categories = editData ? editData.Pro_On_Categories.split(',') : [];
                                                        values.Pro_On_Items = editData ? editData.Pro_On_Items.split(',') : [];

                                                    }}
                                                >
                                                    <MenuItem value='' disabled>{t('promotionscreen.selectpromotiontype')}</MenuItem>
                                                    <MenuItem value={'Basic'}>{t('promotionscreen.basic')}</MenuItem>
                                                    <MenuItem value={'Advanced'}>{t('promotionscreen.advanced')}</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        </div>
                                        {values.Pro_Type == "Advanced" && <div className="col-12 pt-3">
                                            <div className="row">
                                                <div className="col-lg-4 p-3 fs-16">
                                                    {t('promotionscreen.whenacustomer')}:
                                                </div>
                                                <div className="col-lg-6">
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            error={Boolean(errors.Pro_When) && touched.Pro_When}
                                                            id="demo-simple-select"
                                                            name='Pro_When'
                                                            value={values.Pro_When}
                                                            label={t('promotionscreen.choosecondition')}
                                                            onChange={(e) => { handleChange(e); values.Pro_On_Qty = 0; values.Pro_On_Amount = '' }}
                                                        >
                                                            <MenuItem value='' disabled>{t('promotionscreen.choosecondition')}</MenuItem>
                                                            <MenuItem value={'Buys'}>{t('promotionscreen.buythefollowingitems')}</MenuItem>
                                                            <MenuItem value={'Spends'} disabled={values.Pro_They_Will == 'PayFix'}>{t('promotionscreen.spendthefollowingamount')}</MenuItem>
                                                        </TextField>
                                                        <FormHelperText className="Mui-error">{Boolean(errors.Pro_When) || touched.Pro_When ? errors.Pro_When : ""}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>}
                                        {values.Pro_Type == "Advanced" && values.Pro_When != '' && <div className="col-12 pt-3">
                                            <div className="row">
                                                <div className="col-lg-4 p-3  fs-16">
                                                    {values.Pro_When == "Buys" ? t('promotionscreen.buy') : t('promotionscreen.spend')}:
                                                </div>
                                                <div className="col-lg-6">
                                                    {values.Pro_When == "Buys" && <p className='fs-16 mb-0' style={{ marginLeft: '10px' }}>{t('promotionscreen.quantity')}</p>}
                                                    <TextField
                                                        className='mt-0'
                                                        type='number'
                                                        error={values.Pro_When == 'Buys' && Boolean(errors.Pro_On_Qty) && touched.Pro_On_Qty}
                                                        helperText={values.Pro_When == 'Buys' && ((Boolean(errors.Pro_On_Qty) && touched.Pro_On_Qty) ? errors.Pro_On_Qty : '')}
                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                        value={values.Pro_When == 'Buys' ? values.Pro_On_Qty : values.Pro_On_Amount}
                                                        name={values.Pro_When == 'Buys' ? 'Pro_On_Qty' : "Pro_On_Amount"}
                                                        onChange={handleChange}
                                                        sx={{ m: 1, width: "25ch" }}
                                                        InputProps={
                                                            values.Pro_When == "Spends" ? {
                                                                inputProps: { min: 0 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                                ),
                                                            } : { inputProps: { min: 0 } }}
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </div>
                                        </div>}

                                        {/* all/specific */}
                                        {(values.Pro_Type == "Basic" || values.Pro_When != '') && <div className="col-12 pt-3">
                                            <p className="fs-16 mb-0">{t('promotionscreen.product')}</p>
                                            <RadioGroup value={values.Get_Product} className='productRadio' name='Get_Product' onChange={handleChange} row >
                                                <FormControlLabel
                                                    value="getall"
                                                    control={<Radio color="primary" />}
                                                    label={t('promotionscreen.all')}
                                                    labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    className='border-start-0'
                                                    value="getspecific"
                                                    control={<Radio color="primary" />}
                                                    label={t('promotionscreen.specific')}
                                                    labelPlacement="top"
                                                />
                                            </RadioGroup>
                                        </div>}
                                        {(values.Get_Product == "getspecific") && <div className="col-6 pt-3">
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Tags Mode"
                                                onChange={(value) => {
                                                    setFieldValue("Pro_On_Categories", value); handleChangeType(value, values.Pro_On_Items, (ress) => {
                                                        if (ress.length <= 0) {
                                                            setFieldValue("Pro_On_Items", []);
                                                        } else {
                                                            setFieldValue("Pro_On_Items", ress);
                                                        }
                                                    })
                                                }}
                                                value={categoryList && categoryList.length > 0 ? values.Pro_On_Categories : []}
                                                optionLabelProp="label"
                                                name='Pro_On_Categories'

                                            >
                                                {categoryList && categoryList.length > 0 && categoryList.map((item, index) => {
                                                    return <Option key={index} value={item.ID} label={item.CategoryName}>
                                                        {item.CategoryName}
                                                    </Option>
                                                })}

                                            </Select>
                                            {/* <Autocomplete
                                                multiple={true}
                                                options={categoryList || []}
                                                getOptionLabel={(option) => option?.CategoryName || ''}
                                                filterSelectedOptions
                                                value={values.Pro_On_Categories || []}
                                                onChange={(e, value) => {
                                                    setFieldValue("Pro_On_Categories", value); handleChangeType(value, values.Pro_On_Items, (ress) => {
                                                        if (ress.length <= 0) {
                                                            setFieldValue("Pro_On_Items", []);
                                                        } else {
                                                            setFieldValue("Pro_On_Items", ress);
                                                        }
                                                    });
                                                }}
                                                name='Pro_On_Categories'
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('promotionscreen.category')}
                                                    />
                                                )}
                                            /> */}
                                        </div>}
                                        {(values.Get_Product == "getspecific") && <div className="col-6 pt-3">
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Tags Mode"
                                                onChange={(value) => setFieldValue("Pro_On_Items", value)}
                                                value={categoryList && categoryList.length > 0 && values.Pro_On_Items != '' ? values.Pro_On_Items : []}
                                                optionLabelProp="label"
                                                name='Pro_On_Items'

                                            >
                                                {ItemList && ItemList.length > 0 && ItemList.map((item, index) => {
                                                    return <Option key={index} value={item.ID} label={item.ItemName}>
                                                        {item.ItemName}
                                                    </Option>
                                                })}

                                            </Select>
                                            {/* <Autocomplete
                                                multiple={true}
                                                id="tags-outlined"
                                                options={ItemList || []}
                                                getOptionLabel={(option) => option?.ItemName || ''}
                                                filterSelectedOptions
                                                value={values.Pro_On_Items || []}
                                                onChange={(e, value) => { setFieldValue("Pro_On_Items", value); }}
                                                name='Pro_On_Items'
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('promotionscreen.item')}
                                                    />
                                                )}
                                            /> */}
                                        </div>}
                                        {values.Pro_Type == "Advanced" && values.Pro_When != '' && <div className="col-12 pt-4">
                                            <div className="row">
                                                <div className="col-lg-4 p-3  fs-16">
                                                    {t('promotionscreen.thentheywill')}:
                                                </div>
                                                <div className="col-lg-6">
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            error={Boolean(errors.Pro_They_Will) && touched.Pro_They_Will}
                                                            id="demo-simple-select"
                                                            name='Pro_They_Will'
                                                            value={values.Pro_They_Will}
                                                            label={t('promotionscreen.choosecondition')}
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value='' disabled>{t('promotionscreen.choosecondition')}</MenuItem>
                                                            <MenuItem value={'SaveAmount'}>{t('promotionscreen.saveacertainamount')}</MenuItem>
                                                            <MenuItem value={'GetDelivery'}>{t('promotionscreen.getthedelivery')}</MenuItem>
                                                            <MenuItem value={'PayFix'} disabled={values.Pro_When == 'Spends'}>{t('promotionscreen.payafixedprice')}</MenuItem>
                                                            <MenuItem value={'getitems'} disabled>{t('promotionscreen.getthefollowingitems')}</MenuItem>
                                                        </TextField>
                                                        <FormHelperText className="Mui-error">{Boolean(errors.Pro_They_Will) || touched.Pro_They_Will ? errors.Pro_They_Will : ""}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>}
                                        {(values.Pro_Type == "Basic" || values.Pro_They_Will == "SaveAmount" || values.Pro_They_Will == 'GetDelivery') && <div className="col-4 pt-3">
                                            <p className="fs-16 mb-0">{t('promotionscreen.get')}</p>
                                            <RadioGroup value={values.Pro_On_Type} className='productRadio' name='Pro_On_Type'
                                                onChange={(e) => { handleChange(e); values.Pro_Get_Amount = ''; values.Pro_Get_Percent = '' }}
                                                row >
                                                <FormControlLabel
                                                    value="Amount"
                                                    control={<Radio color="primary" />}
                                                    label={currencySymbol}
                                                    labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    className='border-start-0'
                                                    value="Percent"
                                                    control={<Radio color="primary" />}
                                                    label="%"
                                                    labelPlacement="top"
                                                />
                                            </RadioGroup>
                                        </div>}
                                        {(values.Pro_Type == "Basic" || values.Pro_They_Will == 'SaveAmount' || values.Pro_They_Will == 'GetDelivery') && <div className="col-6 pt-3">
                                            <TextField
                                                type='number'
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={values.Pro_On_Type == 'Amount' ? Boolean(errors.Pro_Get_Amount) && touched.Pro_Get_Amount : Boolean(errors.Pro_Get_Percent) && touched.Pro_Get_Percent}
                                                helperText={values.Pro_On_Type == 'Amount' ? ((Boolean(errors.Pro_Get_Amount) && touched.Pro_Get_Amount) ? errors.Pro_Get_Amount : '') : ((Boolean(errors.Pro_Get_Percent) && touched.Pro_Get_Percent) ? errors.Pro_Get_Percent : '')}
                                                value={values.Pro_On_Type == 'Amount' ? values.Pro_Get_Amount : values.Pro_Get_Percent}
                                                name={values.Pro_On_Type == 'Amount' ? 'Pro_Get_Amount' : "Pro_Get_Percent"}
                                                onChange={handleChange}
                                                sx={{ m: 1, width: "25ch" }}
                                                InputProps={values.Pro_On_Type == 'Amount' ? {
                                                    inputProps: { min: 0 },
                                                    startAdornment: (
                                                        <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                    ),
                                                } : {
                                                    inputProps: { min: 0 },
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                }}
                                                variant="outlined"
                                            />
                                        </div>}

                                        {(values.Pro_They_Will == 'PayFix') &&
                                            <div className="col-12 pt-3">
                                                <div className="row">
                                                    <div className="col-lg-4 p-3  fs-16">
                                                        {t('promotionscreen.for')}:
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <TextField
                                                            type='number'
                                                            onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                            value={values.Pro_Get_Amount}
                                                            name={'Pro_Get_Amount'}
                                                            onChange={handleChange}
                                                            sx={{ m: 1, width: "25ch" }}
                                                            InputProps={{
                                                                inputProps: { min: 0 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                                )
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        {values.Pro_Type == "Advanced" && values.Pro_They_Will != '' && <div className="col-12 pt-4">
                                            <div className="row">
                                                <div className="col-lg-4 p-3  fs-16">

                                                </div>
                                                <div className="col-lg-6">
                                                    <FormControl>
                                                        <RadioGroup
                                                            name="Pro_On_Cart"
                                                            value={values.Pro_On_Cart}
                                                            onChange={handleChange}
                                                        >
                                                            <FormControlLabel value="Entire" control={<Radio />} label={t('promotionscreen.offtheentiresale')} />
                                                            <FormControlLabel disabled={values.Get_Product != 'getspecific'} value="SpecificItem" control={<Radio />} label={t('promotionscreen.offthespecificitemsabove')} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className='col-6 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.IsActive} />}
                                                label={t('isactive')}
                                                name="IsActive"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default PromotionsAddEditModal
