import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditVoucherApi } from '../../../../services/voucherServices';
import { symbolsArr } from '../../../../constants/constants';


const AddEditVoucherModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData } = props



    const onsubmit = (values) => {
        if (editData && editData.ID) {
            values.VoucherId = editData.ID
        }
        dispatch(addEditVoucherApi(values, (res) => {
            setOpenModal(false)
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    VoucherName: editData ? editData.VoucherName : '',
                    MinimumAmount: editData ? editData.MinimumAmount : '',
                    Price: editData ? editData.Price : '',
                    Quantity: editData ? editData.Quantity : '',
                    IsActive: editData ? (editData.IsActive == '1' ? true : false) : true,
                }}

                validationSchema={
                    Yup.object().shape({
                        VoucherName: Yup.string().required("Voucher Name is required."),
                        MinimumAmount: Yup.number().required("Minimum amount is required."),
                        Price: Yup.number().required("Price is required."),
                        Quantity: Yup.number().required("Quantity is required."),
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('voucherscreen.updatevoucher') : t('voucherscreen.addvoucher')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.VoucherName) && touched.VoucherName}
                                                helperText={(Boolean(errors.VoucherName) && touched.VoucherName) ? errors.VoucherName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="VoucherName"
                                                label={t('voucherscreen.vouchername')}
                                                value={values.VoucherName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.MinimumAmount) && touched.MinimumAmount}
                                                helperText={(Boolean(errors.MinimumAmount) && touched.MinimumAmount) ? errors.MinimumAmount : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="MinimumAmount"
                                                label={t('voucherscreen.minimumamount')}
                                                value={values.MinimumAmount}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                type={'number'}
                                                required
                                                error={Boolean(errors.Price) && touched.Price}
                                                helperText={(Boolean(errors.Price) && touched.Price) ? errors.Price : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Price"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                label={t('voucherscreen.price')}
                                                value={values.Price}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.Quantity) && touched.Quantity}
                                                helperText={(Boolean(errors.Quantity) && touched.Quantity) ? errors.Quantity : ''}
                                                fullWidth
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onBlur={handleBlur}
                                                name="Quantity"
                                                label={t('voucherscreen.quantity')}
                                                value={values.Quantity}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.IsActive} />}
                                                label={t('isactive')}
                                                name="IsActive"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default AddEditVoucherModal
