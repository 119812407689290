import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOrderHistoryListApi } from '../../../../services/OrderHistoryServices';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { Empty, Typography } from 'antd';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { DatePicker } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from '@mui/material';
import { Button } from 'react-bootstrap';
import { getOrderDetailsApi, getOrderDetailsbyTrsnsactionIdApi } from '../../../../services/orderService';
const { RangePicker } = DatePicker;

export const CustomBadge = ({ item }) => {
    let color = 'primary';
    switch (item.OrderStatusId) {
        case '100':
            color = 'info'
            break;
        case '125':
            color = 'danger'
            break;
        case '150':
            color = 'success'
            break;
        case '175':
            color = 'warning'
            break;
        case '200':
            color = 'danger'
            break;
        case '300':
            color = 'success'
            break;
        case '350':
            color = 'success'
            break;
        case '400':
            color = 'default'
            break;
        case '500':
            color = 'warning'
            break;
        case '600':
            color = 'dark'
            break;
        case '700':
            color = 'dark'
            break;
        default:
            color = 'primary'
    }

    return <span className={`badge badge-${color} text-white badge-sm`}>
        {item.CurrentOrderStatus}
    </span>
}

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const OrderHistory = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation('common')
    const [data, setData] = useState([])
    const [searchId, setSearchId] = useState('')
    const [orderTotalSummary, setOrderTotalSummary] = useState({})
    const [orderTotal, setOrderTotal] = useState(0)
    const [tempOrderTotal, setTempOrderTotal] = useState(0)
    const [page, setPage] = useState(2)
    const [serviceCheck, setServiceCheck] = useState(false)
    const [deliveryCheck, setDeliveryCheck] = useState(false)
    const [lastPage, setLastPage] = useState()
    const [expanded, setExpanded] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterCount, setFilterCount] = useState(0);
    const [filterObj, setFilterObj] = useState({
        OrderStatus: [],
        PaymentType: [],
        OrderType: [],
        StartDate: null,
        EndDate: null,
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        let page_no = 1
        dispatch(getOrderHistoryListApi({ page_no }, (res) => {
            if (res && res.data) {
                setLastPage(res.data.total_page);
                if (res.data.OrderTotalSummary) {
                    setOrderTotalSummary(res.data.OrderTotalSummary)
                    setOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                    setTempOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                }
                if (res.data.orders) {
                    setData(res.data.orders);
                }
            }
        }))
    }, [])

    const fetchdata = () => {
        filterObj.page_no = page
        dispatch(getOrderHistoryListApi(filterObj, (res) => {
            if (res && res.data) {
                setPage(page + 1);
                setLastPage(res.data.total_page);
                if (res.data.OrderTotalSummary) {
                    setOrderTotalSummary(res.data.OrderTotalSummary)
                    setOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                    setTempOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                }
                if (res.data.orders) {
                    setData(data => [...data, ...res.data.orders]);
                }
            }
        }))
    }


    const handleChangeCheck = (name) => {
        if (name == 'service') {
            if (!serviceCheck && orderTotalSummary) {
                let total = parseFloat(orderTotal) + parseFloat(orderTotalSummary.ServiceChargeTotal)
                setOrderTotal(total)
            } else {
                if (deliveryCheck) {
                    let total = parseFloat(orderTotal) - parseFloat(orderTotalSummary.ServiceChargeTotal)
                    setOrderTotal(total)
                } else {
                    setOrderTotal(tempOrderTotal)
                }
            }
            setServiceCheck(!serviceCheck)
        }
        if (name == 'delivery') {
            if (!deliveryCheck && orderTotalSummary) {
                let total = parseFloat(orderTotal) + parseFloat(orderTotalSummary.DeliveryChargeTotal)
                setOrderTotal(total)
            } else {
                if (serviceCheck) {
                    let total = parseFloat(orderTotal) - parseFloat(orderTotalSummary.DeliveryChargeTotal)
                    setOrderTotal(total)
                } else {
                    setOrderTotal(tempOrderTotal)
                }
            }
            setDeliveryCheck(!deliveryCheck)
        }
    }

    // const handleDateChange = (dates) => {
    //     const [start, end] = dates;
    //     setFilterObj({
    //         StartDate: start,
    //         EndDate: end,
    //     })
    //     setPage(1);
    //     fetchdata()
    // }

    const handleRedirectInvoice = (id) => {
        let encodedId = btoa(id)
        navigate(`/invoice/${encodedId}`)
    }

    const handleRedirectChange = () => {
        const obj = { "TransactionId": searchId, "UserId": "0" }
        dispatch(getOrderDetailsbyTrsnsactionIdApi(obj, (res) => {
            if (res.status == 1) {
                let encodedId = btoa(res.data.OrderId)
                navigate(`/invoice/${encodedId}`)
            }
        }))

    }

    const handleFilterCheck = (e, name, subType) => {
        const isChecked = e.target.checked;
        setPage(1);
        setFilterObj(prevState => {
            const updatedFilterObj = { ...prevState };
            if (isChecked) {
                setFilterCount(filterCount + 1)
                updatedFilterObj[name] = [...updatedFilterObj[name], subType];
            } else {
                setFilterCount(filterCount - 1)
                updatedFilterObj[name] = updatedFilterObj[name].filter(item => item !== subType);
            }

            return updatedFilterObj;
        });
    };


    const handleFilterSubmit = () => {
        setData([])
        setServiceCheck(false)
        setDeliveryCheck(false)
        filterObj.page_no = 1
        dispatch(getOrderHistoryListApi(filterObj, (res) => {
            if (res && res.data) {
                setPage(page + 1);
                setLastPage(res.data.total_page);
                if (res.data.OrderTotalSummary) {
                    setOrderTotalSummary(res.data.OrderTotalSummary)
                    setOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                    setTempOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                }
                if (res.data.orders) {
                    setData(data => [...data, ...res.data.orders]);
                }
            }
            setAnchorEl(null);
        }))
    }


    const handleDateChange = (dates, dateString) => {
        setData([])
        setServiceCheck(false)
        setDeliveryCheck(false)
        if (dates) {
            const [start, end] = dates;
            setFilterObj(prevState => {
                const updatedFilterObj = { ...prevState };
                updatedFilterObj['StartDate'] = dateString[0];
                updatedFilterObj['Start'] = start;
                updatedFilterObj['EndDate'] = dateString[1];
                updatedFilterObj['End'] = end;
                return updatedFilterObj;
            });
            let obj = filterObj;
            obj.StartDate = dateString[0];
            obj.EndDate = dateString[1];
            obj.page_no = 1
            dispatch(getOrderHistoryListApi(obj, (res) => {
                if (res && res.data) {
                    setPage(2);
                    setLastPage(res.data.total_page);
                    if (res.data.OrderTotalSummary) {
                        setOrderTotalSummary(res.data.OrderTotalSummary)
                        setOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                        setTempOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                    }
                    if (res.data.orders) {
                        setData(data => [...data, ...res.data.orders]);
                    }
                }
                setAnchorEl(null);
            }))
        } else {
            setFilterObj(prevState => {
                const updatedFilterObj = { ...prevState };
                updatedFilterObj['StartDate'] = null;
                updatedFilterObj['Start'] = null;
                updatedFilterObj['EndDate'] = null;
                updatedFilterObj['End'] = null;
                return updatedFilterObj;
            });
            let obj = filterObj
            obj.StartDate = null;
            obj.EndDate = null;
            obj.page_no = 1
            dispatch(getOrderHistoryListApi(obj, (res) => {
                if (res && res.data) {
                    setPage(2);
                    setLastPage(res.data.total_page);
                    if (res.data.OrderTotalSummary) {
                        setOrderTotalSummary(res.data.OrderTotalSummary)
                        setOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                        setTempOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                    }
                    if (res.data.orders) {
                        setData(data => [...data, ...res.data.orders]);
                    }
                }
                setAnchorEl(null);
            }))
        }
    }

    const handleClear = async () => {
        setData([])
        setServiceCheck(false)
        setDeliveryCheck(false)
        setFilterObj(prevState => {
            const updatedFilterObj = { ...prevState };
            updatedFilterObj['OrderStatus'] = [];
            updatedFilterObj['PaymentType'] = [];
            updatedFilterObj['OrderType'] = [];
            return updatedFilterObj;
        });
        setFilterCount(0)
        let obj = {
            OrderStatus: [],
            PaymentType: [],
            OrderType: [],
            StartDate: filterObj.StartDate,
            EndDate: filterObj.EndDate,
            page_no: 1
        };
        dispatch(getOrderHistoryListApi(obj, (res) => {
            if (res && res.data) {
                setPage(2);
                setLastPage(res.data.total_page);
                if (res.data.OrderTotalSummary) {
                    setOrderTotalSummary(res.data.OrderTotalSummary)
                    setOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                    setTempOrderTotal(res.data.OrderTotalSummary.Orders_Total_Without_Charges)
                }
                if (res.data.orders) {
                    setData(data => [...data, ...res.data.orders]);
                }
            }
            setAnchorEl(null);
        }))
    }

    const handleSearchId = (e) => {
        setSearchId(e.target.value)
    }

    let date;
    return (
        <>
            <div className="card" >
                <div className="card-header">
                    <h4 className="card-title">{t('orderhistory.title')}</h4>
                    <div className='d-flex'>
                        <RangePicker
                            size={'large'}
                            className='h-100'
                            format={"YYYY-MM-DD"}
                            value={[filterObj.Start, filterObj.End]}
                            onChange={handleDateChange}
                        />
                        <Button className='mx-2 h-100' type="button" size="small" onClick={handleClick}>
                            <i className="fa-solid fa-sliders"></i>&nbsp; {t('buttons.filter')} {filterCount > 0 ? '(' + filterCount + ')' : ''}
                        </Button>
                        <div className="input-group search-area2 h-100">
                            <input type="text" className="form-control p-0" value={searchId} onChange={(e) => handleSearchId(e)} placeholder="Search order #" />
                            <span className="input-group-text p-0">
                                <span onClick={() => handleRedirectChange()} to={"#"}><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z" fill="#FC8019" />
                                </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        // onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className="py-3 px-2">
                            <div className="d-flex justify-content-between">
                                <h4 className='m-0'>
                                    {t('orderhistory.filterby')}
                                </h4>
                                <Button
                                    variant=""
                                    className='btn-close p-2'
                                    onClick={handleClose}
                                >

                                </Button>
                            </div>
                            <div>
                                <p className='m-0'>{t('orderhistory.orderstatus')}</p>
                                <div className="d-flex">
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.OrderStatus.includes('cancelled')} name='cancelled' onChange={(e) => handleFilterCheck(e, 'OrderStatus', 'cancelled')} /><span>{t('orderhistory.cancelled')}</span>
                                        </label>
                                    </div>
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.OrderStatus.includes('refunded')} name='refunded' onChange={(e) => handleFilterCheck(e, 'OrderStatus', 'refunded')} /><span>{t('orderhistory.refunded')}</span>
                                        </label>
                                    </div>
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.OrderStatus.includes('completed')} name='completed' onChange={(e) => handleFilterCheck(e, 'OrderStatus', 'completed')} /><span>{t('orderhistory.completed')}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <p className='m-0'>{t('orderhistory.paymenttype')}</p>
                                <div className="d-flex">
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.PaymentType.includes('card')} name='card' onChange={(e) => handleFilterCheck(e, 'PaymentType', 'card')} /><span>{t('orderhistory.card')}</span>
                                        </label>
                                    </div>
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.PaymentType.includes('cash')} name='cash' onChange={(e) => handleFilterCheck(e, 'PaymentType', 'cash')} /><span>{t('orderhistory.cash')}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <p className='m-0'>{t('orderhistory.servicetype')}</p>
                                <div className="d-flex">
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.OrderType.includes('delivery')} name='delivery' onChange={(e) => handleFilterCheck(e, 'OrderType', 'delivery')} /><span>{t('orderhistory.delivery')}</span>
                                        </label>
                                    </div>
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.OrderType.includes('collection')} name='collection' onChange={(e) => handleFilterCheck(e, 'OrderType', 'collection')} /><span>{t('orderhistory.collection')}</span>
                                        </label>
                                    </div>
                                    <div className="cuscheck customCheck">
                                        <label>
                                            <input type="checkbox" checked={filterObj.OrderType.includes('dinein')} name='dinein' onChange={(e) => handleFilterCheck(e, 'OrderType', 'dinein')} /><span>{t('orderhistory.dinein')}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='text-end pt-4'>
                                <Button
                                    variant=""
                                    onClick={handleClear}
                                >
                                    {t('buttons.clear')}
                                </Button>
                                <button
                                    className="btn btn-primary"
                                    size="small"
                                    onClick={() => handleFilterSubmit()}
                                // disabled={!isValid || isDisabled}
                                >
                                    {t('buttons.apply')}
                                </button>
                            </div>
                        </div>
                    </Popover>
                </div>
                <div className="card-body" >
                    <div className="row">
                        {data.length > 0 ?
                            <>
                                <div className="col-lg-8">
                                    <InfiniteScroll
                                        dataLength={data.length} //This is important field to render the next data
                                        next={fetchdata}
                                        hasMore={page > lastPage ? false : true}
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                                <b>Yay! You have seen it all</b>
                                            </p>
                                        }
                                    >
                                        <div className="table-responsive" >
                                            <table className="table table-sm mb-0 table-borderless" >
                                                <tbody id="orders">
                                                    {data && data.length > 0 && data.map((item, index) => {
                                                        let addDateTr = false;
                                                        if (index == 0) {
                                                            date = moment(item.CreatedDateTime).format('YYYY-MM-DD')
                                                            addDateTr = true
                                                        } else {
                                                            if (date != moment(item.CreatedDateTime).format('YYYY-MM-DD')) {
                                                                date = moment(item.CreatedDateTime).format('YYYY-MM-DD')
                                                                addDateTr = true
                                                            } else {
                                                                addDateTr = false
                                                            }
                                                        }
                                                        return <Fragment key={index}>
                                                            {addDateTr &&
                                                                <tr className={`btn-reveal-trigger ${index == 0 ? '' : 'bordertop'}`} >
                                                                    <td colSpan={7}>
                                                                        {moment(item.CreatedDateTime).format('ddd')}&nbsp;&nbsp;<strong className='h4'>{moment(item.CreatedDateTime).format('DD MMM')}</strong>
                                                                    </td>
                                                                </tr>}
                                                            <tr className={`btn-reveal-trigger tableRow ${item.OrderStatusId && parseInt(item.OrderStatusId) >= 400 ? 'border-green' : 'border-red'}`}>
                                                                <td >
                                                                    <p className='m-0'>{moment(item.CreatedDateTime).format('HH:mm')}</p>
                                                                </td>
                                                                <td>
                                                                    <p className='m-0' onClick={() => { handleRedirectInvoice(item.OrderId) }} style={{ cursor: 'pointer' }}>
                                                                        <strong>
                                                                            #{item.TransactionId}
                                                                        </strong>
                                                                    </p>
                                                                </td>
                                                                <td >
                                                                    {item.DeliveryMode == '1' ? <p className='m-0'> <i className="fa-solid fa-sack-dollar"></i>  &nbsp; {t('orderhistory.cash')}</p> : <p className='m-0'><i className="fa-solid fa-credit-card"></i>&nbsp; {t('orderhistory.card')}</p>}
                                                                </td>
                                                                <td >
                                                                    {item.OrderType == '1' ?
                                                                        <p className='m-0'>
                                                                            <i className="fa-solid fa-motorcycle"></i>&nbsp;  {t('orderhistory.delivery')}
                                                                        </p>
                                                                        :
                                                                        <p className='m-0'>
                                                                            <i className="fa-solid fa-bag-shopping"></i> &nbsp; {t('orderhistory.collection')}
                                                                        </p>
                                                                    }
                                                                </td>
                                                                <td >
                                                                    <CustomBadge item={item} />
                                                                </td>
                                                                <td >
                                                                    <p className='m-0'>
                                                                        {t("currency", { Number: parseFloat(item.ShippingCharge ? item.ShippingCharge : 0) })} <small>{t('orderhistory.deliveryfees')}</small>
                                                                    </p>
                                                                </td>
                                                                <td >
                                                                    <p className='m-0'>
                                                                        {t("currency", { Number: parseFloat(item.OrderPrice ? item.OrderPrice : 0) })}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={7} className='p-2'>  </td>
                                                            </tr>
                                                        </Fragment>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </InfiniteScroll>
                                </div>
                                <div className="col-lg-4">
                                    {data.length > 0 && <div className="card ordersummerycard">
                                        <div className="card-body" style={{ background: '#faebd7', borderRadius: '6px' }}>
                                            <p className='m-0'>{t('orderhistory.total')}</p>
                                            <h2>
                                                {t("currency", { Number: parseFloat(orderTotal) })}
                                            </h2>
                                            <small>{t('orderhistory.includechargesfees')}</small>
                                            <div className="d-flex flex-row">
                                                <div className="cat customCheck">
                                                    <label>
                                                        <input type="checkbox" checked={serviceCheck} onChange={(e) => handleChangeCheck('service')} /><span><i className={`fa-solid ${serviceCheck ? 'fa-check' : 'fa-plus'}`}></i>&nbsp;&nbsp;&nbsp;{t('orderhistory.service')}</span>
                                                    </label>
                                                </div>
                                                <div className="cat customCheck">
                                                    <label>
                                                        <input type="checkbox" checked={deliveryCheck} onChange={(e) => handleChangeCheck('delivery')} /><span><i className={`fa-solid ${deliveryCheck ? 'fa-check' : 'fa-plus'}`}></i>&nbsp;&nbsp;&nbsp;{t('orderhistory.delivery')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <p className='m-0 pt-4'>{orderTotalSummary?.OrderCount} {t('orderhistory.orders')}</p>
                                            <p className='m-0'>{filterObj && filterObj.StartDate ? t("date_only", { date: filterObj.StartDate }) + ' - ' + t("date_only", { date: filterObj.EndDate }) : ""}</p>
                                        </div>
                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography>{expanded === 'panel1' ? 'hide details' : 'show details'}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            {t('orderhistory.avgordervalue')}
                                                            <br />
                                                            <small>(food and drinks only)</small>
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.Avg_Order_Value : 0) })}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between pt-2'>
                                                        <div>
                                                            {t('orderhistory.deliveryorders')}
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.DeliveryOrdersTotal : 0) })}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between pt-2'>
                                                        <div>
                                                            {t('orderhistory.collectionorders')}
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.CollectionOrdersTotal : 0) })}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between pt-2'>
                                                        <div>
                                                            {t('orderhistory.dineinorders')}
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.DineinOrdersTotal : 0) })}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between pt-2'>
                                                        <div>
                                                            {t('orderhistory.cashpayment')}
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.CashOrdersTotal : 0) })}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between pt-2' >
                                                        <div>
                                                            {t('orderhistory.cardpayment')}
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.CardOrdersTotal : 0) })}
                                                        </div>
                                                    </div>
                                                    {(serviceCheck || deliveryCheck) && <hr />}
                                                    {serviceCheck && <div className='d-flex justify-content-between pt-2' >
                                                        <div>
                                                            {t('ordermasterscreen.servicecharges')}
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.ServiceChargeTotal : 0) })}
                                                        </div>
                                                    </div>}
                                                    {deliveryCheck && <div className='d-flex justify-content-between pt-2' >
                                                        <div>
                                                            {t('ordermasterscreen.deliverycharges')}
                                                        </div>
                                                        <div>
                                                            {t("currency", { Number: parseFloat(orderTotalSummary ? orderTotalSummary.DeliveryChargeTotal : 0) })}
                                                        </div>
                                                    </div>}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>}
                                </div>
                            </>
                            :
                            <div>
                                <Empty />
                            </div>
                        }
                    </div>
                </div >
            </div >
        </>
    )
}
export default OrderHistory;