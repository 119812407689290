import React, { useState } from "react";
import "./index.css";

import ImageModal from "../../helpers/cropper/ImageModal";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

const ImageInput = ({
  imageWidth,
  imageHeight,
  showWidth,
  showHeight,
  name,
  onChange,
  showPreview,
  imageData,
  defaultPic,
  type
}) => {
  const { t } = useTranslation('common');
  const [image, setImage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);

  const onChangeHandler = file => {
    onChange({
      [name]: {
        data: file[0],
        src: URL.createObjectURL(file[0]),
      },
    });
  };

  const handleFile = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files;
      var url = URL.createObjectURL(file[0]);
      var img = new Image();
      img.src = url;
      img.onload = function () {
        setWidth(this.width);
        setHeight(this.height);
      };
      const maxAllowedSize = 5 * 1024 * 1024;
      if (file[0].size > maxAllowedSize) {
        console.log("max image size");
        // swal("Oops", "File size too large, max file size is 2 Mb", "error")
      } else {
        setImage(file[0]);
        setModalIsOpen(true);
      }
      e.target.value = null;
    }
  };
  let inputElement;

  return (
    <>
      <ImageModal
        modalIsOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen(prevState => !prevState);
        }}
        image={image}
        onCropImage={croppedImg => onChangeHandler([croppedImg])}
        ratio={height / width <= 0.5 ? true : false}
        type={type}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
      />
      <div>
        {showPreview && (
          <div>
            {imageData || defaultPic ?
              <img
                key={imageData}
                src={imageData ? imageData : defaultPic}
                width={showWidth ? showWidth : imageWidth}
                height={showHeight ? showHeight : imageHeight}
                alt="No Image"
              />
              : ''}
          </div>
        )}
        <div className={"upload-image"} onClick={() => inputElement.click()}>
          <button type="button" className="uploadButton">{imageData && image ? image.name : t('profile.restaurantimages.attechfile')}</button>
        </div>
        <input
          ref={input => (inputElement = input)}
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          onChange={handleFile}
        />
      </div>
    </>
  );
};

export default ImageInput;
