import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EmailEditor from "react-email-editor";
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { templateAddEdit } from '../../../../services/EmailMarketingServices';

const EmailTemplate = (props) => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const emailEditorRef = useRef(null);
    const { openModal, setOpenModal, editData, setEditData } = props


    const onsubmit = (values) => {
        if (editData) {
            values.id = editData.id
        }
        const unlayer = emailEditorRef.current?.editor;
        let obj = JSON.parse(JSON.stringify(values))

        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            console.log(design)
            obj.body = html
            obj.design = JSON.stringify(design)
        });

        
        setTimeout(() => {
            dispatch(templateAddEdit(obj, (res) => {
                handleModalClose()
            }))
        }, 500);
    };



    const onReady = (unlayer) => {
        const ref = emailEditorRef?.current
        console.log(editData)
        if (editData) {
            ref.editor.loadDesign(JSON.parse(editData.design))
        }
        ref.editor.registerCallback('image', (file, done) => {
            let fileLink = URL.createObjectURL(file.accepted[0])
            done({ progress: 100, url: fileLink })
        })
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="xl"
            fullscreen={'xl-down'}
            centered
        >
            <Modal.Header>
                <Modal.Title>{editData ? t('emailtemplate.editemailtemplate') : t('emailtemplate.addemailtemplate')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleModalClose}
                >

                </Button>
            </Modal.Header>
            <Formik
                initialValues={{
                    template_name: editData ? editData.template_name : '',
                    subject: editData && editData.subject ? editData.subject : '',
                    body: editData && editData.body ? editData.body : '',
                    is_active: editData && editData.is_active == 1 ? true : false
                }}

                validationSchema={
                    Yup.object().shape({
                        template_name: Yup.string().required("Template name is required."),
                        subject: Yup.string().required("Subject is required."),
                    })}
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, setFieldValue, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-5 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    label={t('emailtemplate.templatename')}
                                                    name='template_name'
                                                    value={values.template_name}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.template_name) && touched.template_name}
                                                    helperText={Boolean(errors.template_name) && touched.template_name ? errors.template_name : ""}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-5 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    label={t('emailtemplate.subject')}
                                                    name='subject'
                                                    value={values.subject}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.subject) && touched.subject}
                                                    helperText={Boolean(errors.subject) && touched.subject ? errors.subject : ""}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-2 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.is_active} />}
                                                label={t('emailtemplate.isactive')}
                                                name="is_active"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <div className="w-100">
                                                <EmailEditor ref={emailEditorRef}
                                                    onLoad={onReady}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Modal>

    )
}

export default EmailTemplate
