import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//import ApexCharts from 'apexcharts';
//import { Link } from "react-router-dom";

const ApexBarChartForReporting = (props) => {
    const { graphData, graphKey, color } = props
    const dispatch = useDispatch()
    const { t } = useTranslation('common')
    const [xaxisData, setXAxisData] = useState([])
    const [yaxisData, setYAxisData] = useState([])

    let legendName = () => {
        switch (graphKey) {
            case 'fullfilled':
                return t('reporting.fullfilledorders')
            case 'unfullfilled':
                return t('reporting.unfullfilledorders')
            case 'totalrevenue':
                return t('reporting.totalrevenue')
            case 'lostrevenue':
                return t('reporting.lostrevenue')
            default:
                return t('reporting.fullfilledorders')
        }
    }


    useEffect(() => {
        let tempXaxisData = []
        let tempYaxisData = []
        if (graphData != {}) {
            for (let i = 0; i < Object.keys(graphData).length; i++) {
                const element = Object.keys(graphData)[i];
                tempXaxisData.push(element)
                if (graphKey == 'fullfilled') {
                    tempYaxisData.push(graphData[element].delivered)
                } else if (graphKey == 'unfullfilled') {
                    tempYaxisData.push(graphData[element].cancel)
                } else if (graphKey == 'totalrevenue') {
                    tempYaxisData.push(graphData[element].revanue)
                } else if (graphKey == 'lostrevenue') {
                    tempYaxisData.push(graphData[element].refund)
                }
            }
            setYAxisData(tempYaxisData)
            setXAxisData(tempXaxisData)
        }
    }, [graphData])


    return (
        <div id="chartBar5">
            <ReactApexChart options={{
                chart: {
                    //id: 'assetDistribution',
                    type: "bar",
                    height: 280,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'square',
                        columnWidth: '80%',
                        borderRadius: 0,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        filter: 'none',
                    }
                },
                markers: {
                    shape: "circle",
                },


                legend: {
                    show: true,
                    fontSize: '14px',
                    position: 'bottom',
                    labels: {
                        colors: '#000000',
                    },
                    markers: {
                        width: 18,
                        height: 18,
                        strokeWidth: 50,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                    }
                },
                stroke: {
                    show: true,
                    width: 3,
                    curve: 'smooth',
                    lineCap: 'round',
                    colors: ['transparent']
                },
                grid: {
                    borderColor: '#eee',
                },
                xaxis: {
                    position: 'bottom',
                    categories: xaxisData,
                    labels: {
                        show: true,
                        style: {
                            colors: '#999999',
                            fontSize: '10px',
                            fontFamily: 'poppins',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        show: false,
                    }
                },
                yaxis: {
                    labels: {
                        offsetX: -16,
                        style: {
                            colors: '#787878',
                            fontSize: '13px',
                            fontFamily: 'poppins',
                            fontWeight: 100,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: color,
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return " " + val + ""
                        }
                    }
                },
                responsive: [{
                    breakpoint: 575,
                    options: {
                        series: [
                            {
                                name: '',
                                data: [120, 90, 70],
                                //radius: 12,	
                            },
                            {
                                name: '',
                                data: [75, 50, 18]
                            },

                        ],
                        plotOptions: {
                            bar: {
                                columnWidth: '70%',
                            },

                        },
                        xaxis: {
                            categories: ['Jan', 'Feb', 'Mar'],
                        }

                    }
                }]

            }} series={[
                {
                    name: legendName(),
                    data: yaxisData,
                },
            ]} type="bar" height={280} />
        </div>
    );
}

export default ApexBarChartForReporting;