import AWS from 'aws-sdk'
import axiosApi from '../../api';
import { S3BUCKETNAME, imageUploadinLocal } from '../../constants/constants';

AWS.config.region = 'eu-west-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-west-2:675d18f2-76a0-4fab-83c5-a0f2587441cf',
});

const BUCKET_NAME = S3BUCKETNAME;

const s3 = new AWS.S3({
    region: AWS.config.region,
    credentials: AWS.config.credentials
});



export const S3Uploads = async (fileContent, filePath, fileName) => {
    if (imageUploadinLocal) {
        let tempFileArray = filePath.split('/')
        tempFileArray.pop()
        let tempfilePath = tempFileArray.join("/")

        const formData = new FormData()
        formData.append('File', fileContent)
        formData.append('FilePath', tempfilePath)
        formData.append('FileName', fileName)

        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        axiosApi.defaults.headers.common['Content-Type'] = `multipart/form-data`;
        await axiosApi.post('Api/upload-file', formData).then((res) => {
            if (res.data.status == 1) {
                return true
            } else {
                return false
            }
        }).catch((error) => {
            return false
        })
    } else {
        const params = {
            Bucket: BUCKET_NAME,
            Key: `uploads/${filePath}`,
            Body: fileContent,
            ContentType: fileContent.type,
        };
        var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
        let data = await s3.upload(params, options).promise()
        if (data) {
            return true
        }
        else {
            return false
        }

        //     (err, data) => {
        //     if (err) {
        //         return false
        //     }
        //     console.log('s3object second')

        //     return true
        // });
        console.log('s3object 3')

    }
}


export const S3ImageDelete = async (fileName) => {
    if (imageUploadinLocal) {
        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        await axiosApi.post('Api/remove-file', { FilePath: fileName }).then((res) => {
            if (res.data.status == 1) {
                return true
            } else {
                return false
            }
        }).catch((error) => {
            return false
        })
    } else {
        s3.deleteObject({
            Bucket: BUCKET_NAME,
            Key: `uploads/${fileName}`,
        }, function (err, data) {
            if (err) {
                return false
            }
        })
        return true
    }
}

