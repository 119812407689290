import { TextField } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { TimePicker } from "antd";
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import { addEditTimeScheduleApi } from '../../../../services/TimeScheduleServices';
import { useDispatch } from 'react-redux';

const ScheduleForm = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common')
    const { openTime, deliveryTime, collectionTime, handleSubmit, tabName } = props;
    const [flag, setFlag] = useState(false)

    const timeData = tabName == 'Open_Close_Schedule' ? openTime : (tabName == 'Delivery_Time_Schedule' ? deliveryTime : (tabName == 'Collection_Time_Schedule' ? collectionTime : []))

    const intValues = {
        timeSchedule: [
            {
                WeekName: "Sunday",
                StartTime: timeData && timeData.length > 0 && timeData[0] && timeData[0].StartTime ? timeData[0].StartTime : '00:00',
                EndTime: timeData && timeData.length > 0 && timeData[0] && timeData[0].EndTime ? timeData[0].EndTime : '00:00',
                errorStart: '',
                errorEnd: '',
                errorTextStart: '',
                errorTextEnd: ''
            },
            {
                WeekName: "Monday",
                StartTime: timeData && timeData.length > 0 && timeData[1] && timeData[1].StartTime ? timeData[1].StartTime : '00:00',
                EndTime: timeData && timeData.length > 0 && timeData[1] && timeData[1].EndTime ? timeData[1].EndTime : '00:00',
                errorStart: '',
                errorEnd: '',
                errorTextStart: '',
                errorTextEnd: ''
            },
            {
                WeekName: "Tuesday",
                StartTime: timeData && timeData.length > 0 && timeData[2] && timeData[2].StartTime ? timeData[2].StartTime : '00:00',
                EndTime: timeData && timeData.length > 0 && timeData[2] && timeData[2].EndTime ? timeData[2].EndTime : '00:00',
                errorStart: '',
                errorEnd: '',
                errorTextStart: '',
                errorTextEnd: ''
            },
            {
                WeekName: "Wednesday",
                StartTime: timeData && timeData.length > 0 && timeData[3] && timeData[3].StartTime ? timeData[3].StartTime : '00:00',
                EndTime: timeData && timeData.length > 0 && timeData[3] && timeData[3].EndTime ? timeData[3].EndTime : '00:00',
                errorStart: '',
                errorEnd: '',
                errorTextStart: '',
                errorTextEnd: ''
            },
            {
                WeekName: "Thursday",
                StartTime: timeData && timeData.length > 0 && timeData[4] && timeData[4].StartTime ? timeData[4].StartTime : '00:00',
                EndTime: timeData && timeData.length > 0 && timeData[4] && timeData[4].EndTime ? timeData[4].EndTime : '00:00',
                errorStart: '',
                errorEnd: '',
                errorTextStart: '',
                errorTextEnd: ''
            },
            {
                WeekName: "Friday",
                StartTime: timeData && timeData.length > 0 && timeData[5] && timeData[5].StartTime ? timeData[5].StartTime : '00:00',
                EndTime: timeData && timeData.length > 0 && timeData[5] && timeData[5].EndTime ? timeData[5].EndTime : '00:00',
                errorStart: '',
                errorEnd: '',
                errorTextStart: '',
                errorTextEnd: ''
            },
            {
                WeekName: "Saturday",
                StartTime: timeData && timeData.length > 0 && timeData[6] && timeData[6].StartTime ? timeData[6].StartTime : '00:00',
                EndTime: timeData && timeData.length > 0 && timeData[6] && timeData[6].EndTime ? timeData[6].EndTime : '00:00',
                errorStart: '',
                errorEnd: '',
                errorTextStart: '',
                errorTextEnd: ''
            },
        ]
    }
    useEffect(() => {

    }, [flag])


    const onsubmit = async (values) => {
        let err = [];
        let obj = {};
        let startTime = [];
        let endTime = [];
        if (tabName == 'Open_Close_Schedule') {
            await values.timeSchedule.map((item, index) => {
                if (item.StartTime != '00:00' && item.EndTime == '00:00') {
                    item.errorEnd = 'end';
                    item.errorTextEnd = 'Please select end time';
                } else if (item.StartTime == '00:00' && item.EndTime != '00:00') {
                    item.errorStart = 'start';
                    item.errorTextStart = 'Please select start time';
                } else if (moment(dayjs(item.StartTime, 'HH:mm')['$d']).diff(moment(dayjs(item.EndTime, 'HH:mm')['$d'])) > 0) {
                    item.errorStart = 'start';
                    item.errorTextStart = `The Start time should be less then end time`;
                } else {
                    if (item.StartTime != '00:00' && deliveryTime[index] && deliveryTime[index].StartTime && deliveryTime[index].StartTime != "00:00" && moment(dayjs(item.StartTime, 'HH:mm')['$d']).diff(moment(dayjs(deliveryTime[index].StartTime, 'HH:mm')['$d']), "Time") > 0) {
                        item.errorStart = 'start';
                        item.errorTextStart = `The Delivery time should be greator then restaurant open time(${deliveryTime[index].StartTime})`;
                    }
                    else if (item.StartTime != '00:00' && collectionTime[index] && collectionTime[index].StartTime && collectionTime[index].StartTime != "00:00" && moment(dayjs(item.StartTime, 'HH:mm')['$d']).diff(moment(dayjs(collectionTime[index].StartTime, 'HH:mm')['$d']), "Time") > 0) {
                        item.errorStart = 'start';
                        item.errorTextStart = `The Collection time should be greator then restaurant open time(${collectionTime[index].StartTime})`;
                    } else {
                        item.errorStart = '';
                        item.errorTextStart = '';
                    }
                    if (item.EndTime != '00:00' && deliveryTime[index] && deliveryTime[index].EndTime && deliveryTime[index].EndTime != "00:00" && moment(dayjs(item.EndTime, 'HH:mm')['$d']).diff(moment(dayjs(deliveryTime[index].EndTime, 'HH:mm')['$d']), "Time") < 0) {
                        item.errorEnd = 'end';
                        item.errorTextEnd = `The Delivery time should be less then restaurant close time(${deliveryTime[index].EndTime})`;
                    }
                    else if (item.EndTime != '00:00' && collectionTime[index] && collectionTime[index].EndTime && collectionTime[index].StartTime != "00:00" && moment(dayjs(item.EndTime, 'HH:mm')['$d']).diff(moment(dayjs(collectionTime[index].EndTime, 'HH:mm')['$d']), "Time") < 0) {
                        item.errorEnd = 'end';
                        item.errorTextEnd = `The Collection time should be less then restaurant close time(${collectionTime[index].EndTime})`;
                    } else {
                        item.errorEnd = '';
                        item.errorTextEnd = '';
                    }
                }
                if (item.errorTextStart == '' && item.errorTextEnd == '') {
                    err.push(false);
                } else {
                    err.push(true);
                }
                startTime.push(moment(dayjs(item.StartTime, 'HH:mm')['$d']).format("HH:mm"))
                endTime.push(moment(dayjs(item.EndTime, 'HH:mm')['$d']).format("HH:mm"))
            })
            setFlag(!flag)
            obj = {
                "ScheduleType": "openclosetime",
                "StartTime": startTime.join(','),
                "EndTime": endTime.join(','),
                "WeekDay": "7,1,2,3,4,5,6"
            }
        }
        if (tabName == 'Delivery_Time_Schedule' || tabName == 'Collection_Time_Schedule') {
            await values.timeSchedule.map((item, index) => {
                if (item.StartTime != '00:00' && item.EndTime == '00:00') {
                    item.errorEnd = 'end';
                    item.errorTextEnd = 'Please select end time';
                } else if (item.StartTime == '00:00' && item.EndTime != '00:00') {
                    item.errorStart = 'start';
                    item.errorTextStart = 'Please select start time';
                } else if (moment(dayjs(item.StartTime, 'HH:mm')['$d']).diff(moment(dayjs(item.EndTime, 'HH:mm')['$d'])) > 0) {
                    item.errorStart = 'start';
                    item.errorTextStart = `The Start time should be less then end time`;
                } else {
                    if (item.StartTime != '00:00' && openTime[index] && openTime[index].StartTime && moment(dayjs(item.StartTime, 'HH:mm')['$d']).diff(moment(dayjs(openTime[index].StartTime, 'HH:mm')['$d']), "Time") < 0) {
                        item.errorStart = 'start';
                        item.errorTextStart = `Enter the time greater then restaurant open time for this day(${openTime[index].StartTime})`;
                    }
                    else {
                        item.errorStart = '';
                        item.errorTextStart = '';
                    }
                    if (item.EndTime != '00:00' && openTime[index] && openTime[index].EndTime && moment(dayjs(item.EndTime, 'HH:mm')['$d']).diff(moment(dayjs(openTime[index].EndTime, 'HH:mm')['$d']), "Time") > 0) {
                        item.errorEnd = 'end';
                        item.errorTextEnd = `Enter the time less then restaurant close time for this day(${openTime[index].EndTime})`;
                    }
                    else {
                        item.errorEnd = '';
                        item.errorTextEnd = '';
                    }
                }
                if (item.errorTextStart == '' && item.errorTextEnd == '') {
                    err.push(false);
                } else {
                    err.push(true);
                }
                startTime.push(moment(dayjs(item.StartTime, 'HH:mm')['$d']).format("HH:mm"))
                endTime.push(moment(dayjs(item.EndTime, 'HH:mm')['$d']).format("HH:mm"))
            })
            setFlag(!flag)
            obj = {
                "ScheduleType": tabName == 'Delivery_Time_Schedule' ? "delivery" : "collection",
                "StartTime": startTime.join(','),
                "EndTime": endTime.join(','),
                "WeekDay": "7,1,2,3,4,5,6"
            }
        }
        let error = err.filter((item) => item == true)
        if (error.length <= 0) {
            dispatch(addEditTimeScheduleApi(obj, (res) => {
            }))
        }

    }

    return (
        timeData && timeData.length > 0 &&
        <Formik
            // enableReinitialize
            initialValues={intValues}
            onSubmit={onsubmit}
        >
            {(props) => {
                const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, setFieldValue, handleSubmit } = props;
                return (
                    <>
                        <Form onSubmit={handleSubmit} noValidate>
                            <FieldArray
                                name="timeSchedule"
                                render={arrayHelpers => (
                                    <>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th scope="col"> {t('timeschedulescreen.opentiming')}</th>
                                                        <th scope="col"> {t('timeschedulescreen.closetiming')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.timeSchedule && values.timeSchedule.length > 0 && (
                                                        values.timeSchedule.map((item, index) => {
                                                            return <tr key={index}>
                                                                <th className='align-middle'>{item.WeekName} </th>
                                                                <td>
                                                                    <TimePicker
                                                                        disabled={tabName != 'Open_Close_Schedule' && openTime[index] && openTime[index].StartTime == '00:00'}
                                                                        name={`timeSchedule.${index}.StartTime`}
                                                                        size={"large"}
                                                                        format={'HH:mm'}
                                                                        allowClear={false}
                                                                        status={item.errorStart == 'start' ? "error" : ''}
                                                                        showNow={false}
                                                                        value={item.StartTime ? dayjs(item.StartTime, 'HH:mm') : dayjs('00:00', 'HH:mm')}
                                                                        onChange={(time, timeString) => {
                                                                            setFieldValue(`timeSchedule.${index}.StartTime`, dayjs(timeString, 'HH:mm'))
                                                                        }}
                                                                    />
                                                                    {/* <TextField
                                                                        Is24hFormat={true}
                                                                        fullWidth
                                                                        type="time"
                                                                        name={`timeSchedule.${index}.StartTime`}
                                                                        value={item.StartTime}
                                                                        onChange={(e) => { handleChange(e, index) }}
                                                                    /> */}
                                                                    {item.errorStart == 'start' && <p className='text-red'>{item.errorTextStart}</p>}
                                                                </td>
                                                                <td>
                                                                    <TimePicker
                                                                        disabled={tabName != 'Open_Close_Schedule' && openTime[index] && openTime[index].EndTime == '00:00'}
                                                                        name={`timeSchedule.${index}.EndTime`}
                                                                        value={item.EndTime ? dayjs(item.EndTime, 'HH:mm') : dayjs('00:00', 'HH:mm')}
                                                                        size={"large"}
                                                                        status={item.errorEnd == 'end' ? "error" : ''}
                                                                        allowClear={false}
                                                                        showNow={false}
                                                                        format={'HH:mm'}
                                                                        onChange={(time, timeString) => {
                                                                            setFieldValue(`timeSchedule.${index}.EndTime`, dayjs(timeString, 'HH:mm'))
                                                                        }}
                                                                    />
                                                                    {/* <TextField
                                                                        fullWidth
                                                                        type="time"
                                                                        name={`timeSchedule.${index}.EndTime`}
                                                                        value={item.EndTime}
                                                                        onChange={(e) => { handleChange(e, index) }}
                                                                    /> */}
                                                                    {item.errorEnd == 'end' && <p className='text-red'>{item.errorTextEnd}</p>}
                                                                </td>
                                                            </tr>

                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <Button
                                            style={{ marginRight: "20px" }}
                                            variant="danger light"
                                            type='reset'
                                        >
                                            {t('buttons.cancel')}
                                        </Button>
                                        <Button type="submit" size="large">
                                            {t('buttons.save')}
                                        </Button>
                                    </>
                                )}
                            />
                        </Form >
                    </>
                )
            }}

        </Formik>
    )
}

export default ScheduleForm
