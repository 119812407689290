import { Form, Formik } from 'formik'
import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageInput from '../../../../helpers/fileUploader/ImageInput';
import { S3ImageUpload } from '../../../../constants/constants';
import { S3ImageDelete, S3Uploads } from '../../../../helpers/S3Upload/S3upload';
import { useEffect } from 'react';

const RestaurantImages = (props) => {
    const { t } = useTranslation('common')
    const { InitialValues, onsubmit, profileData } = props
    let userDetails = JSON.parse(localStorage.getItem('userDetails'))
    let restaurantId = userDetails.restaurant_id
    const [imageVal, setImageVal] = useState({
        HeaderLogoImage: profileData?.HeaderLogo_URL ? profileData?.HeaderLogo_URL : '',
        HeaderLogoImageSrc: profileData?.HeaderLogo_URL ? `${S3ImageUpload}${restaurantId}/restaurant/headerlogo/${profileData?.HeaderLogo_URL}` : '',
        LogoImage: profileData?.LogoUrl ? profileData?.LogoUrl : '',
        LogoImageSrc: profileData?.LogoUrl ? `${S3ImageUpload}${restaurantId}/restaurant/profile/${profileData?.LogoUrl}` : '',
        bannerImage: profileData?.Home_Banner ? profileData?.Home_Banner : '',
        bannerImageSrc: profileData?.Home_Banner ? `${S3ImageUpload}${restaurantId}/restaurant/banner/${profileData?.Home_Banner}` : '',
    })
    const [deleteImagePath, setDeleteImagePath] = useState({
        HeaderLogoImage: '',
        LogoImage: '',
        bannerImage: '',
    })

    const handleChangeForHeaderLogo = (e) => {
        if (profileData?.HeaderLogo_URL == imageVal.HeaderLogoImage) {
            const path = `${restaurantId}/restaurant/headerlogo/${imageVal.HeaderLogoImage}`
            setDeleteImagePath(prevState => ({
                ...prevState,
                HeaderLogoImage: path,
            }))
        }
        setImageVal(prevState => ({
            ...prevState,
            HeaderLogoImage: e.photo.data,
            HeaderLogoImageSrc: e.photo.src
        }))
    }
    const handleChangeForLogo = (e) => {
        if (profileData?.LogoUrl == imageVal.LogoImage) {
            const path = `${restaurantId}/restaurant/profile/${imageVal.LogoImage}`
            setDeleteImagePath(prevState => ({
                ...prevState,
                LogoImage: path,
            }))
        }
        setImageVal(prevState => ({
            ...prevState,
            LogoImage: e.photo.data,
            LogoImageSrc: e.photo.src
        }))
    }
    const handleChangeForBanner = (e) => {
        if (profileData?.Home_Banner == imageVal.bannerImage) {
            const path = `${restaurantId}/restaurant/banner/${imageVal.bannerImage}`
            setDeleteImagePath(prevState => ({
                ...prevState,
                bannerImage: path,
            }))
        }
        setImageVal(prevState => ({
            ...prevState,
            bannerImage: e.photo.data,
            bannerImageSrc: e.photo.src
        }))
    }

    useEffect(() => {
        setImageVal({
            HeaderLogoImage: profileData?.HeaderLogo_URL ? profileData?.HeaderLogo_URL : '',
            HeaderLogoImageSrc: profileData?.HeaderLogo_URL ? `${S3ImageUpload}${restaurantId}/restaurant/headerlogo/${profileData?.HeaderLogo_URL}` : '',
            LogoImage: profileData?.LogoUrl ? profileData?.LogoUrl : '',
            LogoImageSrc: profileData?.LogoUrl ? `${S3ImageUpload}${restaurantId}/restaurant/profile/${profileData?.LogoUrl}` : '',
            bannerImage: profileData?.Home_Banner ? profileData?.Home_Banner : '',
            bannerImageSrc: profileData?.Home_Banner ? `${S3ImageUpload}${restaurantId}/restaurant/banner/${profileData?.Home_Banner}` : '',
        })
    }, [profileData])

    const handleSubmitForImage = () => {
        let currentDate = Date.now()
        if (deleteImagePath.HeaderLogoImage) {
            S3ImageDelete(deleteImagePath.HeaderLogoImage)
        }
        if (deleteImagePath.LogoImage) {
            S3ImageDelete(deleteImagePath.LogoImage)
        }
        if (deleteImagePath.bannerImage) {
            S3ImageDelete(deleteImagePath.bannerImage)
        }

        if (typeof imageVal.HeaderLogoImage == 'object') {
            const fileNameForHeaderlogo = currentDate + '_' + imageVal.HeaderLogoImage.name.replace(/ /g, "_")
            const filePathForHeaderlogo = `${restaurantId}/restaurant/headerlogo/${fileNameForHeaderlogo}`
            S3Uploads(imageVal.HeaderLogoImage, filePathForHeaderlogo, fileNameForHeaderlogo)
            InitialValues.HeaderLogoImageName = fileNameForHeaderlogo
        }
        else {
            InitialValues.HeaderLogoImageName = imageVal.HeaderLogoImage
        }
        if (typeof imageVal.LogoImage == 'object') {
            const fileNameForLogoImage = currentDate + '_' + imageVal.LogoImage.name.replace(/ /g, "_")
            const filePathForLogoImage = `${restaurantId}/restaurant/profile/${fileNameForLogoImage}`
            S3Uploads(imageVal.LogoImage, filePathForLogoImage, fileNameForLogoImage)
            InitialValues.LogoImageName = fileNameForLogoImage
        }
        else {
            InitialValues.LogoImageName = imageVal.LogoImage
        }
        if (typeof imageVal.bannerImage == 'object') {
            const fileNameForbannerImage = currentDate + '_' + imageVal.bannerImage.name.replace(/ /g, "_")
            const filePathForbannerImage = `${restaurantId}/restaurant/banner/${fileNameForbannerImage}`
            S3Uploads(imageVal.bannerImage, filePathForbannerImage, fileNameForbannerImage)
            InitialValues.bannerImageName = fileNameForbannerImage
        }
        else {
            InitialValues.bannerImageName = imageVal.bannerImage
        }

        onsubmit(InitialValues)
    }

    return (
        <Formik
            enableReinitialize
            initialValues={InitialValues}
            onSubmit={handleSubmitForImage}

        >
            {(props) => {
                const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                return (
                    <div className="pt-3">
                        <Form onSubmit={handleSubmit} noValidate>
                            <div className="settings-form">
                                <h4 className="text-primary p-3">{t('profile.tab.restaurantimages')}</h4>
                                <div className="row pb-5">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h6>{t('profile.restaurantimages.restaurantheaderlogoimage')}</h6>
                                        <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.imageshouldbeuploadedin200px56pxdimension')}</span></small>
                                        <div className="d-flex">
                                            <ImageInput
                                                imageWidth={200}
                                                imageHeight={56}
                                                imageData={imageVal.HeaderLogoImageSrc}
                                                type="Header Logo Image"
                                                name="photo"
                                                label="Add Photo"
                                                showPreview
                                                onChange={(files) => { handleChangeForHeaderLogo(files) }}
                                            />
                                            {imageVal.HeaderLogoImageSrc &&
                                                <div className='ps-3'>
                                                    <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                        onClick={() => {
                                                            if (profileData?.HeaderLogo_URL == imageVal.HeaderLogoImage) {
                                                                const path = `${restaurantId}/restaurant/headerlogo/${imageVal.HeaderLogoImage}`
                                                                setDeleteImagePath(prevState => ({
                                                                    ...prevState,
                                                                    HeaderLogoImage: path,
                                                                }))
                                                                setImageVal(
                                                                    {
                                                                        HeaderLogoImage: '',
                                                                        HeaderLogoImageSrc: '',
                                                                        LogoImage: imageVal.LogoImage,
                                                                        LogoImageSrc: imageVal.LogoImageSrc,
                                                                        bannerImage: imageVal.bannerImage,
                                                                        bannerImageSrc: imageVal.bannerImageSrc,
                                                                    })
                                                            } else {
                                                                setImageVal(
                                                                    {
                                                                        HeaderLogoImage: '',
                                                                        HeaderLogoImageSrc: '',
                                                                        LogoImage: imageVal.LogoImage,
                                                                        LogoImageSrc: imageVal.LogoImageSrc,
                                                                        bannerImage: imageVal.bannerImage,
                                                                        bannerImageSrc: imageVal.bannerImageSrc,
                                                                    })
                                                            }
                                                        }}>
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h6>{t('profile.restaurantimages.restaurantlogoimage')}</h6>
                                        <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.imageshouldbeuploadedin300px300pxdimension')}</span></small>
                                        <div className="d-flex">
                                            <ImageInput
                                                imageWidth={150}
                                                imageHeight={150}
                                                imageData={imageVal.LogoImageSrc}
                                                type="Logo Image"
                                                name="photo"
                                                label="Add Photo"
                                                showPreview
                                                onChange={(files) => { handleChangeForLogo(files) }}
                                            />
                                            {imageVal.LogoImageSrc &&
                                                <div className='ps-3'>
                                                    <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                        onClick={() => {
                                                            if (profileData?.LogoUrl == imageVal.LogoImage) {
                                                                const path = `${restaurantId}/restaurant/profile/${imageVal.LogoImage}`
                                                                setDeleteImagePath(prevState => ({
                                                                    ...prevState,
                                                                    LogoImage: path,
                                                                }))
                                                                setImageVal(
                                                                    {
                                                                        HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                        HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                        LogoImage: '',
                                                                        LogoImageSrc: '',
                                                                        bannerImage: imageVal.bannerImage,
                                                                        bannerImageSrc: imageVal.bannerImageSrc,
                                                                    })
                                                            } else {
                                                                setImageVal(
                                                                    {
                                                                        HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                        HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                        LogoImage: '',
                                                                        LogoImageSrc: '',
                                                                        bannerImage: imageVal.bannerImage,
                                                                        bannerImageSrc: imageVal.bannerImageSrc,
                                                                    })
                                                            }
                                                        }}>
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 pt-5">
                                        <h6>{t('profile.restaurantimages.restaurantdetailsbannerimage')}</h6>
                                        <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.imageshouldbeuploadedin1400px350pxdimension')}</span></small>
                                        <div className="d-flex">
                                            <ImageInput
                                                showWidth={'100%'}
                                                showHeight={'100%'}
                                                imageWidth={1400}
                                                imageHeight={350}
                                                imageData={imageVal.bannerImageSrc}
                                                type="Banner Image"
                                                name="photo"
                                                label="Add Photo"
                                                showPreview
                                                onChange={(files) => { handleChangeForBanner(files) }}
                                            />
                                            {imageVal.bannerImageSrc &&
                                                <div className='ps-3'>
                                                    <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                        onClick={() => {
                                                            if (profileData?.Home_Banner == imageVal.bannerImage) {
                                                                const path = `${restaurantId}/restaurant/banner/${imageVal.bannerImage}`
                                                                setDeleteImagePath(prevState => ({
                                                                    ...prevState,
                                                                    bannerImage: path,
                                                                }))
                                                                setImageVal(
                                                                    {
                                                                        HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                        HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                        LogoImage: imageVal.LogoImage,
                                                                        LogoImageSrc: imageVal.LogoImageSrc,
                                                                        bannerImage: '',
                                                                        bannerImageSrc: '',
                                                                    })
                                                            } else {
                                                                setImageVal(
                                                                    {
                                                                        HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                        HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                        LogoImage: imageVal.LogoImage,
                                                                        LogoImageSrc: imageVal.LogoImageSrc,
                                                                        bannerImage: '',
                                                                        bannerImageSrc: '',
                                                                    })
                                                            }
                                                        }}>
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.PleaseuploadimageasperperticulerimageNoteforbetterresolution')}</span></small>
                                <div className="mt-3 d-flex float-right justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                        disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    )
}

export default RestaurantImages
