import React from 'react'

const OrderRecieptPrint = React.forwardRef(({ orderDetails, t }, ref) => (
    <div ref={ref} className="row printDiv p-3">
        <div className="col-4" style={{ width: '33.33%' }}>
            <div id='Bill' style={{ maxWidth: "none" }}>
                <div style={{ textAlign: 'center', fontSize: "30px", borderBottom: '1px dashed gray' }}>
                    {orderDetails.RestaurantName}
                </div>

                <div style={{ borderBottom: '1px dashed gray' }}>
                    <p style={{ fontSize: '20px', textAlign: 'center', marginTop: '10px', marginBottom: '0', lineHeight: '1' }}>
                        <b>#{orderDetails.TransactionId}</b>
                    </p>
                    <p style={{ textAlign: 'center', marginBottom: '5px' }}>
                        <span style={{ fontSize: "15px", letterSpacing: '1px', fontWeight: '100' }}>
                            {t('date_format', {
                                date: orderDetails.DateOfOrder
                            })}
                        </span>
                    </p>

                    {orderDetails.OrderType == '1' ?
                        <p style={{ fontSize: '20px', textAlign: 'center', marginTop: '5px', marginBottom: '0', lineHeight: '1' }}>
                            <b>{t('ordermasterscreen.delivery')}</b>
                        </p>
                        :
                        <p style={{ fontSize: '20px', textAlign: 'center', marginTop: '5px', marginBottom: '0', lineHeight: '1' }}>
                            <b>{t('ordermasterscreen.collection')}</b>
                        </p>}
                    <p style={{ textAlign: 'center', fontSize: "15px", letterSpacing: '1px', fontWeight: '100', marginBottom: '5px' }}>
                        ASAP {orderDetails.ExpectedDeliveryDateTime}
                    </p>
                </div>

                <div style={{ marginTop: "10px", width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ fontWeight: '600' }}>{t('ordermasterscreen.item')}</div>
                        <div style={{ width: '50%', textAlign: 'right', fontWeight: '600' }}>
                            {t('ordermasterscreen.subtotal')}
                        </div>
                    </div>
                    {orderDetails && orderDetails.OrderDetail.length > 0 &&
                        orderDetails.OrderDetail.map((item, index) => (
                            <div key={index} style={{ marginBottom: '3px', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '70%' }}>
                                    <span style={{ fontWeight: '500', letterSpacing: '0.5px' }}>{item.ItemName}</span>
                                    <br />
                                    <span style={{ fontSize: '11px' }}>{item.ToppingNames != '' ? '(' + item.ToppingNames + ')' : ''}</span>
                                    <span style={{ fontSize: '11px', fontStyle: 'italic' }} className='text-green'>{item.Promotion_Name}</span>
                                </div>
                                <div style={{ textAlign: 'right', verticalAlign: 'text-top' }}>
                                    {t('currency', { Number: parseFloat(parseFloat(item.ToppingSubTotal) + parseFloat(item.SubTotal)) })}
                                    <br />
                                    {item.Promotion_Name != '' && (
                                        <>
                                            <strike className='text-red'>
                                                {t('currency', {
                                                    Number: parseFloat((parseFloat(item.SubTotalBeforePromotion) +
                                                        parseFloat(item.ToppingSubTotalBeforePromotion)) * parseFloat(item.Quantity))
                                                })}
                                            </strike>
                                            <br />
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    <div style={{ borderBottom: '1px dashed gray', margin: '5px 0' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '50%' }}>
                                {t('ordermasterscreen.subtotal')}
                            </div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                                {orderDetails.OrderTotal.SubTotalBeforePromotion != orderDetails.OrderTotal.SubTotalWithoutDeduction &&
                                    (
                                        <>
                                            <strike className='text-red'>
                                                {t("currency", { Number: parseFloat(orderDetails.OrderTotal.SubTotalBeforePromotion) })}
                                            </strike>
                                        </>
                                    )}
                                &nbsp;
                                {t("currency", { Number: parseFloat(orderDetails.OrderTotal.SubTotalWithoutDeduction) })}
                            </div>
                        </div>

                        {orderDetails.OrderTotal.SmallOrderFee > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{t('ordermasterscreen.smallorderfee')}</div>
                                <div style={{ width: '50%', textAlign: 'right' }}>
                                    {t('currency', { Number: parseFloat(orderDetails.OrderTotal.SmallOrderFee) })}
                                </div>
                            </div>
                        )}

                        {orderDetails.OrderTotal.ShippingCharge > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{t('ordermasterscreen.deliverycharges')}</div>
                                <div style={{ width: '50%', textAlign: 'right' }}>
                                    {t('currency', { Number: parseFloat(orderDetails.OrderTotal.ShippingCharge) })}
                                </div>
                            </div>
                        )}

                        {orderDetails.OrderTotal.ServiceCharge > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{t('ordermasterscreen.servicecharges')}</div>
                                <div style={{ width: '50%', textAlign: 'right' }}>
                                    {t('currency', { Number: parseFloat(orderDetails.OrderTotal.ServiceCharge) })}
                                </div>
                            </div>
                        )}

                        {orderDetails.OrderTotal.DriverTip > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{t('ordermasterscreen.deliverypartnertip')}</div>
                                <div style={{ width: '50%', textAlign: 'right' }}>
                                    {t('currency', { Number: parseFloat(orderDetails.OrderTotal.DriverTip) })}
                                </div>
                            </div>
                        )}

                        {orderDetails.OrderTotal.SubTotalBeforePromotion != orderDetails.OrderTotal.SubTotalWithoutDeduction && (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{t('ordermasterscreen.restaurantpromo')}</div>
                                <div style={{ width: '50%', textAlign: 'right' }}>
                                    -{t('currency', {
                                        Number: parseFloat(parseFloat(orderDetails.OrderTotal.SubTotalBeforePromotion) -
                                            parseFloat(orderDetails.OrderTotal.SubTotalWithoutDeduction))
                                    })}
                                </div>
                            </div>
                        )}
                        {orderDetails.OrderTotal.PromoDeductAmount &&
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{t('ordermasterscreen.promodiscount')}</div>
                                <div style={{ width: '50%', textAlign: 'right' }}>
                                    -{t("currency", { Number: parseFloat(orderDetails.OrderTotal.PromoDeductAmount) })}
                                </div>
                            </div>
                        }
                        {orderDetails.OrderTotal.VoucherDeductAmount &&
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{t('ordermasterscreen.voucherdiscount')}</div>
                                <div style={{ width: '50%', textAlign: 'right' }}>
                                    -{t("currency", { Number: parseFloat(orderDetails.OrderTotal.VoucherDeductAmount) })}
                                </div>
                            </div>
                        }

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{t('ordermasterscreen.total')}</div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                                {t('currency', { Number: parseFloat(orderDetails.OrderTotal.GrandTotal) })}
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ borderBottom: '1px dashed gray', padding: '3px 0' }}></div>

                {orderDetails.OrderTotal.PaymentMode == 3 || orderDetails.OrderTotal.PaymentMode == 4 && (
                    <div style={{ paddingTop: '5px', borderBottom: '1px dashed gray' }}>
                        <p style={{ marginBottom: '4px' }}>
                            <span>{t('ordermasterscreen.cardnumber')}:</span> {orderDetails.OrderTotal.CardNumber}
                        </p>
                    </div>
                )}

                <div style={{ padding: '5px 0px', borderBottom: '1px dashed gray' }}>
                    <p style={{ fontSize: '16px', marginBottom: '0' }}>
                        <b>{t('ordermasterscreen.note')}: {orderDetails.Note ? orderDetails.Note : "N/A"}</b><br />
                    </p>
                </div>

                {orderDetails.OrderTotal.DeliveryMode == 1 && orderDetails.OrderStatusArray[0].OrderStatusId != 400 ? (
                    <p style={{ textAlign: "center", borderBottom: '1px dashed gray' }}>
                        <span style={{ fontSize: "25px" }}>{t('ordermasterscreen.ordernotpaid')}</span>
                    </p>
                ) : (
                    <p style={{ textAlign: "center", borderBottom: '1px dashed gray' }}>
                        <span style={{ fontSize: '25px' }}>{t('ordermasterscreen.orderhasbeenpaid')}</span>
                    </p>
                )}

                <div>
                    <p style={{ marginBottom: '-2px' }}><b>{t('ordermasterscreen.customerInformation')}:</b></p>
                    <p style={{ marginBottom: '-3px' }}>{t('ordermasterscreen.customerid')}: {orderDetails.Customer.CustomerId}</p>
                    <p style={{ marginBottom: '-2px' }}>{t('ordermasterscreen.name')}: {orderDetails.Customer.Name}</p>
                    <p style={{ marginBottom: '-2px', lineHeight: '1.3', paddingTop: '3px' }}>
                        {t('ordermasterscreen.address')}: {orderDetails.Customer.FullAddress}
                    </p>
                </div>
            </div>
        </div>
    </div>
));


export default OrderRecieptPrint
