
import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import { useDispatch } from 'react-redux';
import { getReportData, getcustomerDetails } from '../../../services/ReportingServices';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import AreaChart from './performanceChart';
import moment from 'moment';
import ApexBarChartforWeeklyAvg from './ApexBarChartforWeeklyAvg';

const Performance = () => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const [orderPerformance, setOrderPerformance] = useState({})
    const [graphData, setGraphData] = useState({})
    const [maxCount, setMaxCount] = useState(0)
    const [avgWeeklyOrderCount, setAvgWeeklyOrderCount] = useState(0)

    useEffect(() => {
        dispatch(getcustomerDetails((res) => {
            let sum = 0
            let maxNumber = 0
            setOrderPerformance(res.data)
            if (res.data && res.data.weekly_avg_by_weekday && res.data.weekly_avg_by_weekday.length > 0) {
                for (let i = 0; i < res.data.weekly_avg_by_weekday.length; i++) {
                    const element = res.data.weekly_avg_by_weekday[i];
                    if (element.order_count) {
                        sum = parseInt(sum) + parseInt(element.order_count)
                        if (element.average_orders > maxNumber) {
                            maxNumber = element.average_orders
                        }
                    }
                }
                let finalWeeklyAvg = Math.round(sum / 4)
                setMaxCount(maxNumber)
                setAvgWeeklyOrderCount(finalWeeklyAvg)
            }
        }))
    }, [])


    useEffect(() => {
        let obj = {
            StartDate: moment(new Date()).subtract(27, "days").format('YYYY-MM-DD'),
            EndDate: moment(new Date()).format('YYYY-MM-DD')
        }
        dispatch(getReportData(obj, (res) => {
            setGraphData(res.data)
        }))
    }, [])

    return (
        <div className="performance">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-xl-4 col-lg-4">
                                    <h4>{t('reporting.overallperformancescore')}</h4>
                                    <p className='pt-3 fs-20'>
                                        <span className='fw-bold fs-48'>
                                            57
                                        </span>
                                        &nbsp;/100
                                    </p>
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className=' m-1'>{t('reporting.orders')}</p>
                                            </div>
                                            <div>
                                                <p className=' m-1'>20 / 50</p>
                                            </div>
                                        </div>
                                        <ProgressBar variant="determinate" now={20} />
                                    </div>
                                    <div className='pt-2'>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className=' m-1'>{t('reporting.customerexperience')}</p>
                                            </div>
                                            <div>
                                                <p className=' m-1'>36 / 50</p>
                                            </div>
                                        </div>
                                        <ProgressBar variant="determinate" now={36} />
                                    </div>
                                </div> */}
                                <div className="col-xl-12 col-lg-12 text-end ps-0">
                                    <h5 className='m-0'>{t('reporting.showingresultforlast28days')}</h5>
                                    <AreaChart graphData={graphData} customerData={orderPerformance && orderPerformance.customer_repeat_28 ? orderPerformance.customer_repeat_28 : {}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <p className="fs-24 mb-0">
                    {t('reporting.orders')}
                </p>
                <p>
                    {t('reporting.orderinsigtstext')}
                </p>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h3>{t('reporting.averageweeklyorders')}</h3>
                            <p className="fs-46 fw-bolder mb-0">{avgWeeklyOrderCount}</p>
                            <p>{t('reporting.averageordertext')}</p>
                            <div>
                                <ApexBarChartforWeeklyAvg graphData={orderPerformance && orderPerformance.weekly_avg_by_weekday ? orderPerformance.weekly_avg_by_weekday : []} />
                            </div>
                            {/* <table className="table table-borderless">
                                <tbody>
                                    {orderPerformance && orderPerformance.weekly_avg_by_weekday && orderPerformance.weekly_avg_by_weekday.length > 0 &&
                                        orderPerformance.weekly_avg_by_weekday.map((item, i) => {
                                            return <tr key={i}>
                                                <th scope="row">{item.day_of_week}</th>
                                                <td className='w-100'> <ProgressBar variant="determinate" now={item.average_orders} max={maxCount} /></td>
                                                <td> {`${t("reporting.ordercount", { order: item.average_orders })}`}</td>
                                            </tr>
                                        })}
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <p className="fs-24 mb-0">
                    {t('reporting.customerexperience')}
                </p>
                <p>
                    {t('reporting.customerinsigtstext')}
                </p>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h3>{t('reporting.customerrepeaterate')}</h3>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="w-75 m-2">
                                        <CircularProgressbar
                                            value={orderPerformance && orderPerformance.customer_repeat_rate ? orderPerformance.customer_repeat_rate : 0}
                                            text={`${orderPerformance && orderPerformance.customer_repeat_rate ? orderPerformance.customer_repeat_rate : 0}%`}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                strokeLinecap: "butt"
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-8 align-self-center p-0" style={{ backgroundColor: '#d3ebff' }}>
                                    <div className='p-3 d-flex'>
                                        <div className='w-5 me-3'>
                                            <img src="/images/lightbulb.png" alt="" className='w-100' />
                                        </div>
                                        <div>
                                            <h4 className='mb-0'>
                                                {t('reporting.tip')}
                                            </h4>
                                            <p className='mb-0 fs-16'>{t('reporting.repeattiptext', { number: orderPerformance && orderPerformance.customer_repeat_rate ? orderPerformance.customer_repeat_rate : 0 })}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h3>{t('reporting.rejectedorders')}</h3>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="w-75 m-2">
                                        <CircularProgressbarWithChildren
                                            value={orderPerformance && orderPerformance.rejected_order_rate ? orderPerformance.rejected_order_rate : 0}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                strokeLinecap: "butt"
                                            })}
                                        >
                                            <p className='fs-24 mb-0' style={{ color: '#3E98C7' }}>
                                                {t('reporting.total')}
                                            </p>
                                            <p className='mb-0 fs-38 fw-bold' style={{ color: '#3E98C7' }}>
                                                {orderPerformance && orderPerformance.rejected_order_rate ? orderPerformance.rejected_order_rate : 0}%
                                            </p>
                                            <p className='mb-0' style={{ color: '#3E98C7' }}>
                                                {orderPerformance && orderPerformance.rejected_order ? orderPerformance.rejected_order : 0}  {t('reporting.rejected')}
                                            </p>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </div>
                                <div className="col-lg-8 align-self-center p-0" style={{ backgroundColor: '#d3ebff' }}>
                                    <div className='p-3 d-flex'>
                                        <div className='w-5 me-3'>
                                            <img src="/images/lightbulb.png" alt="" className='w-100' />
                                        </div>
                                        {orderPerformance && orderPerformance.rejected_order_rate && orderPerformance.rejected_order_rate > 0
                                            ?
                                            <div style={{ width: '90%' }}>
                                                <h4 className='mb-0'>
                                                    {t('reporting.tip')}
                                                </h4>
                                                <p className='mb-0 fs-16'>{t('reporting.norejectedorderstextifmorethenzero', { number: orderPerformance && orderPerformance.rejected_order_rate ? orderPerformance.rejected_order_rate : 0 })}</p>
                                            </div>

                                            :
                                            <div style={{ width: '90%' }}>
                                                <h4 className='mb-0'>
                                                    {t('reporting.norejectedorders')}
                                                </h4>
                                                <p className='mb-0 fs-16'>{t('reporting.norejectedorderstext')}</p>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Performance
