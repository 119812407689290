import React, { useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { COLUMNS } from './Columns';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import AddEditModal from './AddEditModal';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteUserApi, getUserListApi } from '../../../services/RestaurantServices';
import { useTranslation } from 'react-i18next';
import TableSearch from '../../../helpers/TableSearch';
import TablePaginationHelper from '../../../helpers/Pagination';
import ViewUserModal from './ViewUserModal';
import { Empty } from 'antd';

export const User = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const [userList, setUserList] = useState([])
    const dispatch = useDispatch()
    const { auth } = useSelector((state) => state.auth);
    const { t } = useTranslation('common');
    const permissions = JSON.parse(auth.role_permissions)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        dispatch(getUserListApi((res) => {
            if (res && res.restaurant_users) {
                let arr;
                // if (res.restaurant_owner) {
                //     res.restaurant_owner['isOwner'] = true
                //     arr = [res.restaurant_owner].concat(res.restaurant_users)
                // } else {
                arr = res.restaurant_users;
                // }
                setUserList(arr)
            }
        }))
    }, [dispatch, openModal])

    const columns = COLUMNS
    const data = userList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }

    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }

    const handleDelete = (row) => {
        const id = row.original.id
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover user!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {

            if (willDelete) {

                swal("User has been deleted!", {
                    icon: "success",
                });
                dispatch(DeleteUserApi({ id }, (result) => {
                    dispatch(getUserListApi((res) => {
                        if (res && res.restaurant_users) {
                            let arr;
                            // if (res.restaurant_owner) {
                            //     res.restaurant_owner['isOwner'] = true
                            //     arr = [res.restaurant_owner].concat(res.restaurant_users)
                            // } else {
                            arr = res.restaurant_users;
                            // }
                            setUserList(arr)
                        }
                    }))
                }))
            }
        })
    }
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('userscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {((permissions && permissions.user && permissions.user.add) || permissions == null || !permissions.user) && <Button onClick={handleAddOpen}>{t('buttons.adduser')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>

                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                                })}
                                                {!row.original.isOwner && <td className='text-end'>
                                                    {((permissions && permissions.user && permissions.user.view) || permissions == null || !permissions.user) && <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>}
                                                    {((permissions && permissions.user && permissions.user.edit) || permissions == null || !permissions.user) && <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>}
                                                    {((permissions && permissions.user && permissions.user.delete) || permissions == null || !permissions.user) && <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>}
                                                </td>}

                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}

                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div>
            {openModal && <AddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} setUserList={setUserList} />}
            {openViewModal && <ViewUserModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
        </>
    )

}
export default User;