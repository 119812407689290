import { FormControl, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditModifierTypeApi } from '../../../../services/modifierservices';


const ModifierTypeAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData } = props

    const onsubmit = (values) => {
        if (editData && editData.ID) {
            values.ProductTypeId = editData.ID
        }
        dispatch(addEditModifierTypeApi(values, (res) => {
            setOpenModal(false);
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()

    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    ProductTypeName: editData ? editData.Type : '',
                }}

                validationSchema={
                    Yup.object().shape({
                        ProductTypeName: Yup.string().required("modifier Type Name is required."),
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('modifiertypescreen.updatemodifiertype') : t('modifiertypescreen.addmodifiertype')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.ProductTypeName) && touched.ProductTypeName}
                                                helperText={(Boolean(errors.ProductTypeName) && touched.ProductTypeName) ? errors.ProductTypeName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="ProductTypeName"
                                                label={t('modifiertypescreen.modifiertype')}
                                                value={values.ProductTypeName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default ModifierTypeAddEditModal
