import React from 'react'
import LiveReporting from './LiveReporting';
import { useTranslation } from 'react-i18next';
import Performance from './Performance';
import { Nav, Tab } from 'react-bootstrap';

const Reporting = () => {
    const { t } = useTranslation('common')

    return (
        <div className="default-tab">
            <Tab.Container defaultActiveKey={1}>
                <Nav as="ul" className="nav-tabs">
                    <Nav.Item>
                        <Nav.Link eventKey={1} className='px-5 py-3'>
                            {t('reporting.livereporting')}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={2} className='px-5 py-3'>
                            {t('reporting.performance')}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                    <Tab.Pane eventKey={1} >
                        <LiveReporting />
                    </Tab.Pane>
                    <Tab.Pane eventKey={2} >
                        <Performance />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default Reporting