

export const NO_DATA = 'no_data';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const GET_LOCALIZATION_SETTINGS = "GET_LOCALIZATION_SETTINGS"
export const GET_ORDER_COUNT = "GET_ORDER_COUNT"
export const GET_THEME = "GET_THEME"