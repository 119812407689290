import { FormControl, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditmodifierGroupApi } from '../../../../services/modifierservices';


const ModifierGroupAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData } = props

    const onsubmit = (values) => {
        if (editData && editData.ID) {
            values.ModifierGroupId = editData.ID
        }
        dispatch(addEditmodifierGroupApi(values, (res) => {
            setOpenModal(false);
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    ModifierGroupName: editData ? editData.Name : '',
                    NameDisplayOnTill: editData ? editData.Name_Display_On_Till : '',
                    ModifierGroupType: editData ? editData.Type : 1,
                    Description: editData ? editData.Description : '',
                }}

                validationSchema={
                    Yup.object().shape({
                        ModifierGroupName: Yup.string().required("modifier Group Name is required."),
                        NameDisplayOnTill: Yup.string().required("Name Display On Till is required."),
                        ModifierGroupType: Yup.string().required("Type is required."),
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('modifiergroupscreen.updatemodifiergroup') : t('modifiergroupscreen.addmodifiergroup')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.ModifierGroupName) && touched.ModifierGroupName}
                                                helperText={(Boolean(errors.ModifierGroupName) && touched.ModifierGroupName) ? errors.ModifierGroupName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="ModifierGroupName"
                                                label={t('modifiergroupscreen.name')}
                                                value={values.ModifierGroupName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.NameDisplayOnTill) && touched.NameDisplayOnTill}
                                                helperText={(Boolean(errors.NameDisplayOnTill) && touched.NameDisplayOnTill) ? errors.NameDisplayOnTill : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="NameDisplayOnTill"
                                                label={t('modifiergroupscreen.displaynameontill')}
                                                value={values.NameDisplayOnTill}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="demo-simple-select"
                                                    value={values.ModifierGroupType}
                                                    label={t('modifiergroupscreen.type')}
                                                    name='ModifierGroupType'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.ModifierGroupType) && touched.ModifierGroupType}
                                                    helperText={Boolean(errors.ModifierGroupType) && touched.ModifierGroupType ? errors.ModifierGroupType : ""}
                                                >
                                                    <MenuItem value='1'>Free</MenuItem>
                                                    <MenuItem value='2'>Paid</MenuItem>
                                                    <MenuItem value='3'>FreePaid</MenuItem>

                                                </TextField>

                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                multiline
                                                rows={4}
                                                error={Boolean(errors.Description) && touched.Description}
                                                helperText={(Boolean(errors.Description) && touched.Description) ? errors.Description : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Description"
                                                label={t('modifiergroupscreen.description')}
                                                value={values.Description}
                                                onChange={handleChange}
                                            />
                                        </div>


                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default ModifierGroupAddEditModal
