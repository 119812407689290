import React, { useState, useEffect } from 'react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { FormControl, FormHelperText, InputLabel, MenuItem, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import { Modal, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import Select from '@mui/material/Select';
import moment from 'moment';
import { addEditExceptions } from '../../../../services/TimeConfigServices';
import { useDispatch } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { DatePicker } from "antd";
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const AddSpecialDayHoliday = (props) => {
  const { isEditException, exceptionData, setExceptionData, setModalShow, modalShow } = props;
  const { t } = useTranslation('common');
  const dispatch = useDispatch()


  const handleSubmit = (values) => {
    let sendExceptionData = {
      "id": exceptionData && exceptionData.id ? exceptionData.id : null,
      "exception_title": values.name,
      "service_type": values.services,
      "start_date": moment(new Date(values.startDate)).format('YYYY-MM-DD'),
      "end_date": moment(new Date(values.endDate)).format('YYYY-MM-DD'),
      "description": values.notification ? values.notification : null
    }

    dispatch(addEditExceptions(sendExceptionData, (res) => {
      setExceptionData({});
      setModalShow(false)
      props.setIsFormExpanded(false);
    }))
  };

  const handleModalClose = () => {
    setExceptionData({});
    setModalShow(false)
  };

  return (
    <Modal
      className="fade bd-example-modal-lg "
      show={modalShow}
      // onHide={handleModalClose}
      size="md"
      centered
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: exceptionData ? exceptionData.exception_title : '',
          services: exceptionData && exceptionData.service_type ? exceptionData.service_type : '',
          startDate: exceptionData && exceptionData.start_date ? dayjs(exceptionData.start_date) : null,
          endDate: exceptionData && exceptionData.end_date ? dayjs(exceptionData.end_date) : null,
          notification: exceptionData ? exceptionData.description : ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required."),
          services: Yup.string().required('Services is required.'),
          startDate: Yup.date()
            .nullable()
            .required('Start Date is required.'),
          endDate: Yup.date()
            .nullable()
            .required('End Date is required.')

        })}
        // date:Yup.string().required('Date is required.')

        onSubmit={handleSubmit}
      >
        {(props) => {
          const { values, touched, errors, setFieldValue, handleBlur, handleChange, handleSubmit } = props;
          // console.log(errors, values)
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>
                  {t('timeconfig.addspecialdayholiday')}
                </Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={handleModalClose}
                >
                </Button>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <strong>{t('timeconfig.name')}*</strong>
                    <TextField
                      required
                      fullWidth
                      error={Boolean(errors.name) && touched.name}
                      helperText={(Boolean(errors.name) && touched.name) ? errors.name : ''}
                      onBlur={handleBlur}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3'>
                    <strong>{t('timeconfig.selectdateorperiod')}*</strong>
                    <FormControl fullWidth>
                      <RangePicker
                        className={"createDateRangePicker"}
                        dropdownClassName={"createDateRangePicker"}
                        value={[values.startDate, values.endDate]}
                        name='date'
                        onChange={(dates) => {
                          setFieldValue('startDate', dates[0]);
                          setFieldValue('endDate', dates[1])
                        }}
                        format="YYYY-MM-DD"
                      />
                      {/* {errors.startDate && touched.startDate && (
                        <p className="mui-error text-danger">{errors.startDate}</p>
                      )}
                      {errors.endDate && touched.endDate && (
                        <p className="mui-error text-danger">{errors.endDate}</p>
                      )} */}
                      {!values.startDate && !values.endDate && errors.endDate && touched.endDate && errors.startDate && touched.startDate &&(
                        <p className="mui-error text-danger ms-3">
                          {/* Please select date  */}
                           {t('timeconfig.dateisrequired')}
                        </p>
                      )}
                   </FormControl>
                  </div>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3'>
                    <strong>{t('timeconfig.affectedservices')}*</strong>
                    <div>
                      <FormControl fullWidth>
                        <Select sx={{ minWidth: 355 }}
                          required
                          value={values.services}
                          onChange={handleChange}
                          name='services'
                          error={Boolean(errors.services) && touched.services}
                        >
                          <MenuItem value=''>{t('timeconfig.selectservice')}</MenuItem>
                          <MenuItem value='openclosetime'>{t('timeconfig.openclosetime')}</MenuItem>
                          <MenuItem value='collection'>{t('timeconfig.collectionservice')}</MenuItem>
                          <MenuItem value='delivery'>{t('timeconfig.deliveryservice')}</MenuItem>
                        </Select>
                        <FormHelperText className="Mui-error">{Boolean(errors.services) || touched.services ? errors.services : ""}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3 mb-3'>
                    <strong>{t('timeconfig.notificationmessage')}</strong>({t('timeconfig.optional')})
                    <TextArea minRows={3}
                      name="notification"
                      value={values.notification}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="danger light"
                  onClick={handleModalClose}
                >
                  {t('buttons.cancel')}
                </Button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  size="large"
                // disabled={!isValid || isDisabled}
                >
                  {t('buttons.save')}
                </button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddSpecialDayHoliday;
