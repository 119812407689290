import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import * as Yup from 'yup';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ModifiergroupItemAddEditApi, ModifiergroupItemDeleteApi, getModifieritemListApi } from '../../../../services/modifierservices';
import swal from 'sweetalert';
import { symbolsArr } from '../../../../constants/constants';

const ModifierGroupDetailsForm = (props) => {
    const dispatch = useDispatch()
    const { openDetailsModal, setOpenDetailsModal, modifierGroupDetails, modifierTypeList, setmodifierTypeList, viewData, initialvalue } = props;
    const { t } = useTranslation('common');
    const [modifierItemList, setmodifierItemList] = useState([]);

    useEffect(() => {
        dispatch(getModifieritemListApi((res) => {
            setmodifierItemList(res.data)
            if (modifierGroupDetails && modifierGroupDetails.Items && modifierGroupDetails.Items.length > 0) {
                modifierGroupDetails.Items.map((item) => {
                    item.modifierlist = res.data.filter((data) => data.ProductTypeId == item.ProductTypeId)
                })
            }
        }))
    }, [])

    const modifierItemHandleList = () => {
        dispatch(getModifieritemListApi((res) => {
            setmodifierItemList(res.data)
        }))
    }

    const handleModalClose = () => {
        setOpenDetailsModal(false)
    }

    const onsubmit = (values) => {
        values = values.modifierDetails.filter((item) => (item.ProductTypeId != '' && item.ItemId != ''))

        let tempValue = JSON.parse(JSON.stringify(values))
        tempValue.length > 0 && tempValue.map((item) => {
            delete item.modifierlist
            item.ModifierGroupId = modifierGroupDetails.ID
        })
        dispatch(ModifiergroupItemAddEditApi({ ModifierGroupDetail: tempValue }, (res) => {
            setOpenDetailsModal(false)
        }))
    }

    const handleDelete = (id, arrayHelpers, index) => {
        if (id) {
            swal({
                title: "Are you sure?",
                text:
                    "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: {
                    confirm: 'Ok',
                    cancel: 'Cancel'
                },
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    dispatch(ModifiergroupItemDeleteApi({ ID: id }, (res) => {
                        modifierGroupDetails.Items.splice(index, 1)
                    }))
                    swal("Modifier Group Item has been deleted!", {
                        icon: "success",
                    });
                }
            })
        } else {
            arrayHelpers.remove(index)
        }
    }


    const validationSchema = Yup.object().shape({
        modifierDetails: Yup.array().of(
            Yup.object().shape({
                Description: Yup.string().when(['ProductTypeId', 'ItemId'], {
                    is: (ProductTypeId, ItemId) => ProductTypeId && ItemId,
                    then: Yup.string()
                        .required('Please Enter Description'),
                    otherwise: Yup.string(),
                }),
                DisplayOrder: Yup.string().when(['ProductTypeId', 'ItemId'], {
                    is: (ProductTypeId, ItemId) => ProductTypeId && ItemId,
                    then: Yup.string()
                        .required('Please Enter Display Order'),
                    otherwise: Yup.string(),
                })
            })
        )
    });

    return (
        <Formik
            validateOnChange={false}
            enableReinitialize
            initialValues={{
                modifierDetails: modifierGroupDetails && modifierGroupDetails.Items && modifierGroupDetails.Items.length > 0 ? modifierGroupDetails.Items :
                    initialvalue
            }}
            validationSchema={validationSchema}
            onSubmit={onsubmit}
        >
            {(props) => {
                const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                return (
                    <>

                        <Form onSubmit={handleSubmit} noValidate>
                            <FieldArray
                                name="modifierDetails"
                                render={arrayHelpers => (
                                    <>
                                        {!viewData && <Modal.Header>
                                            <Modal.Title>{t('modifiergroupscreen.details', { name: modifierGroupDetails.Name })}</Modal.Title>
                                            <Button
                                                onClick={() => arrayHelpers.push({
                                                    ProductTypeId: "",
                                                    ItemId: '',
                                                    Description: '',
                                                    DisplayOrder: '',
                                                    IsActive: false,
                                                    modifierlist: []
                                                })}
                                            >
                                                {t('buttons.addmodifier')}
                                            </Button>
                                        </Modal.Header>}

                                        <Modal.Body className={`${viewData && 'p-0 m-0'}`}>
                                            <div className="table-responsive">

                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">{t('modifiergroupscreen.modifierType')}</th>
                                                            <th scope="col">{t('modifiergroupscreen.modifier')}</th>
                                                            <th scope="col">{t('modifiergroupscreen.description')}</th>
                                                            <th scope="col">{t('modifiergroupscreen.displayorder')}</th>
                                                            <th scope="col">{t('modifiergroupscreen.available')}</th>
                                                            {!viewData && <th scope="col">{t('table.action')}</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values.modifierDetails && values.modifierDetails.length > 0 && (
                                                            values.modifierDetails.map((item, index) => {
                                                                const Description = `modifierDetails.${index}.Description`;
                                                                const touchedDescription = getIn(touched, Description);
                                                                const errorDescription = getIn(errors, Description);

                                                                const DisplayOrder = `modifierDetails.${index}.DisplayOrder`;
                                                                const touchedDisplayOrder = getIn(touched, DisplayOrder);
                                                                const errorDisplayOrder = getIn(errors, DisplayOrder);

                                                                if (item.ProductTypeId && !item.Flag) {
                                                                    item.modifierlist = modifierItemList.filter((res) => res.ProductTypeId == item.ProductTypeId)
                                                                }
                                                                return <tr key={index}>
                                                                    <td className='col-md-3'>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                disabled={viewData}
                                                                                autoFocus
                                                                                select
                                                                                fullWidth
                                                                                required
                                                                                inputProps={{ readOnly: item.Flag }}
                                                                                value={item.ProductTypeId}
                                                                                label={t('modifiergroupscreen.modifierType')}
                                                                                name={`modifierDetails.${index}.ProductTypeId`}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, index)
                                                                                    if (e.target.value) {
                                                                                        modifierItemHandleList()
                                                                                    }
                                                                                }}>
                                                                                <MenuItem disabled value=''>select modifier type</MenuItem>
                                                                                {modifierTypeList.length > 0 && modifierTypeList.map((items, index1) => {
                                                                                    return <MenuItem key={index1} value={items.ID}>{items.Type}</MenuItem>
                                                                                })}
                                                                            </TextField>

                                                                        </FormControl>
                                                                    </td>
                                                                    <td className='col-md-2'>
                                                                        {<FormControl fullWidth>
                                                                            <TextField
                                                                                select
                                                                                disabled={viewData}
                                                                                fullWidth
                                                                                required
                                                                                inputProps={{ readOnly: item.Flag }}
                                                                                value={item.ItemId}
                                                                                label={t('modifiergroupscreen.modifier')}
                                                                                name={`modifierDetails.${index}.ItemId`}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                            >
                                                                                <MenuItem disabled value=''>select modifier</MenuItem>
                                                                                {item.modifierlist && item.modifierlist.length > 0 && item.modifierlist.map((item, index2) => {
                                                                                    return <MenuItem key={index2} value={item.ID}>{item.ItemName}</MenuItem>
                                                                                })}
                                                                            </TextField>
                                                                        </FormControl>}
                                                                    </td>
                                                                    <td>
                                                                        <TextField
                                                                            disabled={viewData}
                                                                            // required={item.Flag ? false : true}
                                                                            helperText={
                                                                                Boolean(touchedDescription && errorDescription)
                                                                                    ? errorDescription
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedDescription && errorDescription)}
                                                                            fullWidth
                                                                            name={`modifierDetails.${index}.Description`}
                                                                            label={t('modifiergroupscreen.description')}
                                                                            value={item.Description}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <TextField
                                                                            disabled={viewData}
                                                                            // required={item.Flag ? false : true}
                                                                            helperText={
                                                                                Boolean(touchedDisplayOrder && errorDisplayOrder)
                                                                                    ? errorDisplayOrder
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedDisplayOrder && errorDisplayOrder)}
                                                                            fullWidth
                                                                            type={'number'}
                                                                            InputProps={{ inputProps: { min: 0 } }}
                                                                            onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                            name={`modifierDetails.${index}.DisplayOrder`}
                                                                            label={t('modifiergroupscreen.displayorder')}
                                                                            value={item.DisplayOrder}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                        />
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        <FormControlLabel
                                                                            disabled={viewData}
                                                                            control={<Checkbox checked={item.IsActive == '1'} />}
                                                                            name={`modifierDetails.${index}.IsActive`}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                        />
                                                                    </td>
                                                                    {!viewData && <td>
                                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                                            onClick={() => { handleDelete(item.ID, arrayHelpers, index) }}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                    </td>}
                                                                </tr>
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Modal.Body>
                                        {!viewData && <Modal.Footer>
                                            <Button
                                                variant="danger light"
                                                onClick={handleModalClose}
                                            >
                                                {t('buttons.cancel')}
                                            </Button>
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                                size="large"
                                            // onClick={handleSubmit}
                                            >
                                                {t('buttons.save')}
                                            </button>
                                        </Modal.Footer>}
                                    </>
                                )}
                            />
                        </Form >

                    </>
                )
            }}
        </Formik>
    )
}

export default ModifierGroupDetailsForm
