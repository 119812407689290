import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { getOrderDetailsApi } from '../../../../services/orderService'
import { useDispatch } from 'react-redux'
import { CustomBadge } from './OrderHistory'
import moment from 'moment'
import { Empty } from 'antd'

const Invoice = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation('common')
    const [orderDetails, setOrderDetails] = useState();
    const [error, setError] = useState(false)

    useEffect(() => {
        try {
            let orderId = atob(location.pathname.split('/').pop())
            const obj = { "OrderId": orderId, "UserId": "0" }
            dispatch(getOrderDetailsApi(obj, (res) => {
                if (res.status == 1) {
                    setError(false)
                    setOrderDetails(res.data)
                } else {
                    setError(true)
                }
            }))
        } catch (error) {
            setError(true)
            console.log(error)
        }

    }, [])
    const navigateBack = () => {
        navigate('/orderhistory')
    }

    return (
        <div className="card" >
            <div className="card-header">
                {/* <h4 className="card-title">{t('invoice.title')}</h4> */}
                <Button
                    variant="danger light"
                    onClick={navigateBack}
                >
                    <i className="fa-solid fa-arrow-left"></i>
                    &nbsp;
                    {t('buttons.back')}
                </Button>
            </div>
            <div className="card-body" >
                {!error ? <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="mt-4 col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                <div>
                                    <strong>{orderDetails?.RestaurantName}</strong>{" "}
                                </div>
                                <p>{orderDetails?.RestaurantAddress}</p>
                                <div className='d-flex'>
                                    <div>
                                        {orderDetails?.OrderType == '1' ?
                                            <p className='m-0'>
                                                <i className="fa-solid fa-motorcycle"></i>&nbsp;  {t('orderhistory.delivery')}
                                            </p>
                                            :
                                            <p className='m-0'>
                                                <i className="fa-solid fa-bag-shopping"></i> &nbsp; {t('orderhistory.collection')}
                                            </p>
                                        }
                                    </div>
                                    <div className='ps-4'>
                                        {orderDetails?.DeliveryMode == '1' ? <p className='m-0'> <i className="fa-solid fa-sack-dollar"></i>  &nbsp; {t('orderhistory.cash')}</p> : <p className='m-0'><i className="fa-solid fa-credit-card"></i>&nbsp; {t('orderhistory.card')}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 col-xl-6 col-lg-12 col-md-12 col-sm-12 text-end">
                                {orderDetails && <CustomBadge item={orderDetails} />}
                                <h4>
                                    <strong className="d-block">#{orderDetails?.TransactionId}</strong>
                                </h4>
                                <p className='m-0'>
                                    <strong>{t('invoice.orderplaced')}&nbsp;{moment(orderDetails?.DateOfOrder).format('HH:mm on DD MMM YYYY')}</strong>
                                </p>
                            </div>
                        </div>
                        <hr className='mt-1 mb-1' />
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="center">#</th>
                                        <th>{t('ordermasterscreen.item')}</th>
                                        <th className="right">{t('ordermasterscreen.price')}</th>
                                        <th className="center">{t('ordermasterscreen.qty')}</th>
                                        <th className="right text-end">{t('ordermasterscreen.subtotal')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetails && orderDetails.OrderDetail.length > 0 &&
                                        orderDetails.OrderDetail.map((item, index) => {
                                            return <tr key={index}>
                                                <th>{index + 1}</th>
                                                <td>{item.ItemName} <br /> <span className='toppingList invoiceToppings'> {item.ToppingNames != '' ? '(' + item.ToppingNames + ')' : ''}</span></td>
                                                <td>
                                                    {t("currency", { Number: parseFloat((parseFloat(item.Price) / parseFloat(item.Quantity)) + parseFloat(item.ToppingPrice)) })}
                                                    <br />
                                                    {item.Promotion_Name != '' && <strike className='text-red'>
                                                        {t("currency", { Number: parseFloat(parseFloat(item.SubTotalBeforePromotion) + parseFloat(item.ToppingSubTotalBeforePromotion)) })}
                                                    </strike>}
                                                </td>
                                                <td>{item.Quantity}</td>
                                                <td className=" text-end">
                                                    {t("currency", { Number: parseFloat(parseFloat(item.ToppingSubTotal) + parseFloat(item.SubTotal)) })}
                                                    <br />
                                                    {item.Promotion_Name != '' &&
                                                        <>
                                                            <strike className='text-red'>
                                                                {t("currency", { Number: parseFloat((parseFloat(item.SubTotalBeforePromotion) + parseFloat(item.ToppingSubTotalBeforePromotion)) * parseFloat(item.Quantity)) })}
                                                            </strike>
                                                            <br />
                                                            <span className='text-green'>{item.Promotion_Name}</span>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-5"> </div>
                            <div className="col-lg-4 col-sm-5 ms-auto">
                                <table className="table table-clear">
                                    <tbody>
                                        <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.sub_total')}</strong>
                                            </td>
                                            <td className="text-end">
                                                {orderDetails?.OrderTotal?.SubTotalBeforePromotion != orderDetails?.OrderTotal?.SubTotalWithoutDeduction &&
                                                    <>
                                                        <strike className='text-red'>
                                                            {t("currency", { Number: parseFloat(orderDetails?.OrderTotal?.SubTotalBeforePromotion) })}
                                                        </strike>
                                                    </>
                                                }
                                                &nbsp;
                                                {t("currency", { Number: parseFloat(orderDetails?.OrderTotal?.SubTotalWithoutDeduction) })}
                                            </td>
                                        </tr>
                                        {orderDetails?.OrderTotal?.SmallOrderFee > 0 && <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.smallorderfee')}</strong>
                                            </td>
                                            <td className="text-end">{t("currency", { Number: parseFloat(orderDetails?.OrderTotal?.SmallOrderFee) })}</td>
                                        </tr>}
                                        {orderDetails?.OrderTotal?.ShippingCharge > 0 && <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.deliverycharges')}</strong>
                                            </td>
                                            <td className="text-end">{t("currency", { Number: parseFloat(orderDetails?.OrderTotal?.ShippingCharge) })}</td>
                                        </tr>}
                                        {orderDetails?.OrderTotal?.ServiceCharge > 0 && <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.servicecharges')}</strong>
                                            </td>
                                            <td className="text-end">{t("currency", { Number: parseFloat(orderDetails?.OrderTotal?.ServiceCharge) })}</td>
                                        </tr>}
                                        {orderDetails?.OrderTotal?.DriverTip > 0 && <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.deliverypartnertip')}</strong>
                                            </td>
                                            <td className="text-end">{t("currency", { Number: parseFloat(orderDetails?.OrderTotal?.DriverTip) })}</td>
                                        </tr>}
                                        {orderDetails?.OrderTotal?.SubTotalBeforePromotion != orderDetails?.OrderTotal?.SubTotalWithoutDeduction && <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.restaurantpromo')}</strong>
                                            </td>
                                            <td className="text-end">-{t("currency", { Number: parseFloat(parseFloat(orderDetails?.OrderTotal?.SubTotalWithoutDeduction) - parseFloat(orderDetails?.OrderTotal?.SubTotalBeforePromotion)) })}</td>
                                        </tr>}
                                        {orderDetails?.OrderTotal?.PromoDeductAmount > 0 && <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.promodiscount')}</strong>
                                            </td>
                                            <td className="text-end">-{t("currency", { Number: parseFloat(parseFloat(orderDetails?.OrderTotal?.PromoDeductAmount)) })}</td>
                                        </tr>}
                                        {orderDetails?.OrderTotal?.VoucherDeductAmount > 0 && <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.voucherdiscount')}</strong>
                                            </td>
                                            <td className="text-end">-{t("currency", { Number: parseFloat(parseFloat(orderDetails?.OrderTotal?.VoucherDeductAmount) - parseFloat(orderDetails?.OrderTotal?.SubTotalWithoutDeduction)) })}</td>
                                        </tr>}
                                        <tr>
                                            <td className="left">
                                                <strong>{t('ordermasterscreen.payableamount')}</strong>
                                            </td>
                                            <td className="text-end">
                                                <strong>{t("currency", { Number: parseFloat(orderDetails?.OrderTotal?.GrandTotal) })}</strong>
                                                <br />
                                                <strong className='text-green'>({orderDetails?.OrderTotal?.PaymentModeName})</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr className="mt-1 mb-1" />
                        <div className="row pt-4">
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <h4>{t('invoice.additionalinformation')}</h4>
                                <div className="card">
                                    <div className="card-body d-flex align-items-center pt-1 pb-1">
                                        <p className='m-0'>
                                            {orderDetails?.Note != '' ? orderDetails?.Note : t('invoice.nonoteforthisorder')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h4>{t('invoice.deliveryaddress')}</h4>
                                <div className="card">
                                    <div className="card-body d-flex align-items-center pt-1 pb-1">
                                        <p className='m-0'>
                                            {orderDetails?.Customer?.FullAddress}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                    <h3><Empty /></h3>
                }
            </div>
        </div>
    )
}

export default Invoice
