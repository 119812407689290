import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { chartColors } from "../../../constants/constants";
import { useTranslation } from "react-i18next";
//import ApexCharts from 'apexcharts';
//import { Link } from "react-router-dom";

const RevenuebyorderPieChart = (props) => {
  const { t } = useTranslation('common')
  const { pieChartData, revenueData } = props;
  const [seriesData, setSeriesData] = useState([])
  const [Names, setNames] = useState([])

  useEffect(() => {
    if (Object.keys(pieChartData).length > 0) {
      let name = [];
      let value = [];
      for (let i = 0; i < Object.keys(pieChartData).length; i++) {
        const element = Object.keys(pieChartData)[i];
        name.push(element)
        value.push(parseFloat(pieChartData[element]))
      }
      setSeriesData(value)
      setNames(name)
    }
  }, [pieChartData])

  return (
    <div id="piechart" className="revenuePiechart">
      <ReactApexChart options={{
        chart: {
          //id: 'assetDistribution',
          type: "donut",
          offsetY: 0,
          height: 250,

        },
        labels: Names,
        dataLabels: {
          enabled: false
        },
        legend: {

          show: false
        },
        stroke: {

          width: 0,
        },
        fill: {
          colors: chartColors,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 0,
                },
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: '22px',
                  fontWeight: 600,
                  label: `${t("currency", { Number: parseFloat(revenueData && revenueData.total_revanue_amount ? revenueData.total_revanue_amount : 0) })}`,
                  color: '#0065bd',
                  formatter: function (w) {
                    return 'Total';
                  }
                }
              }
            }
          }
        },
        responsive: [{
          breakpoint: 601,
          options: {
            chart: {
              width: 200,
              height: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        },
        {
          breakpoint: 361,
          options: {
            chart: {
              width: 270,
              height: 200

            },
            legend: {
              position: 'bottom'
            }
          }

        }],
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const label = w.globals.labels[seriesIndex];
            const color = chartColors[seriesIndex]
            let name = t(`reporting.${label}`);
            const value = t("currency", { Number: parseFloat(series[seriesIndex] || 0) });
            return (
              '<div class="custom-tooltip" style="background-color: ' + color + '">' +
              '<span>' + name + ': ' + value + '</span>' +
              '</div>'
            );
          },
        },

      }} series={seriesData} type="donut" height={250} />
    </div>
  );
}

export default RevenuebyorderPieChart;