import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, TextField } from '@mui/material'
import { FieldArray, Form, Formik, getIn } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ImageInput from '../../../../helpers/fileUploader/ImageInput';
import { useState } from 'react';
import { useEffect } from 'react';
import { getModdifierGroupListApi } from '../../../../services/modifierservices';
import swal from 'sweetalert';
import { DeletemodifierGroupProductApi, addEditItemApi, getmodifierGroupProductistApi } from '../../../../services/foodmenuServices';
import { S3ImageUpload, symbolsArr } from '../../../../constants/constants';
import { S3ImageDelete, S3Uploads } from '../../../../helpers/S3Upload/S3upload';


const FoodMenuAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData, options } = props
    let userDetails = JSON.parse(localStorage.getItem('userDetails'))
    let restaurantId = userDetails.restaurant_id
    const [imageVal, setImageVal] = useState({
        itemImage: editData?.Image ? editData?.Image : '',
        itemImageSrc: editData?.Image ? `${S3ImageUpload}${restaurantId}/product/${editData?.Image}` : '',
    })

    const [deleteImagePath, setDeleteImagePath] = useState('')
    const [modifierGroupList, setmodifierGroupList] = useState([]);
    const CurrencySymbol = localStorage.getItem('currencySymbol')
    const [modifierGroupProductList, setmodifierGroupProductList] = useState([]);

    const initialvalue = {
        ModifierGroupId: '',
        DisplayName: '',
        Description: '',
        DisplayOrder: '',
        MinSelection: '',
        MaxSelection: '',
        IsActive: false,
    }

    useEffect(() => {
        dispatch(getModdifierGroupListApi((res) => {
            setmodifierGroupList(res.data)
        }))
    }, []);

    useEffect(() => {
        if (editData) {
            const ID = editData.ID
            dispatch(getmodifierGroupProductistApi({ ID }, (res) => {
                setmodifierGroupProductList(res.data)
            }))
        }
    }, []);

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }

    const handleChangeForImage = (e) => {
        if (editData?.Image == imageVal.itemImage) {
            const path = `${restaurantId}/product/${imageVal.itemImage}`
            setDeleteImagePath(path)
        }
        setImageVal(prevState => ({
            ...prevState,
            itemImage: e.photo.data,
            itemImageSrc: e.photo.src
        }))
    }


    const handleDelete = (id, arrayHelpers, index) => {
        if (id) {
            swal({
                title: "Are you sure?",
                text:
                    "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: {
                    confirm: 'Ok',
                    cancel: 'Cancel'
                },
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    swal("deleted!", {
                        icon: "success",
                    });
                    dispatch(DeletemodifierGroupProductApi({ ID: id }, (res) => {
                        modifierGroupProductList.splice(index, 1)
                    }))
                }
            })
        } else {
            arrayHelpers.remove(index)
        }
    }

    const onsubmit = (values) => {
        let currentDate = Date.now()
        if (deleteImagePath != '') {
            S3ImageDelete(deleteImagePath)
        }
        if (editData) {
            values.ItemId = editData.ID
        }
        if (typeof imageVal.itemImage == 'object') {
            const fileNameForItem = currentDate + '_' + imageVal.itemImage.name.replace(/ /g, "_")
            values.menuItemImage = fileNameForItem
        } else {
            values.menuItemImage = imageVal.itemImage
        }
        dispatch(addEditItemApi(values, (res) => {
            let id = editData && editData.ID ? editData.ID : res.data
            if (typeof imageVal.itemImage == 'object') {
                const fileNameForItem = currentDate + '_' + imageVal.itemImage.name.replace(/ /g, "_")
                const filePathForItem = `${restaurantId}/product/${fileNameForItem}`
                S3Uploads(imageVal.itemImage, filePathForItem, fileNameForItem)
            }
            handleModalClose()
        }))

    }

    const validationSchema = Yup.object().shape({
        ItemName: Yup.string().required("Name required"),
        MenuCategory: Yup.string().required("The Category Name field is required."),
        Price: Yup.number().min(0, "Must be positive"),
        SupplierPrice: Yup.number().min(0, "Must be positive"),
        FoodMenuDetails: Yup.array().of(
            Yup.object().shape({
                MinSelection: Yup.string().when(['ModifierGroupId'], {
                    is: (ModifierGroupId) => ModifierGroupId,
                    then: Yup.string()
                        .required('Please Enter Min Selection'),
                    otherwise: Yup.string(),
                }),
                MaxSelection: Yup.string().when(['ModifierGroupId'], {
                    is: (ModifierGroupId) => ModifierGroupId,
                    then: Yup.string()
                        .required('Please Enter Max Selection'),
                    otherwise: Yup.string(),
                }),
            })
        )
    });


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="xl"
            centered
        >

            <Formik
                enableReinitialize
                validateOnChange={false}
                initialValues={{
                    ItemName: editData ? editData.ItemName : '',
                    MenuCategory: editData && options.length > 0 ? editData.CategoryId : '',
                    Price: editData ? editData.Price : '',
                    SupplierPrice: editData ? editData.SupplierPrice : '',
                    ShortDescription: editData ? editData.SortDescription : '',
                    LongDescription: editData ? editData.LongDescription : '',
                    item_calories: editData ? editData.item_calories : '',
                    // DiscoverNew: editData ? (editData.New == '1' ? true : false) : true,
                    Popular: editData ? (editData.IsMostPopular == '1' ? true : false) : true,
                    IsActive: editData ? (editData.IsActive == '1' ? true : false) : true,
                    IsNew: editData ? (editData.IsNew == '1' ? true : false) : true,
                    IsVeg: editData ? (editData.IsVeg == '1' ? true : false) : true,
                    IsContainingEgg: editData ? (editData.IsContainingEgg == '1' ? true : false) : true,
                    IsHalal: editData ? (editData.IsHalal == '1' ? true : false) : true,
                    IsVegan: editData ? (editData.IsVegan == '1' ? true : false) : true,
                    FoodMenuDetails: modifierGroupProductList && modifierGroupProductList.length > 0 ? modifierGroupProductList : [initialvalue]
                }}

                validationSchema={validationSchema}

                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('foodmenuscreen.updatefoodmenu') : t('foodmenuscreen.addfoodmenu')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.ItemName) && touched.ItemName}
                                                helperText={(Boolean(errors.ItemName) && touched.ItemName) ? errors.ItemName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="ItemName"
                                                label={t('foodmenuscreen.itemName')}
                                                value={values.ItemName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="demo-simple-select"
                                                    value={values.MenuCategory}
                                                    label={t('foodmenuscreen.menucategory')}
                                                    name='MenuCategory'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.MenuCategory) && touched.MenuCategory}
                                                    helperText={Boolean(errors.MenuCategory) && touched.MenuCategory ? errors.MenuCategory : ""}
                                                >
                                                    {options && options.length > 0 ? options.map((item, index) => {
                                                        let count = (item.CategoryName.match(/ —/g) || []).length;
                                                        if (count > 1) {
                                                            return <MenuItem disabled value={item.ID} key={index}>{item.CategoryName}</MenuItem>
                                                        } else {
                                                            return <MenuItem value={item.ID} key={index}>{item.CategoryName}</MenuItem>
                                                        }
                                                    }) : []}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                type='number'
                                                fullWidth
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onBlur={handleBlur}
                                                name="Price"
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                label={`${t('foodmenuscreen.price')} (${editData && editData.CurrencySymbol ? editData.CurrencySymbol : CurrencySymbol})`}
                                                value={values.Price}
                                                onChange={handleChange}
                                                error={Boolean(errors.Price) && touched.Price}
                                                helperText={(Boolean(errors.Price) && touched.Price) ? errors.Price : ''}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                type='number'
                                                fullWidth
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onBlur={handleBlur}
                                                name="SupplierPrice"
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                label={`${t('foodmenuscreen.supplierprice')} (${editData && editData.CurrencySymbol ? editData.CurrencySymbol : CurrencySymbol})`}
                                                value={values.SupplierPrice}
                                                onChange={handleChange}
                                                error={Boolean(errors.SupplierPrice) && touched.SupplierPrice}
                                                helperText={(Boolean(errors.SupplierPrice) && touched.SupplierPrice) ? errors.SupplierPrice : ''}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                multiline
                                                rows={4}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="ShortDescription"
                                                label={t('foodmenuscreen.shortdescription')}
                                                value={values.ShortDescription}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                multiline
                                                rows={4}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="LongDescription"
                                                label={t('foodmenuscreen.longdescription')}
                                                value={values.LongDescription}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <div className="row">
                                                <div className="col-6">
                                                    <TextField
                                                        type="text"
                                                        name="item_calories"
                                                        InputProps={{
                                                            inputProps: {
                                                                inputMode: 'numeric',
                                                            },
                                                            onInput: (e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '');
                                                            },
                                                        }}
                                                        required
                                                        // type='number'
                                                        // onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                        error={Boolean(errors.item_calories) && touched.item_calories}
                                                        helperText={(Boolean(errors.item_calories) && touched.item_calories) ? errors.item_calories : ''}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        // name="item_calories"
                                                        label={t('foodmenuscreen.itemcalories')}
                                                        value={values.item_calories}
                                                        onChange={handleChange}
                                                    />
                                                    {/* <TextField
                                                        type='number'
                                                        fullWidth
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                        onBlur={handleBlur}
                                                        name="item_calories"
                                                        label={t('foodmenuscreen.itemcalories')}
                                                        value={values.item_calories}
                                                        onChange={handleChange}
                                                    /> */}
                                                </div>
                                                <FormHelperText>Note: <span className='text-red'>If 0 then it shows '0 Kcal' in restaurant</span></FormHelperText>
                                            </div>
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <InputLabel>{t('foodmenuscreen.itemimage')}</InputLabel>
                                            <div className="d-flex">
                                                <ImageInput
                                                    imageWidth={200}
                                                    imageHeight={200}
                                                    imageData={imageVal.itemImageSrc}
                                                    type=" Item Image"
                                                    name="photo"
                                                    label="Add Photo"
                                                    showPreview
                                                    onChange={(files) => { handleChangeForImage(files) }}
                                                />
                                                {imageVal.itemImageSrc &&
                                                    <div className='ps-3'>
                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                            onClick={() => {
                                                                if (editData?.Image == imageVal.itemImage) {
                                                                    const path = `${restaurantId}/product/${imageVal.itemImage}`
                                                                    setDeleteImagePath(path)
                                                                    setImageVal({ itemImage: '', itemImageSrc: '' });
                                                                } else {
                                                                    setImageVal({ itemImage: '', itemImageSrc: '' });
                                                                }
                                                            }}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="row  pt-3">
                                            {/* <div className='col-3'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.DiscoverNew} />}
                                                    label={t('foodmenuscreen.discovernew')}
                                                    name="DiscoverNew"
                                                    onChange={handleChange}
                                                />
                                            </div> */}
                                            <div className='col-3 '>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.Popular} />}
                                                    label={t('foodmenuscreen.popular')}
                                                    name="Popular"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.IsNew} />}
                                                    label={t('foodmenuscreen.newproduct')}
                                                    name="IsNew"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.IsHalal} />}
                                                    label={t('foodmenuscreen.halal')}
                                                    name="IsHalal"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.IsActive} />}
                                                    label={t('foodmenuscreen.available')}
                                                    name="IsActive"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.IsVeg} />}
                                                    label={t('foodmenuscreen.vegonly')}
                                                    name="IsVeg"
                                                    onChange={(e) => { values.IsContainingEgg = false; handleChange(e) }}
                                                />
                                            </div>
                                            {values.IsVeg && <div className='col-3'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.IsContainingEgg} />}
                                                    label={t('foodmenuscreen.containingeggs')}
                                                    name="IsContainingEgg"
                                                    onChange={handleChange}
                                                />
                                            </div>}
                                            <div className='col-3'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.IsVegan} />}
                                                    label={t('foodmenuscreen.vegan')}
                                                    name="IsVegan"
                                                    onChange={handleChange}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-12 pt-3">
                                            <FieldArray
                                                name="FoodMenuDetails"
                                                render={arrayHelpers => (
                                                    <>

                                                        <Button
                                                            onClick={() => arrayHelpers.push({
                                                                ModifierGroupId: '',
                                                                DisplayName: '',
                                                                Description: '',
                                                                DisplayOrder: '',
                                                                MinSelection: '',
                                                                MaxSelection: '',
                                                                IsActive: false,
                                                            })}
                                                        >
                                                            {t('buttons.additem')}
                                                        </Button>

                                                        <div className="table-responsive">

                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t('foodmenuscreen.group')}</th>
                                                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.table.displayname') }}></th>
                                                                        <th>{t('foodmenuscreen.description')}</th>
                                                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.table.displayorder') }}></th>
                                                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.table.minselection') }}></th>
                                                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.table.maxselection') }}></th>
                                                                        <th scope="col">{t('foodmenuscreen.available')}</th>
                                                                        <th scope="col">{t('table.action')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {values.FoodMenuDetails && values.FoodMenuDetails.length > 0 && (
                                                                        values.FoodMenuDetails.map((item, index) => {
                                                                            const MinSelection = `FoodMenuDetails.${index}.MinSelection`;
                                                                            const touchedMinSelection = getIn(touched, MinSelection);
                                                                            const errorMinSelection = getIn(errors, MinSelection);

                                                                            const MaxSelection = `FoodMenuDetails.${index}.MaxSelection`;
                                                                            const touchedMaxSelection = getIn(touched, MaxSelection);
                                                                            const errorMaxSelection = getIn(errors, MaxSelection);

                                                                            item.IsActive = item.IsActive == '1' ? true : false
                                                                            return <tr key={index}>
                                                                                <td className='col-md-2'>
                                                                                    <FormControl fullWidth>
                                                                                        <TextField
                                                                                            select
                                                                                            fullWidth
                                                                                            required
                                                                                            value={item.ModifierGroupId}
                                                                                            label={t('foodmenuscreen.group')}
                                                                                            name={`FoodMenuDetails.${index}.ModifierGroupId`}
                                                                                            onChange={(e) => {
                                                                                                handleChange(e, index)
                                                                                                if (e.target.value) {
                                                                                                    item.DisplayName = modifierGroupList && modifierGroupList.filter((item) => item.ID == e.target.value)[0].Name_Display_On_Till
                                                                                                }
                                                                                            }}>
                                                                                            <MenuItem disabled value=''>select modifier Group</MenuItem>
                                                                                            {modifierGroupList.length > 0 ? modifierGroupList.map((items, index1) => {
                                                                                                return <MenuItem key={index1} value={items.ID}>{items.Name}</MenuItem>
                                                                                            }) : []}
                                                                                        </TextField>
                                                                                    </FormControl>
                                                                                </td>
                                                                                <td>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        name={`FoodMenuDetails.${index}.DisplayName`}
                                                                                        label={t('foodmenuscreen.displayname')}
                                                                                        value={item.DisplayName}
                                                                                        onChange={(e) => handleChange(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        name={`FoodMenuDetails.${index}.Description`}
                                                                                        label={t('foodmenuscreen.description')}
                                                                                        value={item.Description}
                                                                                        onChange={(e) => handleChange(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        type={'number'}
                                                                                        InputProps={{ inputProps: { min: 0 } }}
                                                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                                        name={`FoodMenuDetails.${index}.DisplayOrder`}
                                                                                        label={t('foodmenuscreen.displayorder')}
                                                                                        value={item.DisplayOrder == '' && !item.orderflag ? (index + 1) : item.DisplayOrder}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e, index);
                                                                                            item.orderflag = true
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <TextField
                                                                                        required={item.Flag ? false : true}
                                                                                        helperText={
                                                                                            Boolean(touchedMinSelection && errorMinSelection)
                                                                                                ? errorMinSelection
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedMinSelection && errorMinSelection)}
                                                                                        fullWidth
                                                                                        type={'number'}
                                                                                        InputProps={{ inputProps: { min: 0 } }}
                                                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                                        name={`FoodMenuDetails.${index}.MinSelection`}
                                                                                        label={t('foodmenuscreen.minselection')}
                                                                                        value={item.MinSelection}
                                                                                        onChange={(e) => handleChange(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <TextField
                                                                                        required={item.Flag ? false : true}
                                                                                        helperText={
                                                                                            Boolean(touchedMaxSelection && errorMaxSelection)
                                                                                                ? errorMaxSelection
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(touchedMaxSelection && errorMaxSelection)}
                                                                                        fullWidth
                                                                                        InputProps={{ inputProps: { min: 0 } }}
                                                                                        type={'number'}
                                                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                                        name={`FoodMenuDetails.${index}.MaxSelection`}
                                                                                        label={t('foodmenuscreen.maxselection')}
                                                                                        value={item.MaxSelection}
                                                                                        onChange={(e) => handleChange(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox checked={item.IsActive} />}
                                                                                        name={`FoodMenuDetails.${index}.IsActive`}
                                                                                        onChange={(e) => handleChange(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                                                        onClick={() => { handleDelete(item.ID, arrayHelpers, index) }}
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default FoodMenuAddEditModal
