
export const COLUMNSFORDRIVERLIST = [

	{
		Header: 'driverlistscreen.name',
		accessor: 'FirstName'
	},
	{
		Header: 'driverlistscreen.mobilenumber',
		accessor: 'Number'
	},
	{
		Header: 'driverlistscreen.email',
		accessor: 'EmailAddress'
	},
]
