
export const MenuList = [
    //Dashboard
    {
        title: 'sidebar.menu.dashboard',
        iconStyle: "bi bi-grid",
        to: 'dashboard',
        Permissions: ['all']
    },
    // {
    //     title: 'sidebar.menu.reporting',
    //     iconStyle: "bi bi-graph-up",
    //     to: 'reporting',
    //     Permissions: ['all']
    // },
    //Restaurant
    {
        title: 'sidebar.menu.restaurant',
        classsChange: 'mm-collapse',
        iconStyle: "bi bi-shop-window",
        Permissions: ['admin', 'restaurant'],
        content: [
            {
                title: 'sidebar.submanu.ordermaster',
                to: 'orderMaster',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.orderhistory',
                to: 'orderHistory',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.category',
                to: 'category',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.modifiergroup',
                to: 'modifiergroup',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.modifieritem',
                to: 'modifieritem',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.modifiertype',
                to: 'producttype',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.voucher',
                to: 'voucher',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.promocode',
                to: 'promocode',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.promotions',
                to: 'promotions',
                Permissions: ['admin', 'restaurant'],
            },
            {
                title: 'sidebar.submanu.foodmenu',
                to: 'foodmenu',
                Permissions: ['admin', 'restaurant'],
            }

        ]
    },
    //Drivers
    {
        title: 'sidebar.menu.homebanner',
        iconStyle: "bi bi-card-image",
        to: 'homebanner',
        Permissions: ['admin', 'restaurant']
    },
    {
        title: 'sidebar.menu.drivers',
        classsChange: 'mm-collapse',
        iconStyle: "bi bi-bicycle",
        Permissions: ['admin', 'driver'],
        content: [
            {
                title: 'sidebar.submanu.list',
                to: 'drivers'
            },
        ],
    },
    {
        title: 'sidebar.menu.localization',
        iconStyle: "bi bi-sliders",
        to: 'localization-settings',
        Permissions: ['admin', 'restaurant']
    },
    {
        title: 'sidebar.menu.users',
        to: 'userlist',
        iconStyle: "bi bi-person-lines-fill",
        Permissions: ['admin', 'restaurant'],
    },
    {
        title: 'sidebar.menu.rolesettings',
        to: 'role-settings',
        iconStyle: "bi bi-gear",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.emailmarketing',
        classsChange: 'mm-collapse',
        iconStyle: "bi bi-envelope",
        Permissions: ['admin'],
        content: [
            {
                title: 'sidebar.submanu.emailtemplate',
                to: 'emailtemplate'
            },
            {
                title: 'sidebar.submanu.campaign',
                to: 'campaign'
            },
        ],
    },
    {
        title: 'sidebar.menu.configuration',
        classsChange: 'mm-collapse',
        iconStyle: "bi bi-bicycle",
        Permissions: ['admin'],
        content: [
            {
                title: 'sidebar.submanu.deliverycharges',
                to: 'deliverycharges'
            },
            {
                title: 'sidebar.submanu.importexport',
                to: 'rest-api'
            },
            {
                title: 'sidebar.submanu.timeschedule',
                to: 'timeschedule'
            },
            {
                title: 'sidebar.submanu.preorder',
                to: 'preorderconfig'
            },
            {
                title: 'sidebar.submanu.apps',
                to: 'apps'
            }
        ]
    }
]