import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { S3ImageUpload } from '../../../../constants/constants'

const ViewcategoryModal = (props) => {
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData, restaurantId } = props
    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }
    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('categoryscreen.viewcategory')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('categoryscreen.categoryname')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.CategoryName}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('categoryscreen.displaynameontill')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Name_Display_On_Till}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('categoryscreen.categorycode')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.CategoryCode}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('categoryscreen.parentcategory')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.ParentCategory ? viewData.ParentCategory : '-'}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('categoryscreen.categoryimage')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2" ><img src={`${S3ImageUpload}${restaurantId}/category/icon/${viewData.Image}`} alt="" className='w-100' /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('categoryscreen.bannerimage')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col-lg-5 p-2"><img src={`${S3ImageUpload}${restaurantId}/category/banner/${viewData.Banner}`} alt="" className='w-100' /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('categoryscreen.categorydiscription')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2" dangerouslySetInnerHTML={{ __html: viewData.CategoryDescription }}></div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewcategoryModal
