import React from 'react'
import {  Modal } from 'react-bootstrap';

import ModifierGroupDetailsForm from './ModifierGroupDetailsForm';


const initialvalue = [{
    ProductTypeId: '',
    ItemId: '',
    Description: '',
    DisplayOrder: '',
    IsActive: false,
    modifierlist: []
}]


const ModifierdetailModal = (props) => {
    const { openDetailsModal, setOpenDetailsModal, modifierGroupDetails, modifierTypeList, setmodifierTypeList } = props;
    const handleModalClose = () => {
        setOpenDetailsModal(false)
    }

    return (

        <Modal
            className="fade bd-example-modal-lg "
            show={openDetailsModal}
            // onHide={handleModalClose}
            size="xl"
            centered
        >
            <ModifierGroupDetailsForm openDetailsModal={openDetailsModal} setOpenDetailsModal={setOpenDetailsModal} modifierGroupDetails={modifierGroupDetails} modifierTypeList={modifierTypeList} setmodifierTypeList={setmodifierTypeList} initialvalue={initialvalue} />
        </Modal >

    )
}

export default ModifierdetailModal

