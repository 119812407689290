import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ViewPromotionModal = (props) => {
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData } = props
    const currencySymbol = localStorage.getItem('currencySymbol')


    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('promotionscreen.viewpromotion')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.promotiontitle')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_Name}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.promotiondescription')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_Desc}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.fromdate')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_FromDate ? t("date_only", { date: viewData.Pro_FromDate }) : '-'}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.todate')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_ToDate ? t("date_only", { date: viewData.Pro_ToDate }) : '-'}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.fromtime')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_FromTime == '00:00:00' ? '-' : viewData.Pro_FromTime}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.totime')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_ToTime == '00:00:00' ? '-' : viewData.Pro_FromTime}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.days')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_On_Days_Names}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.promotiontype')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Pro_Type}</div>
                    </div>
                    {viewData.Pro_Type == "Advanced" &&
                        <>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.whenacustomer')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">{viewData.Pro_When == 'Buys' ? t('promotionscreen.buyswithnumber', { quantity: viewData.Pro_On_Qty }) : t('promotionscreen.spendswithnumber', { spends: viewData.Pro_On_Amount }) + currencySymbol}</div>
                            </div>
                        </>
                    }
                    {(viewData.Pro_On_Categories != null || viewData.Pro_On_Items != null) ?
                        <>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.category')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">{viewData.Pro_On_Categories_Names}</div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.item')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">{viewData.Pro_On_Items}</div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.product')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">{t('promotionscreen.all')}</div>
                            </div>
                        </>
                    }
                    {viewData.Pro_Type == "Advanced" && viewData.Pro_When != '' && viewData.Pro_They_Will == 'GetDelivery' &&
                        <>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.thentheywill')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">
                                    {viewData.Pro_Get_Percent ? t('promotionscreen.getdeliveryon', { number: viewData.Pro_Get_Percent, symbol: '%' }) : t('promotionscreen.getdeliveryon', { number: viewData.Pro_Get_Amount, symbol: currencySymbol })}
                                </div>
                            </div>
                        </>
                    }
                    {viewData.Pro_Type == "Advanced" && viewData.Pro_When != '' && viewData.Pro_They_Will == "SaveAmount" &&
                        <>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.thentheywill')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">
                                    {viewData.Pro_Get_Percent ? t('promotionscreen.savecertainamount', { number: viewData.Pro_Get_Percent }) + '%' : t('promotionscreen.savecertainamount', { number: viewData.Pro_Get_Amount }) + currencySymbol}
                                </div>
                            </div>
                        </>
                    }
                    {viewData.Pro_Type == "Advanced" && viewData.Pro_When != '' && viewData.Pro_They_Will == "PayFix" &&
                        <>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.thentheywill')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">
                                    {(viewData.Pro_Get_Percent ? t('promotionscreen.savecertainamount', { number: viewData.Pro_Get_Percent }) + '%' : t('promotionscreen.savecertainamount', { number: viewData.Pro_Get_Amount }) + currencySymbol) + ' ' + t('promotionscreen.onpayFix')}
                                </div>
                            </div>
                        </>
                    }
                    {viewData.Pro_Type == "Advanced" &&
                        <>
                            <div className="row">
                                <div className="col-lg-4 p-2 text-end"><h6>{t('promotionscreen.appileson')}</h6></div>
                                <div className="col-lg-1 p-2 text-center">:</div>
                                <div className="col p-2">
                                    {viewData.Pro_On_Cart == "Entire" ? t('promotionscreen.entiresale') : t('promotionscreen.specificitemsabove')}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewPromotionModal
