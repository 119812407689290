import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, Select, TextField } from "@mui/material";
import { Form, Formik } from 'formik';
import React from 'react'
import { useTranslation } from "react-i18next";
import * as Yup from 'yup'
import { symbolsArr } from "../../../../constants/constants";

const RestaurantSettings = (prop) => {
    const { t } = useTranslation('common');
    const { InitialValues, onsubmit } = prop
    return (
        <>
            <div className="pt-3">
                <div className="settings-form">
                    <h4 className="text-primary p-3">{t('profile.restaurantsettings.Restaurantadvancedetails')}</h4>
                    <Formik
                        enableReinitialize
                        initialValues={InitialValues}
                        validationSchema={Yup.object().shape({
                            DeliveryType: Yup
                                .object()
                                .shape({
                                    delivery: Yup.boolean(),
                                    pickup: Yup.boolean(),
                                    dine_in: Yup.boolean()
                                })
                                .test(
                                    "DeliveryType",
                                    "At least one of the checkbox is required",
                                    (options) => {
                                        return options.delivery || options.pickup || options.dine_in;
                                    }
                                )

                        })}
                        onSubmit={onsubmit}

                    >
                        {(props) => {
                            const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                            return (
                                <Form onSubmit={(!isValid || isDisabled) ? null : handleSubmit} noValidate>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <TextField
                                                error={Boolean(errors.Radius) && touched.Radius}
                                                helperText={(Boolean(errors.Radius) && touched.Radius) ? errors.Radius : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                type='number'
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                name="Radius"
                                                label={t('profile.restaurantsettings.radius')}
                                                value={values.Radius}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <TextField
                                                error={Boolean(errors.PickupTimeinminute) && touched.PickupTimeinminute}
                                                helperText={(Boolean(errors.PickupTimeinminute) && touched.PickupTimeinminute) ? errors.PickupTimeinminute : ''}
                                                fullWidth
                                                type='text'
                                                onBlur={handleBlur}
                                                name="PickupTimeinminute"
                                                label={t('profile.restaurantsettings.pickuptimeinminute')}
                                                value={values.PickupTimeinminute}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <TextField
                                                error={Boolean(errors.DeliveryTimeinminute) && touched.DeliveryTimeinminute}
                                                helperText={(Boolean(errors.DeliveryTimeinminute) && touched.DeliveryTimeinminute) ? errors.DeliveryTimeinminute : ''}
                                                fullWidth
                                                type='text'
                                                onBlur={handleBlur}
                                                name="DeliveryTimeinminute"
                                                label={t('profile.restaurantsettings.deliverytimeinminute')}
                                                value={values.DeliveryTimeinminute}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 pt-4">
                                            <TextField
                                                error={Boolean(errors.MinimumOrderPrice) && touched.MinimumOrderPrice}
                                                helperText={(Boolean(errors.MinimumOrderPrice) && touched.MinimumOrderPrice) ? errors.MinimumOrderPrice : ''}
                                                fullWidth
                                                type='number'
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                onBlur={handleBlur}
                                                name="MinimumOrderPrice"
                                                label={t('profile.restaurantsettings.minimumorderprice')}
                                                value={values.MinimumOrderPrice}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <FormControl variant="standard">
                                                <FormLabel component="legend"><h6>{t('profile.restaurantsettings.restaurantdeliverytype')}</h6></FormLabel>
                                                <FormHelperText className="Mui-error">{Boolean(errors.DeliveryType) || touched.DeliveryType ? errors.DeliveryType : ""}</FormHelperText>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={values.DeliveryType.delivery} onChange={handleChange} name="DeliveryType.delivery" />
                                                        }
                                                        label={t('profile.restaurantsettings.delivery')}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={values.DeliveryType.pickup} onChange={handleChange} name="DeliveryType.pickup" />
                                                        }
                                                        label={t('profile.restaurantsettings.pickup')}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={values.DeliveryType.dine_in} onChange={handleChange} name="DeliveryType.dine_in" />
                                                        }
                                                        label={t('profile.restaurantsettings.dinein')}
                                                    />
                                                </FormGroup>

                                            </FormControl>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <h6>{t('profile.restaurantsettings.restaurantstatusconfiguration')}</h6>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={values.isOffline}
                                                        name='isOffline'
                                                        onChange={handleChange}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } label={t('profile.restaurantsettings.isOffline')} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={values.IsGiveContactFree}
                                                        name='IsGiveContactFree'
                                                        onChange={handleChange}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } label={t('profile.restaurantsettings.isgivecontactfree')} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={values.IsDriverTipEnabled}
                                                        name='IsDriverTipEnabled'
                                                        onChange={handleChange}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } label={t('profile.restaurantsettings.isdrivertipenabled')} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <h6>{t('profile.restaurantsettings.restaurantpymentconfiguration')}</h6>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={values.Fully_Paid}
                                                        name='Fully_Paid'
                                                        onChange={handleChange}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } label={t('profile.restaurantsettings.fullypaid')} />
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className="mt-3 d-flex float-right align-items-center justify-content-end">
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            size="large"
                                            disabled={!isValid || isDisabled}
                                        >
                                          {t('buttons.save')}
                                        </button>
                                    </div>

                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default RestaurantSettings
