import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditPromoCodeApi } from '../../../../services/promocodeServices';
import { symbolsArr } from '../../../../constants/constants';


const PromoCodeAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData } = props

    const onsubmit = (values) => {
        if (editData && editData.ID) {
            values.PromocodeId = editData.ID
        }
        dispatch(addEditPromoCodeApi(values, (res) => {
            setOpenModal(false);
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()

    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    CouponCode: editData ? editData.CouponCode : '',
                    FromDate: editData ? editData.FromDate : '',
                    ToDate: editData ? editData.ToDate : '',
                    CartMinValue: editData ? editData.CartMinValue : '',
                    MaxUser: editData ? editData.MaxUser : '',
                    PromoCodePercent: editData ? editData.PromoCodePercent : '',
                    TimesPerUser: editData ? editData.TimesPerUser : '',
                    MaxDiscount: editData ? editData.MaxDiscount : '',
                    IsActive: editData ? (editData.IsActive == '1' ? true : false) : true,
                }}

                validationSchema={
                    Yup.object().shape({
                        CouponCode: Yup.string().required("Promo Code is required."),
                        FromDate: Yup.date().required("From date is required."),
                        ToDate: Yup.date().required("To date is required."),
                        CartMinValue: Yup.string().required("cart Mininmum value is required."),
                        MaxUser: Yup.string().required("maximum User is required."),
                        PromoCodePercent: Yup.string().required("Promo Code percent is required."),
                        TimesPerUser: Yup.string().required("No. of times per user is required."),
                        MaxDiscount: Yup.string().required("Maximum disount is required."),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('promocodescreen.updatepromocode') : t('promocodescreen.addpromocode')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.CouponCode) && touched.CouponCode}
                                                helperText={(Boolean(errors.CouponCode) && touched.CouponCode) ? errors.CouponCode : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="CouponCode"
                                                label={t('promocodescreen.promocode')}
                                                value={values.CouponCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.CartMinValue) && touched.CartMinValue}
                                                helperText={(Boolean(errors.CartMinValue) && touched.CartMinValue) ? errors.CartMinValue : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="CartMinValue"
                                                label={t('promocodescreen.cartminvalue')}
                                                value={values.CartMinValue}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.MaxUser) && touched.MaxUser}
                                                helperText={(Boolean(errors.MaxUser) && touched.MaxUser) ? errors.MaxUser : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="MaxUser"
                                                label={t('promocodescreen.maxuser')}
                                                value={values.MaxUser}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.PromoCodePercent) && touched.PromoCodePercent}
                                                helperText={(Boolean(errors.PromoCodePercent) && touched.PromoCodePercent) ? errors.PromoCodePercent : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="PromoCodePercent"
                                                label={t('promocodescreen.promocodepercent')}
                                                value={values.PromoCodePercent}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.TimesPerUser) && touched.TimesPerUser}
                                                helperText={(Boolean(errors.TimesPerUser) && touched.TimesPerUser) ? errors.TimesPerUser : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="TimesPerUser"
                                                label={t('promocodescreen.nooftimeperuser')}
                                                value={values.TimesPerUser}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl size="small" variant="outlined" fullWidth>
                                                <TextField
                                                    onKeyDown={() => { "return false" }}
                                                    fullWidth
                                                    inputProps={{ max: values.ToDate }}
                                                    type="date"
                                                    name='FromDate'
                                                    variant="outlined"
                                                    label={t('promocodescreen.fromdate')}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleChange}
                                                    value={values.FromDate}
                                                    error={Boolean(errors.FromDate) && touched.FromDate}
                                                    helperText={(Boolean(errors.FromDate) && touched.FromDate) ? errors.FromDate : ''}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl size="small" variant="outlined" fullWidth>
                                                <TextField
                                                    onKeyDown={() => { "return false" }}
                                                    fullWidth
                                                    type="date"
                                                    inputProps={{ min: values.FromDate ? new Date(values.FromDate).toISOString().slice(0, 10) : "" }}
                                                    name='ToDate'
                                                    variant="outlined"
                                                    label={t('promocodescreen.todate')}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleChange}
                                                    value={values.ToDate}
                                                    error={Boolean(errors.ToDate) && touched.ToDate}
                                                    helperText={(Boolean(errors.ToDate) && touched.ToDate) ? errors.ToDate : ''}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.MaxDiscount) && touched.MaxDiscount}
                                                helperText={(Boolean(errors.MaxDiscount) && touched.MaxDiscount) ? errors.MaxDiscount : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="MaxDiscount"
                                                label={t('promocodescreen.maxdiscount')}
                                                value={values.MaxDiscount}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.IsActive} />}
                                                label={t('isactive')}
                                                name="IsActive"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default PromoCodeAddEditModal
