import moment from "moment-timezone";
import axiosApi from "../api";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, GET_LOCALIZATION_SETTINGS, GET_THEME, SHOW_MESSAGE } from "../constants/ActionTypes";
import { errorHandler } from "../helpers/apiErrorHandler";

export const getLocalizationsettingsApi = (cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/get-localization-settings').then((res) => {
            if (res.data.status == 1) {
                let obj = {};
                res?.data?.data.map((item) => {
                    obj[item.type] = item.value;
                })
                dispatch({ type: GET_LOCALIZATION_SETTINGS, payload: obj })
                localStorage.setItem('timeZone', obj.timezone ? obj.timezone : moment.tz.guess())
                localStorage.setItem('dateFormat', obj.dateFormat ? obj.dateFormat : "DD-MM-YYYY")
                localStorage.setItem('timeFormat', obj.timeFormat ? obj.timeFormat : "24 Hours")
                localStorage.setItem('firstDayOfWeek', obj.firstDayOfWeek ? obj.firstDayOfWeek : "Sunday")
                localStorage.setItem('currency', obj.currency ? obj.currency : "INR")
                localStorage.setItem('currencySymbol', obj.currencySymbol ? obj.currencySymbol : "₹")
                localStorage.setItem('currencyposition', obj.currencyposition ? obj.currencyposition : "Left")
                localStorage.setItem('decimalSeparator', obj.decimalSeparator ? obj.decimalSeparator : "Comma(,)")
                localStorage.setItem('decimalNumber', obj.noOfSeparator ? obj.noOfSeparator : "2")
                localStorage.setItem('language', obj.language ? obj.language : "EN")
                dispatch({ type: FETCH_SUCCESS });
                if (cb) cb(obj)

            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const getThemesettingsApi = (cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/get-localization-settings').then((res) => {
            if (res.data.status == 1) {
                let obj = {};
                res?.data?.data.map((item) => {
                    obj[item.type] = item.value;
                })
                dispatch({ type: GET_LOCALIZATION_SETTINGS, payload: obj })
                if (obj && obj.themeSettings) {
                    let theme = JSON.parse(obj.themeSettings)
                    dispatch({ type: GET_THEME, payload: theme })
                    localStorage.setItem('primaryColor', theme['primaryColor'])
                    localStorage.setItem('navigationHader', theme['navigationHader'])
                    localStorage.setItem('sidebarColor', theme['sidebarColor'])
                    localStorage.setItem('headerColor', theme['headerColor'])
                    localStorage.setItem('background', theme['background'])
                    localStorage.setItem('sidebarLayout', theme['sidebarLayout'])
                    localStorage.setItem('headerposition', theme['headerposition'])
                    localStorage.setItem('sideBarStyle', theme['sideBarStyle'])
                    localStorage.setItem('sidebarposition', theme['sidebarposition'])
                    localStorage.setItem('containerPositionSize', theme['containerPositionSize'])
                    localStorage.setItem('typography', theme['typography'])

                    const body = document.querySelector("body");
                    body.setAttribute("data-primary", theme['primaryColor'])
                    body.setAttribute("data-nav-headerbg", theme['navigationHader'])
                    body.setAttribute("data-sibebarbg", theme['sidebarColor'])
                    body.setAttribute("data-headerbg", theme['headerColor'])
                    body.setAttribute("data-theme-version", theme['background'])
                    body.setAttribute("data-layout", theme['sidebarLayout'])
                    body.setAttribute("data-header-position", theme['headerposition'])
                    body.setAttribute("data-sidebar-style", theme['sideBarStyle'])
                    body.setAttribute("data-sidebar-position", theme['sidebarposition'])
                    body.setAttribute("data-container", theme['containerPositionSize'])
                    body.setAttribute("data-typography", theme['typography'])
                    body.setAttribute("data-secondary", "color_1");
                    body.setAttribute("direction", "ltr");
                }
                dispatch({ type: FETCH_SUCCESS });
                if (cb) cb(obj)

            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const AddEditLocalizationSettingsApi = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/localization-settings', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const uploadImage = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/uplaod-file', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const deleteImage = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/remove-file', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}