export const COLUMNS = [
	
	{
		Header : 'userscreen.firstname',
		accessor: 'first_name'
	},
	{
		Header : 'userscreen.lastname',
		accessor: 'last_name'
	},
	{
		Header : 'userscreen.jobtitle',
		accessor: 'job_title'
	},
	{
		Header : 'userscreen.gender',
		accessor: 'gender',
	},
	{
		Header : 'userscreen.email',
		accessor: 'email'
	},
	{
		Header : 'userscreen.role',
		accessor: 'role_name',
	},
]