import axiosApi from "../api";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHOW_MESSAGE } from "../constants/ActionTypes";
import { errorHandler } from "../helpers/apiErrorHandler";

export const getReportData = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/get-order-count-by-time', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const getReportDataforLocation = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/get-order-count-by-location', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const getReportDataforrevenue = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/get-total-revanue-by-payment-type', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const getTotalIncome = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/get-total-income', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}


export const getTopItems = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        // dispatch({ type: FETCH_START });
        axiosApi.post('Api/get-top-item', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const getcustomerDetails = (cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/get-orders-performance').then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const getTopCategory = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/get-top-category', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
