import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EmailEditor from "react-email-editor";

const EmailTemplateViewModal = (props) => {
    const { t } = useTranslation('common')
    const emailEditorRef = useRef(null);
    const { openViewModal, setOpenViewModal, viewData, setViewData } = props

    const handleModalClose = () => {
        setOpenViewModal(false);
        setViewData()
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('emailtemplate.viewemailtemplate')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleModalClose}
                >
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div
                    dangerouslySetInnerHTML={{ __html: viewData?.body }}
                />
            </Modal.Body>
        </Modal>

    )
}

export default EmailTemplateViewModal
