import { Box, Checkbox, Chip, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getCustomerList, modifyCampaign } from '../../../../services/EmailMarketingServices';

const EmailSendModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openSendModal, setOpenSendModal, sendData, setSendData, templateList } = props
    const [userList, setUserList] = useState([])


    const onsubmit = (values) => {
        console.log(values)
        if (sendData.campaign_id) {
            values.id = sendData.campaign_id
        }
        dispatch(modifyCampaign(values, (res) => {
            handleModalClose()
        }))
    }

    useEffect(() => {
        dispatch(getCustomerList((res) => {
            setUserList(res.data)
        }))
    }, [])

    const handleModalClose = () => {
        if (sendData && sendData.customer_ids) {
            sendData.customer_ids = sendData.customer_ids.join(',')
        }
        setOpenSendModal(false);
        setSendData()

    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openSendModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    name: sendData ? sendData.name : '',
                    schedule_at: sendData ? sendData.schedule_at : null,
                    customer_ids: sendData && sendData.customer_ids ? sendData.customer_ids : [],
                    template_id: sendData && sendData.template_id ? sendData.template_id : ''
                }}

                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().required("Campaign Name is required."),
                        template_id: Yup.string().required("Template is required."),
                        customer_ids: Yup.array().min(1, "Please select atlease one user."),
                    })}
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, setFieldValue, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{sendData && sendData.campaign_id ? t('emailtemplate.editcampaign') : t('emailtemplate.addcampaign')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    disabled={sendData && sendData.disableTemplate ? true : false}
                                                    value={values.template_id}
                                                    label={t('emailtemplate.template')}
                                                    name='template_id'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.template_id) && touched.template_id}
                                                    helperText={Boolean(errors.template_id) && touched.template_id ? errors.template_id : ""}
                                                >
                                                    {templateList && templateList.length > 0 && templateList.map((item) => {
                                                        return <MenuItem value={item.id}>{item.template_name}</MenuItem>
                                                    })}

                                                </TextField>

                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label={t('emailtemplate.campaignname')}
                                                    name='name'
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.name) && touched.name}
                                                    helperText={Boolean(errors.name) && touched.name ? errors.name : ""}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    label={t('emailtemplate.datetime')}
                                                    name='schedule_at'
                                                    type="datetime-local"
                                                    value={values.schedule_at}
                                                    onChange={handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.schedule_at) && touched.schedule_at}
                                                    helperText={Boolean(errors.schedule_at) && touched.schedule_at ? errors.schedule_at : ""}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-multiple-checkbox-label" className={`${Boolean(errors.customer_ids) && touched.customer_ids ? 'text-danger' : ''}`}>{t('emailtemplate.users')}</InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-multiple-checkbox-label"
                                                    multiple
                                                    value={values.customer_ids}
                                                    name='customer_ids'
                                                    onChange={handleChange}
                                                    input={<OutlinedInput label={t('emailtemplate.users')} />}
                                                    renderValue={(selected) => {
                                                        let userValue = [];
                                                        {
                                                            selected.map((value) => {
                                                                userValue.push(userList.length > 0 ? userList.filter((item) => item.user_id == value)[0].full_name : '')
                                                            })
                                                        }
                                                        return userValue.join(', ')
                                                    }}
                                                    error={Boolean(errors.customer_ids) && touched.customer_ids}
                                                >
                                                    {userList.map((name) => (
                                                        <MenuItem key={name.user_id} value={name.user_id}>
                                                            <Checkbox checked={values.customer_ids.indexOf(name.user_id) > -1} />
                                                            <ListItemText primary={name.full_name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText className="Mui-error">{Boolean(errors.customer_ids) || touched.customer_ids ? errors.customer_ids : ""}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default EmailSendModal
