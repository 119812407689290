import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ViewModifierTypeModal = (props) => {
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData } = props


    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('modifiertypescreen.viewmodifiertype')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('modifiertypescreen.modifiertype')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Type}</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewModifierTypeModal
