import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { S3ImageUpload } from '../../../constants/constants'

const ViewHomeBannerModal = (props) => {
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData, restaurantId } = props


    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('homebannerScreen.viewhomebanner')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('homebannerScreen.bannerimage')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2 pt-0">
                            {viewData?.BannerImage.slice(viewData?.BannerImage.lastIndexOf('.') + 1) == 'mp4'
                                ?
                                <video width="200" autoPlay loop="loop" muted>
                                    <source src={`${S3ImageUpload}${restaurantId}/homebanner/${viewData?.BannerImage}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                :
                                <img src={`${S3ImageUpload}${restaurantId}/homebanner/${viewData?.BannerImage}`} alt="" className='w-25' />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('homebannerScreen.bannerstatus')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.IsActive == '1' ? 'true' : 'false'}</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewHomeBannerModal
