
export const COLUMNSFORCategory = [
	
	{
		Header : 'categoryscreen.image',
		accessor: 'Image'
	},
	{
		Header : 'categoryscreen.categoryname',
		accessor: 'CategoryName'
	},
	{
		Header : 'categoryscreen.parentcategory',
		accessor: 'ParentCategory'
	},
	{
		Header : 'categoryscreen.displaynameontill',
		accessor: 'Name_Display_On_Till'
	},
	{
		Header : 'categoryscreen.categorycode',
		accessor: 'CategoryCode',
	},
	{
		Header : 'categoryscreen.addeddate',
		accessor: 'CreatedDateTime'
	},

]
