
export const COLUMNSFORDELIVERYCHARGE = [

	{
		Header: 'deliverychargesscreen.deliveryrangeinmiles',
		accessor: 'StartRange'
	},
	{
		Header: 'deliverychargesscreen.deliverycharge',
		accessor: 'DeliveryCharge'
	},
]
