import React, { useState, useContext } from "react";
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { FormControl, MenuItem, Select } from "@mui/material";


const Setting = () => {
	const { t } = useTranslation('common')
	const [settingToggle, setSettingToggle] = useState(false);
	const {
		body,
		sideBarOption,
		layoutOption,
		backgroundOption,
		sidebarposition,
		headerPositions,
		containerPosition,
		fontFamily,
		typography,
		changePrimaryColor,
		changeSecondaryColor,
		changeNavigationHader,
		sideBarStyle,
		changeSideBarStyle,
		changeSideBarPostion,
		sidebarpositions,
		changeHeaderPostion,
		headerposition,
		changeSideBarLayout,
		sidebarLayout,
		colors,
		chnageheaderColor,
		chnageSidebarColor,
		changeBackground,
		background,
		changeContainerPosition,
		containerPosition_,
		changeTypography,
		containerPositionSize
	} = useContext(ThemeContext);
	return (
		<>
			<div className={`settings sidebar-right ${settingToggle ? "show" : ""}`}>
				<div className="bg-overlay" onClick={() => setSettingToggle(!settingToggle)}></div>
				<Link to="#" className="sidebar-right-trigger wave-effect wave-effect-x" onClick={() => setSettingToggle(!settingToggle)} >
					<span><i className="fa fa-cog fa-spin" /></span>
				</Link>
				<Link to="#" className="sidebar-close-trigger" onClick={() => setSettingToggle(!settingToggle)} >
					<span><i className="la-times las"></i></span>
				</Link>
				<div className="sidebar-right-inner">
					<h4>{t('themesettings.pickyourstyle')}</h4>
					<Tab.Container defaultActiveKey="Theme">
						<div className="card-tabs ">
							<Nav as="ul" className="nav nav-tabs" role="tablist">
								<Nav.Item as="li" className="nav-item">
									<Nav.Link as="a" className="nav-link c-pointer" data-toggle="tab" eventKey="Theme" role="tab"> {t('themesettings.theme')} </Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link as="a" className="nav-link c-pointer" data-toggle="tab" eventKey="Header" role="tab"> {t('themesettings.header')} </Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link as="a" className="nav-link c-pointer" data-toggle="tab" eventKey="Content" role="tab">{t('themesettings.content')}</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
						<Tab.Content className="">
							<Tab.Pane className="tab-pane fade " eventKey="Theme">
								<div className="admin-settings">
									<div className="row">
										<div className="col-sm-12">
											<p>{t('themesettings.background')}</p>{" "}
											<FormControl fullWidth>
												<Select
													value={background}
													onChange={(e) => changeBackground(e)}
												>
													{backgroundOption && backgroundOption.map((item, index) => {
														return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
													})}
												</Select>
											</FormControl>
											{/* <Select defaultValue={background} onChange={(e) => changeBackground(e)}
																options={backgroundOption}
																style={{ lineHeight: "44px", color: "#777777", paddingLeft: " 15px", }}
															/> */}
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.primarycolor')}</p>
											<div>
												{colors.map((color, i) => (
													<span key={i}>
														<input type="radio" name="primary_color" defaultValue={color} className="filled-in chk-col-primary"
															id={`primary_${color}`}
															onClick={() => changePrimaryColor(color)}
														/>
														<label htmlFor={`primary_${color}`} />
													</span>
												))}
											</div>
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.navigationheader')}</p>
											<div>
												{colors.map((color, i) => (
													<span key={i}>
														<input type="radio" name="navigation_header" defaultValue={color} className="filled-in chk-col-primary"
															id={`nav_header_${color}`}
															onClick={() => changeNavigationHader(color)}
														/>
														<label htmlFor={`nav_header_${color}`} />
													</span>
												))}
											</div>
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.header')}</p>
											<div>
												{colors.map((color, i) => (
													<span key={i}>
														<input type="radio" name="header_bg" defaultValue={color} className="filled-in chk-col-primary"
															id={`header_${color}`}
															onClick={() => chnageheaderColor(color)}
														/>
														<label htmlFor={`header_${color}`} />
													</span>
												))}
											</div>
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.sidebar')}</p>
											<div>
												{colors.map((color, i) => (
													<span key={i}>
														<input type="radio" name="navigation_header" defaultValue={color} className="filled-in chk-col-primary"
															id={`sidebar_${color}`}
															onClick={() => chnageSidebarColor(color)}
														/>
														<label htmlFor={`sidebar_${color}`} />
													</span>
												))}
											</div>
										</div>
										{/* <div className="col-sm-6">
										<p>Background</p>{" "}
										<Select defaultValue={background} onChange={(e) => changeBackground(e)}
											options={backgroundOption}
											style={{ lineHeight: "40px", color: "#7e7e7e", paddingLeft: " 15px",}}
										/>
									</div> */}
									</div>
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane fade" eventKey="Header">
								<div className="admin-settings">
									<div className="row">
										<div className="col-sm-6">
											<p>{t('themesettings.layout')}</p>{" "}
											<FormControl fullWidth>
												<Select
													value={sidebarLayout}
													onChange={(e) => changeSideBarLayout(e)}
												>
													{layoutOption && layoutOption.map((item, index) => {
														return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
													})}
												</Select>
											</FormControl>
											{/* <Select defaultValue={sidebarLayout} onChange={(e) => changeSideBarLayout(e)} options={layoutOption}
												style={{
													lineHeight: "40px",
													color: "#7e7e7e",
													paddingLeft: " 15px",
												}}
											/> */}
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.headerposition')}</p>{" "}
											<FormControl fullWidth>
												<Select
													value={headerposition}
													onChange={(e) => changeHeaderPostion(e)}
												>
													{headerPositions && headerPositions.map((item, index) => {
														return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
													})}
												</Select>
											</FormControl>
											{/* <Select
												defaultValue={headerposition}
												onChange={(e) => changeHeaderPostion(e)}
												options={headerPositions}
												style={{ lineHeight: "40px", color: "#7e7e7e", paddingLeft: " 15px", }}
											/> */}
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.sidebar')}</p>{" "}
											<FormControl fullWidth>
												<Select
													value={sideBarStyle}
													onChange={(e) => changeSideBarStyle(e)}
												>
													{sideBarOption && sideBarOption.map((item, index) => {
														return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
													})}
												</Select>
											</FormControl>
											{/* <Select defaultValue={sideBarStyle} onChange={(e) => changeSideBarStyle(e)} options={sideBarOption}
												style={{ lineHeight: "40px", color: "#7e7e7e", paddingLeft: " 15px", }}
											/> */}
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.sidebarposition')}</p>{" "}
											<FormControl fullWidth>
												<Select
													value={sidebarposition}
													onChange={(e) => changeSideBarPostion(e)}
												>
													{sidebarpositions && sidebarpositions.map((item, index) => {
														return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
													})}
												</Select>
											</FormControl>
											{/* <Select defaultValue={sidebarposition} onChange={(e) => changeSideBarPostion(e)} options={sidebarpositions}
												style={{ lineHeight: "40px", color: "#7e7e7e", paddingLeft: " 15px", }}
											/> */}
										</div>
									</div>
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane fade" eventKey="Content">
								<div className="admin-settings">
									<div className="row">
										<div className="col-sm-6">
											<p>{t('themesettings.container')}</p>{" "}
											<FormControl fullWidth>
												<Select
													value={containerPositionSize}
													onChange={(e) => changeContainerPosition(e)}
												>
													{containerPosition && containerPosition.map((item, index) => {
														return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
													})}
												</Select>
											</FormControl>
											{/* <Select defaultValue={containerPosition_} onChange={(e) => changeContainerPosition(e)} options={containerPosition}
												style={{ lineHeight: "40px", color: "#7e7e7e", paddingLeft: " 15px", }}
											/> */}
										</div>
										<div className="col-sm-6">
											<p>{t('themesettings.bodyfont')}</p>{" "}
											<FormControl fullWidth>
												<Select
													value={typography}
													onChange={(e) => changeTypography(e)}
												>
													{fontFamily && fontFamily.map((item, index) => {
														return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
													})}
												</Select>
											</FormControl>
											{/* <Select defaultValue={fontFamily[1]} onChange={(e) => changeTypography(e)}
												options={fontFamily}
												style={{ lineHeight: "40px", color: "#7e7e7e", paddingLeft: " 15px", }}
											/> */}
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div >
		</>
	);
};

export default Setting;


