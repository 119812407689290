import { GET_LOCALIZATION_SETTINGS, GET_THEME } from "../../constants/ActionTypes";


const INIT_STATE = {
    settings: {},
    themeConfig: {
        sideBarStyle: "",
        sidebarposition: "",
        headerposition: "",
        sidebarLayout: "",
        direction: "",
        primaryColor: "",
        secondaryColor: "",
        navigationHader: "",
        headerColor: "",
        sidebarColor: "",
        background: "",
        containerPositionSize: "",
        iconHover: false,
        menuToggle: false,
        windowWidth: 0,
        windowHeight: 0,
        typography: "",
    }
};

export function localizationSettings(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_LOCALIZATION_SETTINGS: {
            return {
                ...state,
                settings: action.payload || {},
            };
        }
        case GET_THEME: {
            return {
                ...state,
                themeConfig: {
                    sideBarStyle: action.payload.sideBarStyle,
                    sidebarposition: action.payload.sidebarposition,
                    headerposition: action.payload.headerposition,
                    sidebarLayout: action.payload.sidebarLayout,
                    direction: action.payload.direction,
                    primaryColor: action.payload.primaryColor,
                    secondaryColor: action.payload.secondaryColor,
                    navigationHader: action.payload.navigationHader,
                    headerColor: action.payload.headerColor,
                    sidebarColor: action.payload.sidebarColor,
                    background: action.payload.background,
                    containerPositionSize: action.payload.containerPositionSize,
                    iconHover: false,
                    menuToggle: false,
                    windowWidth: 0,
                    windowHeight: 0,
                    typography: action.payload.typography,
                }
            };
        }
        default:
            return state;
    }
};
