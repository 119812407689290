import axiosApi from "../api";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, GET_ORDER_COUNT, SHOW_MESSAGE } from "../constants/ActionTypes";
import { errorHandler } from "../helpers/apiErrorHandler";

export const getOrderHistoryListApi = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        axiosApi.post('Api/order-history-report', data).then((res) => {
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}