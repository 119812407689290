import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DeletePromotionApi, getPromotionItemListApi, getPromotionListApi } from '../../../../services/promotionServices'
import { useEffect } from 'react'
import { COLUMNSFORPROMOTIONS } from './PromotionsColumns'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import PromotionsAddEditModal from './PromotionsAddEditModal'
import TablePaginationHelper from '../../../../helpers/Pagination'
import TableSearch from '../../../../helpers/TableSearch'
import { Button } from 'react-bootstrap'
import swal from 'sweetalert'
import { weekendsArr, weekendsArrForPromotion } from '../../../../constants/constants'
import { getCategoryListApi } from '../../../../services/CategoryServices'
import { Empty } from 'antd'
import ViewPromotionModal from './ViewPromotionModal'

const Promotions = () => {
    const dispatch = useDispatch()
    const [promotionList, setPromotionList] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const { auth } = useSelector((state) => state.auth);
    const { t } = useTranslation('common');
    const permissions = JSON.parse(auth.role_permissions)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [categoryList, setCategoryList] = useState([])
    const [ItemList, setItemList] = useState([])

    useEffect(() => {
        dispatch(getPromotionListApi((res) => {
            setPromotionList(res.data)
        }))
    }, [openModal])

    useEffect(() => {
        dispatch(getCategoryListApi((res) => {
            setCategoryList(res.data)
        }))
    }, [])

    const columns = COLUMNSFORPROMOTIONS
    const data = promotionList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleEdit = (row) => {
        if (row.original.Pro_On_Categories != '' && row.original.Pro_On_Categories != null) {
            let Pro_On_Categories_arr = row.original.Pro_On_Categories.split(',');
            let CategoryIds = [];
            Pro_On_Categories_arr.map((data) => {
                let filterCategory = categoryList.filter((res) => res['ID'] === data)
                CategoryIds.push(parseInt(filterCategory[0]?.ID))
            })
            dispatch(getPromotionItemListApi({ CategoryIds }, (res) => {
                setItemList(res.data)

            }))
        }
        setEditData(row.original)
        setOpenModal(true)

    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }

    const handleView = (row) => {
        if (row.original.Pro_On_Categories != '' && row.original.Pro_On_Categories != null) {
            let Pro_On_Categories_arr = row.original.Pro_On_Categories.split(',');
            let CategoryIds = [];
            let CategoryName = [];
            Pro_On_Categories_arr.map((data) => {
                let filterCategory = categoryList.filter((res) => res['ID'] === data)
                CategoryIds.push(parseInt(filterCategory[0]?.ID))
                CategoryName.push(filterCategory[0]?.CategoryName)
                row.original.Pro_On_Categories_Names = CategoryName.join(', ')
            })
            dispatch(getPromotionItemListApi({ CategoryIds }, (res) => {
                setItemList(res.data)
                let Pro_On_Items_arr = row.original.Pro_On_Items.split(',');
                let CategoryItemName = [];
                Pro_On_Items_arr.map((data) => {
                    let filterCategoryItem = res.data.filter((res) => res['ID'] === data)
                    CategoryItemName.push(filterCategoryItem[0]?.ItemName)
                    row.original.Pro_On_Items = CategoryItemName.join(', ')
                })
            }))
        }
        if (row.original.Pro_On_Days && row.original.Pro_On_Days.split(',').length > 0) {
            let arr = weekendsArrForPromotion.filter((item, index) => row.original.Pro_On_Days.includes(index))
            row.original.Pro_On_Days_Names = arr.join(', ')
        }
        setViewData(row.original)
        setOpenViewModal(true)
    }

    const handleDelete = (row) => {
        const PromotionId = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Promotion has been deleted!", {
                    icon: "success",
                });
                dispatch(DeletePromotionApi({ PromotionId }, (ress) => {
                    dispatch(getPromotionListApi((res) => {
                        setPromotionList(res.data)
                    }))
                }))
            }
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('promotionscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {((permissions && permissions.promotion && permissions.promotion.add) || permissions == null || !permissions.promotion) && <Button onClick={handleAddOpen}>{t('buttons.addpromotion')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        let arr = weekendsArrForPromotion.filter((item, index) => row.original.Pro_On_Days.includes(index))
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td> {row.original.Pro_Name} </td>
                                                <td > {row.original.Pro_FromDate ? t("date_only", { date: row.original.Pro_FromDate }) : '-'} </td>
                                                <td >  {row.original.Pro_FromDate ? t("date_only", { date: row.original.Pro_ToDate }) : '-'} </td>
                                                <td> {row.original.Pro_FromTime && row.original.Pro_FromTime != '00:00:00' ? row.original.Pro_FromTime : '-'} </td>
                                                <td> {row.original.Pro_ToTime && row.original.Pro_ToTime != "00:00:00" ? row.original.Pro_ToTime : '-'} </td>
                                                <td> {arr.length == 7 ? "All Days (Week)" : arr.join(',')} </td>
                                                <td> {row.original.Pro_Type} </td>
                                                <td> {row.original.IsActive == '1' ? "Active" : "Inactive"} </td>
                                                <td className='text-end'>
                                                    {((permissions && permissions.promotion && permissions.promotion.view) || permissions == null || !permissions.promotion) && <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>}
                                                    {((permissions && permissions.promotion && permissions.promotion.edit) || permissions == null || !permissions.promotion) && <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>}
                                                    {((permissions && permissions.promotion && permissions.promotion.delete) || permissions == null || !permissions.promotion) && <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>}
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5' style={{ fontSize: '20px', fontWeight: '600' }}><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div>
            {openModal && <PromotionsAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} categoryList={categoryList} ItemList={ItemList} setItemList={setItemList} />}
            {openViewModal && <ViewPromotionModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
        </>
    )
}

export default Promotions
