import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getmodifierGroupProductistApi } from '../../../../services/foodmenuServices'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { S3ImageUpload } from '../../../../constants/constants'

const ViewFoodMenuModal = (props) => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const { openViewModal, setOpenViewModal, viewData } = props
    const [modifierGroupProductList, setmodifierGroupProductList] = useState([]);
    let userDetails = JSON.parse(localStorage.getItem('userDetails'))
    let restaurantId = userDetails.restaurant_id

    useEffect(() => {
        if (viewData) {
            const ID = viewData.ID
            dispatch(getmodifierGroupProductistApi({ ID }, (res) => {
                setmodifierGroupProductList(res.data)
            }))
        }
    }, []);

    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('foodmenuscreen.viewfoodmenu')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('foodmenuscreen.itemName')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.ItemName}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('foodmenuscreen.menucategory')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.CategoryName ? viewData.CategoryName : viewData.ParentCategoryName}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('foodmenuscreen.price')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.Price) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('foodmenuscreen.supplierprice')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.SupplierPrice) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('foodmenuscreen.shortdescription')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.SortDescription}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('foodmenuscreen.longdescription')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.LongDescription}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('foodmenuscreen.itemimage')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2"><img src={`${S3ImageUpload}${restaurantId}/product/${viewData.Image}`} alt="" /></div>
                    </div>
                    {modifierGroupProductList && modifierGroupProductList.length > 0 && <div className="row">
                        <div className="col-lg-12 p-0">
                            <table className="table-sm table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th>{t('foodmenuscreen.group')}</th>
                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.displayname') }}></th>
                                        <th>{t('foodmenuscreen.description')}</th>
                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.displayorder') }}></th>
                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.minselection') }}></th>
                                        <th dangerouslySetInnerHTML={{ __html: t('foodmenuscreen.maxselection') }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modifierGroupProductList && modifierGroupProductList.length > 0 && modifierGroupProductList.map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.ModifierGroupName}</td>
                                                <td>{item.DisplayName}</td>
                                                <td>{item.Description}</td>
                                                <td>{item.DisplayOrder}</td>
                                                <td>{item.MinSelection}</td>
                                                <td>{item.MaxSelection}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewFoodMenuModal
