import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { div } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from 'react-redux';
import { orderReportApi } from '../../../../services/orderService';
import { useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';



const TrackOrderModal = (props) => {
    const dispatch = useDispatch()
    const { openTrackModal, setOpenTrackModal, orderDetails } = props;
    const { t } = useTranslation('common')
    const [orderReport, setOrderReport] = useState([])


    useEffect(() => {
        const obj = { "OrderId": orderDetails.OrderId }
        dispatch(orderReportApi(obj, (res) => {
            setOrderReport(res.data.ReturnData)
        }))
    }, [])


    const handleTrackModalClose = () => {
        setOpenTrackModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openTrackModal}
            // onHide={handleTrackModalClose}
            size="md"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {t('ordermasterscreen.ordertracking')}(#{orderDetails.TransactionId})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PerfectScrollbar
                    style={{ height: "350px" }}
                    className="trackOrderTimeline dlab-scroll height370 ps ps--active-y"
                >
                    <div className="process">
                        <ul>
                            {orderReport && orderReport.length > 0 && orderReport.map((item, index) => {
                                return (
                                    <li key={index} className={`${item.OrderStatusId ? 'active' : ''}`}>
                                        <h5>{item.StatusDisplayName}</h5>
                                        <p>{item.UserGuide}</p>
                                        <span color="text.secondary">{item.DateAndTime}</span>
                                    </li>)
                            })}
                        </ul>
                    </div>
                </PerfectScrollbar>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger light"
                    onClick={handleTrackModalClose}
                >

                    {t('buttons.close')}
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default TrackOrderModal
