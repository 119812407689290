// import firebase from 'firebase';

// const firebaseConfig = {
//   apiKey: "AIzaSyBCDIQdRhE5aTnT6MjM3PbvrfF1x3h47fg",
//   authDomain: "foodel-215405.firebaseapp.com",
//   databaseURL: "https://foodel-215405.firebaseio.com",
//   projectId: "foodel-215405",
//   storageBucket: "foodel-215405.appspot.com",
//   messagingSenderId: "92855779018",
//   appId: "1:92855779018:web:f0160fcfebdac5765b3e33",
//   measurementId: "G-K73QVFXK9D"
// };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);

// export default firebase;

import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyBCDIQdRhE5aTnT6MjM3PbvrfF1x3h47fg",
  authDomain: "foodel-215405.firebaseapp.com",
  databaseURL: "https://foodel-215405.firebaseio.com",
  projectId: "foodel-215405",
  storageBucket: "foodel-215405.appspot.com",
  messagingSenderId: "92855779018",
  appId: "1:92855779018:web:f0160fcfebdac5765b3e33",
  measurementId: "G-K73QVFXK9D"
};

firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging();

messaging.usePublicVapidKey('BMFFnR8GkMC3jHE9AddLzjlhqcJLdD14gLRTc9aSX7oTd0yao7va1Cq01csNhCNCnhWjkAmuGcFASW_XIwNdFKw', { vapidKey: false });