import React from 'react'
import { COLUMNFORCAMPAIGN } from './CampaignColumn';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import TableSearch from '../../../../helpers/TableSearch';
import TablePaginationHelper from '../../../../helpers/Pagination';
import { Empty } from 'antd';
import EmailSendModal from '../EmailTemplate/EmailSendModal';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCampaign, getCampaignList, getTemplateList } from '../../../../services/EmailMarketingServices';
import swal from 'sweetalert';
import { Button } from 'react-bootstrap';
import ViewCampaignModal from './ViewCampaignModal';

const CampaignList = () => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [campaignList, setCampaignList] = useState([])
    const [openSendModal, setOpenSendModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [sendData, setSendData] = useState({});
    const [templateList, setTemplateList] = useState([])

    const columns = COLUMNFORCAMPAIGN
    const data = campaignList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    useEffect(() => {
        dispatch(getCampaignList((res) => {
            setCampaignList(res.data)
        }))
    }, [openSendModal])

    useEffect(() => {
        dispatch(getTemplateList((res) => {
            setTemplateList(res.data)
        }))
    }, [])

    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }

    const handleSend = (row) => {
        row.original.customer_ids = row.original.customer_ids.split(',')
        setSendData(row.original)
        setOpenSendModal(true)
    }

    const handleAddOpen = (row) => {
        setOpenSendModal(true)
    }

    const handleDelete = (row) => {
        let id = row.original.campaign_id
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Campaign has been deleted!", {
                    icon: "success",
                });
                dispatch(deleteCampaign({ id }, (res) => {
                    dispatch(getCampaignList((res) => {
                        setCampaignList(res.data)
                    }))
                }))
            }
        })
    }


    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('campaign.title')}</h4>
                <div className='d-flex justify-content-md-around TableSerchInput'>
                    <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Button onClick={handleAddOpen}>{t('campaign.addcampaign')}</Button>
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <div className="dataTables_wrapper">
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {t(column.render('Header'))}
                                                <span className="ml-1">
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                    )
                                                        :
                                                        (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                    }
                                                </span>
                                            </th>
                                        ))}
                                        <th className='text-end'>{t('table.action')}</th>
                                    </tr>
                                ))}
                            </thead>
                            {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            <td > {row.original.name}  </td>
                                            <td > {row.original.customer_ids}  </td>
                                            <td > {row.original.template_name}  </td>
                                            <td > {row.original.status}  </td>
                                            {row.original.schedule_at
                                                ?
                                                <td > {t("date_format", { date: row.original.schedule_at })}  </td>
                                                :
                                                <td className='text-center'> -</td>
                                            }
                                            <td className='text-end'>
                                                <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>
                                                <button disabled={row.original.status != "created" ? true : false} className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleSend(row)} > <i className="fas fa-pencil-alt"></i></button>
                                                <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody> :
                                <tbody>
                                    <tr>
                                        <td colSpan={6} className='text-center p-5'><Empty /></td>
                                    </tr>
                                </tbody>}
                            {/* This is only for footer if u require */}

                        </table>
                    </div>
                </div>
                {openSendModal && <EmailSendModal openSendModal={openSendModal} setOpenSendModal={setOpenSendModal} sendData={sendData} setSendData={setSendData} templateList={templateList} />}
                {openViewModal && <ViewCampaignModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} setViewData={setViewData} />}
            </div>
            {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
        </div>
    )
}

export default CampaignList