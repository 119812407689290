import { IconButton, InputAdornment, InputLabel, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditDriverApi } from '../../../../services/DriverServices';
import { S3ImageUpload } from '../../../../constants/constants';
import ImageInput from '../../../../helpers/fileUploader/ImageInput';
import { S3ImageDelete, S3Uploads } from '../../../../helpers/S3Upload/S3upload';
import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



const DriverAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData } = props
    const [deleteImagePath, setDeleteImagePath] = useState('')
    let userDetails = JSON.parse(localStorage.getItem('userDetails'))
    let restaurantId = userDetails.restaurant_id

    const onsubmit = async (values) => {
        let currentDate = Date.now()
        if (deleteImagePath != '') {
            S3ImageDelete(deleteImagePath)
        }
        if (editData && editData.DID) {
            values.DriverId = editData.DID
        }

        if (typeof values.DriverProfile == 'object') {
            const fileNameForDriver = currentDate + '_' + values.DriverProfile.name.replace(/ /g, "_")
            values.profileImage = fileNameForDriver
        } else {
            values.profileImage = values.DriverProfile
        }

        dispatch(addEditDriverApi(values, async (res) => {
            let driverId = editData && editData.DID ? editData.DID : res.data
            if (typeof values.DriverProfile == 'object') {
                const fileNameForDriver = currentDate + '_' + values.DriverProfile.name.replace(/ /g, "_")
                const filePathForDriver = `${restaurantId}/driver/${fileNameForDriver}`
                await S3Uploads(values.DriverProfile, filePathForDriver, fileNameForDriver)
            }
            setOpenModal(false);
            setEditData()
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()

    }
    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                enableReinitialize
                initialValues={
                    editData ?
                        {
                            FirstName: editData ? editData.FirstName : '',
                            LastName: editData ? editData.LastName : '',
                            Email: editData ? editData.EmailAddress : '',
                            MobileNumber: editData ? editData.Number : '',
                            DriverProfile: editData?.ProfilePic ? editData?.ProfilePic : '',
                            DriverProfileSrc: editData?.ProfilePic ? `${S3ImageUpload}${restaurantId}/driver/${editData?.ProfilePic}` : ''
                        }
                        :
                        {
                            FirstName: editData ? editData.FirstName : '',
                            LastName: editData ? editData.LastName : '',
                            Email: editData ? editData.EmailAddress : '',
                            MobileNumber: editData ? editData.Number : '',
                            Password: '',
                            showPassword: false,
                            DriverProfile: editData?.ProfilePic ? editData?.ProfilePic : '',
                            DriverProfileSrc: editData?.ProfilePic ? `${S3ImageUpload}${restaurantId}/driver/${editData?.ProfilePic}` : ''
                        }}

                validationSchema={
                    editData ?
                        Yup.object().shape({
                            FirstName: Yup.string().required("FIrst Name is required."),
                            LastName: Yup.string().required("last Name is required."),
                            Email: Yup.string().required("Email Address is required.").email("Please enter valid email"),
                            MobileNumber: Yup.string()
                                .test(
                                    'is-valid-phone-number',
                                    'Invalid phone number. It should start with 0 and be 11 characters long',
                                    value => {
                                        console.log(value)
                                        if (!value) {
                                            return false; // Empty value is considered invalid
                                        }
                                        return /^0\d{10}$/.test(value);
                                    }
                                )
                                // .matches('^0\d{10}$', 'Invalid mobile number. It should start with 0 and be 11 characters long')
                                .required("Phone Number is required."),
                        })
                        :
                        Yup.object().shape({
                            FirstName: Yup.string().required("FIrst Name is required."),
                            LastName: Yup.string().required("last Name is required."),
                            Email: Yup.string().required("Email Address is required.").email("Please enter valid email"),
                            Password: Yup.string().required("Password is required."),
                            MobileNumber: Yup.string()
                                .test(
                                    'is-valid-phone-number',
                                    'Invalid phone number. It should start with 0 and be 11 characters long',
                                    value => {
                                        console.log(value)
                                        if (!value) {
                                            return false; // Empty value is considered invalid
                                        }
                                        return /^0\d{10}$/.test(value);
                                    }
                                )
                                // .matches('^0\d{10}$', 'Invalid phone number. It should start with 0 and be 11 characters long')
                                .required("Phone Number is required."),
                        })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, setFieldValue, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('driverlistscreen.updatedriver') : t('driverlistscreen.adddriver')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.FirstName) && touched.FirstName}
                                                helperText={(Boolean(errors.FirstName) && touched.FirstName) ? errors.FirstName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="FirstName"
                                                label={t('driverlistscreen.firstname')}
                                                value={values.FirstName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.LastName) && touched.LastName}
                                                helperText={(Boolean(errors.LastName) && touched.LastName) ? errors.LastName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="LastName"
                                                label={t('driverlistscreen.lastname')}
                                                value={values.LastName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.Email) && touched.Email}
                                                helperText={(Boolean(errors.Email) && touched.Email) ? errors.Email : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Email"
                                                label={t('driverlistscreen.email')}
                                                value={values.Email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {!editData && <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => { setFieldValue('showPassword', !values.showPassword) }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}
                                                error={Boolean(errors.Password) && touched.Password}
                                                helperText={(Boolean(errors.Password) && touched.Password) ? errors.Password : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Password"
                                                type={values.showPassword ? 'text' : "password"}
                                                label={t('driverlistscreen.password')}
                                                value={values.Password}
                                                onChange={handleChange}
                                            />
                                        </div>}
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                type="text"
                                                name="MobileNumber"
                                                InputProps={{
                                                    inputProps: {
                                                        inputMode: 'numeric',
                                                    },
                                                    onInput: (e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                    },
                                                }}
                                                required
                                                // type='number'
                                                // onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.MobileNumber) && touched.MobileNumber}
                                                helperText={(Boolean(errors.MobileNumber) && touched.MobileNumber) ? errors.MobileNumber : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                // name="MobileNumber"
                                                label={t('driverlistscreen.mobilenumber')}
                                                value={values.MobileNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3 '>
                                            <InputLabel>{t('driverlistscreen.driverprofile')}</InputLabel>
                                            <div className='d-flex'>
                                                <ImageInput
                                                    imageWidth={200}
                                                    imageHeight={200}
                                                    imageData={values.DriverProfileSrc}
                                                    type="Driver Profile"
                                                    name="DriverProfile"
                                                    label="Add Photo"
                                                    showPreview
                                                    onChange={(e) => {
                                                        if (editData?.ProfilePic == values.DriverProfile) {
                                                            const path = `${restaurantId}/driver/${values.DriverProfile}`
                                                            setDeleteImagePath(path)
                                                        }
                                                        setFieldValue('DriverProfile', e.DriverProfile.data);
                                                        setFieldValue('DriverProfileSrc', e.DriverProfile.src);
                                                    }}
                                                />
                                                {values.DriverProfileSrc &&
                                                    <div className='ps-3'>
                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp me-1'
                                                            onClick={() => {
                                                                if (editData?.ProfilePic == values.DriverProfile) {
                                                                    const path = `${restaurantId}/driver/${values.DriverProfile}`
                                                                    setDeleteImagePath(path)
                                                                    setFieldValue('DriverProfile', '');
                                                                    setFieldValue('DriverProfileSrc', '');
                                                                } else {
                                                                    setFieldValue('DriverProfile', '');
                                                                    setFieldValue('DriverProfileSrc', '');
                                                                }
                                                            }}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default DriverAddEditModal
