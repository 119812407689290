import React from 'react';
import { Tab, Nav, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteRoleApi, getPermissionsRolewiseApi, getRoleListAPi, rolePermissionsAddEditApi } from '../../../services/RoleServices';
import { useState } from 'react';
import AddEditRoleModal from './AddEditRoleModal';
import swal from 'sweetalert';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Form, Formik } from 'formik';



const RoleSettings = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    const [roles, setRoles] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState({})
    const [roleId, setRoleID] = useState()
    const [permissions, setPermissions] = useState()

    useEffect(() => {
        dispatch(getRoleListAPi((res) => {
            setRoles(res.data)
        }))
    }, [dispatch, openModal])

    const handleOpen = () => {
        setOpenModal(true)
    }

    const handleEdit = (row) => {
        setOpenModal(true)
        setEditData(row)
    }

    const handleDelete = (row) => {
        const id = row.id
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Role has been deleted!", {
                    icon: "success",
                });
                dispatch(DeleteRoleApi({ id }, (result) => {
                    dispatch(getRoleListAPi((res) => {
                        setRoles(res.data)
                    }))
                }))
            }
        })
    }

    const handleSelect = (role_id) => {
        setRoleID(role_id)
        dispatch(getPermissionsRolewiseApi({ role_id }, (res) => {
            setPermissions(JSON.parse(res.role_permissions))
        }))
    }

    const onsubmit = (values) => {
        const data = {
            role_id: roleId,
            role_permissions: JSON.stringify(values)
        }
        dispatch(rolePermissionsAddEditApi(data, (res) => { }))
    }
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('rolesettings.title')}</h4>
                    <Button onClick={handleOpen}>{t('buttons.addrole')}</Button>
                </div>
                <div className="card-body">
                    <div className="row">
                        <Tab.Container>
                            <div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body px-3">
                                        <Nav onSelect={handleSelect} className="nav nav-tabs border-0 flex-column" id="nav-tab" role="tablist">
                                            {roles && roles.map((item, index) => {
                                                return (
                                                    <Nav.Link key={index} className="nav-link setting-bx d-flex " id={item.role_name} eventKey={item.id}>
                                                        <div className="setting-info">
                                                            <h6>{item.role_name}</h6>
                                                            <div>
                                                                <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(item)} > <i className="fas fa-pencil-alt"></i></button>
                                                                <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(item)} > <i className="fa fa-trash"></i></button>
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                )
                                            })}
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <Tab.Content>
                                    {roles && roles.map((item, index) => {
                                        return (<Tab.Pane key={index} eventKey={item.id}>
                                            <Formik
                                                enableReinitialize
                                                initialValues={{
                                                    excludemenu: {
                                                        Users: permissions?.excludemenu?.Users ? permissions.excludemenu?.Users : false
                                                    },
                                                    excludesubmenu: {
                                                        category: permissions?.excludesubmenu?.category ? permissions.excludesubmenu?.category : false,
                                                    },
                                                    user: {
                                                        view: true,
                                                        add: permissions?.user?.add ? permissions.user?.add : false,
                                                        edit: permissions?.user?.edit ? permissions.user?.edit : false,
                                                        delete: permissions?.user?.delete ? permissions.user?.delete : false,
                                                    },
                                                    category: {
                                                        view: true,
                                                        add: permissions?.category?.add ? permissions.category?.add : false,
                                                        edit: permissions?.category?.edit ? permissions.category?.edit : false,
                                                        delete: permissions?.category?.delete ? permissions.category?.delete : false,
                                                    }

                                                }}

                                                onSubmit={onsubmit}
                                            >
                                                {(props) => {
                                                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                                                    return (
                                                        <Form onSubmit={handleSubmit} noValidate>
                                                            <div className="setting-right">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <h3>{t('rolesettings.excludedfromleftmenu')}</h3>
                                                                            <div className="col">
                                                                                <h5>{t('rolesettings.menulist')}</h5>
                                                                                <FormGroup style={{ width: '10%' }}>
                                                                                    <FormControlLabel control={<Checkbox checked={values.excludemenu.Users} onChange={handleChange} name="excludemenu.Users" />} label={t('rolesettings.users')} />
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div className="col">
                                                                            <h5>{t('rolesettings.submenulist')}</h5>
                                                                                <FormGroup style={{ width: '10%' }}>
                                                                                    <FormControlLabel control={<Checkbox checked={values.excludesubmenu.category} onChange={handleChange} name="excludesubmenu.category" />} label={t('rolesettings.category')} />
                                                                                </FormGroup>
                                                                            </div>


                                                                        </div>
                                                                        <div className="row">
                                                                            <h3>{t('rolesettings.setuserpermissions')}</h3>
                                                                            <FormGroup style={{ width: '10%' }}>
                                                                                <FormControlLabel control={<Checkbox checked={values.user.add} onChange={handleChange} name="user.add" />} label={t('rolesettings.add')} />
                                                                                <FormControlLabel control={<Checkbox checked={values.user.edit} onChange={handleChange} name="user.edit" />} label={t('rolesettings.edit')} />
                                                                                <FormControlLabel control={<Checkbox checked={values.user.delete} onChange={handleChange} name="user.delete" />} label={t('rolesettings.delete')} />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="row">
                                                                            <h3>{t('rolesettings.setcategorypermissions')}</h3>
                                                                            <FormGroup style={{ width: '10%' }}>
                                                                                {/* <FormControlLabel control={<Checkbox checked={values.category.view} onChange={handleChange} name="category.view" />} label={t('rolesettings.view')} /> */}
                                                                                <FormControlLabel control={<Checkbox checked={values.category.add} onChange={handleChange} name="category.add" />} label={t('rolesettings.add')} />
                                                                                <FormControlLabel control={<Checkbox checked={values.category.edit} onChange={handleChange} name="category.edit" />} label={t('rolesettings.edit')} />
                                                                                <FormControlLabel control={<Checkbox checked={values.category.delete} onChange={handleChange} name="category.delete" />} label={t('rolesettings.delete')} />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3 d-flex float-right align-items-center justify-content-end">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="submit"
                                                                    size="large"
                                                                    disabled={!isValid || isDisabled}
                                                                >
                                                                    {t('buttons.save')}
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </Tab.Pane>
                                        )
                                    })}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div >
                </div>
            </div>
            {openModal && <AddEditRoleModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} />}
        </>
    )
}
export default RoleSettings;