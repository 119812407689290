import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { connectToMailchimpAPI, getaccessTokenForMailchimp } from '../../../../services/mailchimpServices';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Button } from 'react-bootstrap';

const MailchimpConnected = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation('common')
    const [response, setResponse] = useState({})
    let mailchimpObj = JSON.parse(localStorage.getItem('obj'))
    let count = 0;
    let userData = JSON.parse(localStorage.getItem('userDetails'))

    useEffect(() => {
        if (count == 0) {
            const obj = {
                user_id: atob(mailchimpObj.state),
                code: mailchimpObj.code,
            };
            dispatch(getaccessTokenForMailchimp(obj, (res) => {
                setResponse(res.data)
            }))
            count++
        }
    }, [])

    const onsubmit = (values) => {
        const obj = {
            access_token: response.access_token,
            dc: response.dc,
            list_id: values.audience,
            user_id: userData.user_id
        }
        dispatch(connectToMailchimpAPI(obj, (res) => {
            navigate('/mailchimp/customers', { state: values.audience })
        }))
    }

    return (
        <div className='card'>
            <div className="card-header">
                <h4 className="card-title">{t('mailchimpscreen.audience')}</h4>
            </div>
            <Formik
                initialValues={{
                    audience: ''
                }}

                validationSchema={
                    Yup.object().shape({
                        audience: Yup.string().required("audience is required.")
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="card-body">
                                    <p className='font-w600 fs-16'>{t('mailchimpscreen.choosewhichaudience', { restaurantname: userData.restaurant_name })}</p>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="demo-simple-select"
                                                    value={values.audience}
                                                    label={t('mailchimpscreen.audience')}
                                                    name='audience'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.audience) && touched.audience}
                                                    helperText={Boolean(errors.audience) && touched.audience ? errors.audience : ""}
                                                >
                                                    <MenuItem disabled value=''>Choose an audience</MenuItem>
                                                    {response && response.audiences_list && response.audiences_list.length > 0 && response.audiences_list.map((item, index) => {
                                                        return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                    })}

                                                </TextField>

                                            </FormControl>
                                        </div>


                                    </div>


                                </div>
                                <div className="card-footer text-end">
                                    <Button type="submit" size="large" onClick={handleSubmit}>
                                        {t('mailchimpscreen.connect')}
                                    </Button>
                                </div>
                            </Form>

                        </>
                    );
                }}
            </Formik>

        </div>
    )
}

export default MailchimpConnected
