import axiosApi from "../api";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHOW_MESSAGE } from "../constants/ActionTypes";
import { errorHandler } from "../helpers/apiErrorHandler";




export const getDeliveryChargesApi = (cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/delivery-charge-list').then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const checkDelivaryAreaExist = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/delivary-area-check', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const updateDelivaryArea = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/delivary-area-add-edit', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const DeleteDelivaryArea = (id, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/delivary-area-delete', id).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}