import { FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import moment from 'moment';
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditRoleApi } from '../../../services/RoleServices';

const AddEditRoleModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData } = props

    const onsubmit = (values) => {
        if (editData && editData.id) {
            values.id = editData.id;
        }
        dispatch(addEditRoleApi(values, (res) => {
            setOpenModal(false);
        }))

    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData();
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >
            <Formik
                initialValues={{
                    role_name: editData ? editData.role_name : '',
                }}

                validationSchema={Yup.object().shape({
                    role_name: Yup.string().required("Role Name is required."),
                })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('rolesettings.updaterole') : t('rolesettings.addrole')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={() => setOpenModal(false)}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.role_name) && touched.role_name}
                                                helperText={(Boolean(errors.role_name) && touched.role_name) ? errors.role_name : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="role_name"
                                                label={t('rolesettings.rolename')}
                                                value={values.role_name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default AddEditRoleModal
