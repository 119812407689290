
export const COLUMNSFORHOMEBANNER = [

	{
		Header: 'homebannerScreen.bannerimage',
		accessor: 'Image'
	},
	{
		Header: 'homebannerScreen.bannerstatus',
		accessor: 'IsActive'
	},
]
