import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { genrateImportExportconfApi, getImportExportListApi } from '../../../../services/ImportExportServices'
import { IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ImportExport = () => {
    const dispatch = useDispatch()
    const [confdata, setConfdata] = useState({})
    const [title, setTitle] = useState('click to copy')
    const { t } = useTranslation('common');

    useEffect(() => {
        dispatch(getImportExportListApi((res) => {
            setConfdata(res.data)
        }))
    }, [])

    const handleSubmit = () => {
        dispatch(genrateImportExportconfApi((ress) => {
            setTimeout(() => {
                dispatch(getImportExportListApi((res) => {
                    setConfdata(res.data)
                }))
            }, 500);
        }))
    }

    const copyToClipboard = str => {
        navigator.clipboard.writeText(str)
            .then(() => {
                setTitle('Copied to clipboard')
                setTimeout(() => {
                    setTitle('click to copy')
                }, 3000);
            })
            .catch(err => {
                console.error('Error copying text:', err);
            });
    };



    return (
        <div className='card'>
            <div className="card-header">
                <h4 className="card-title">{t('importexportscreen.title')}</h4>
            </div>

            <div className="card-body">
                {confdata &&
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <h4>{t('importexportscreen.apiurl')}</h4>
                                <div className="d-flex">
                                    <p className='fs-16'>{confdata.API_Url}
                                        {confdata.API_Url && (confdata?.API_Url).toString().length > 0 && <Tooltip title={title} >
                                            <IconButton onClick={() => copyToClipboard(confdata.API_Url)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <h4>{t('importexportscreen.clientkey')}</h4>
                                <div className="d-flex">
                                    <p className='fs-16'>{confdata.ClientKey}
                                        {confdata.ClientKey && (confdata?.ClientKey).toString().length > 0 && <Tooltip title={title} >
                                            <IconButton onClick={() => copyToClipboard(confdata.ClientKey)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <h4>{t('importexportscreen.clientsecret')}</h4>
                                <div className="d-flex">
                                    <p className='fs-16'>{confdata.ClientSecret}
                                        {confdata.ClientSecret && (confdata?.ClientSecret).toString().length > 0 && <Tooltip title={title} >
                                            <IconButton onClick={() => copyToClipboard(confdata.ClientSecret)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="card-footer">
                <Button type="submit" size="large" onClick={handleSubmit}>
                    {t('buttons.genrate')}
                </Button>
            </div>
        </div>
    )
}

export default ImportExport
