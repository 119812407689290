
export const COLUMNSFORMODIFIERITEM = [
	
	{
		Header : 'modifieritemscreen.modifieritems',
		accessor: 'ItemName'
	},
	{
		Header : 'modifieritemscreen.price',
		accessor: 'Price'
	},
	{
		Header : 'modifieritemscreen.modifiertype',
		accessor: 'ProductTypeId'
	},
	{
		Header : 'modifieritemscreen.available',
		accessor: 'IsActive'
	},
]
