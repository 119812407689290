import React, { Fragment } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
// import styles

import RestaurantProfile from "./RestaurantProfile";
import RestaurantLinks from "./RestaurantLinks";
import RestaurantAddress from "./RestaurantAddress";
import RestaurantSettings from "./RestaurantSettings";
import RestaurantImages from "./RestaurantImages";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EditRestProfiletApi, getProfileData } from "../../../../services/RestaurantServices";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ChangePassword from "../../../pages/ChangePassword";
import { S3Uploads } from "../../../../helpers/S3Upload/S3upload";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions";


const AppProfile = () => {
	const { t } = useTranslation('common');
	const dispatch = useDispatch()
	const [profileData, setProfileData] = useState({})
	const [flag, setFlag] = useState(false)
	const [openModal, setOpenModal] = useState(false);
	let userDetails = JSON.parse(localStorage.getItem('userDetails'))

	useEffect(() => {
		setFlag(true)
		dispatch(getProfileData((res) => {
			setProfileData(res.data.Profile)
			setFlag(false)
		}))
	}, [])

	const handleModalOpen = () => {
		setOpenModal(true)
	}
	const InitialValues = {
		RestaurantName: profileData?.RestaurantName ? profileData?.RestaurantName : '',
		RestaurantExternalURL: profileData?.RestaurantUrl ? profileData?.RestaurantUrl : '',
		ExternalURLSlug: profileData?.UrlSlug ? profileData?.UrlSlug : '',
		Email: profileData?.EmailAddress ? profileData?.EmailAddress : '',
		AlternativeEmail: profileData?.AlternativeEmail ? profileData?.AlternativeEmail : '',
		// MobileNo: [{ number: '' }],
		MobileNo: profileData?.MobileNo && profileData?.MobileNo.length > 0 ? profileData?.MobileNo : [{ number: '' }],
		ShortDescription: profileData?.ShortText ? profileData?.ShortText : '',
		LongDescription: profileData?.Description ? profileData?.Description : '',
		AppStoreURL: profileData?.AppStoreURL ? profileData?.AppStoreURL : '',
		PlayStoreURL: profileData?.PlayStoreURL ? profileData?.PlayStoreURL : '',
		LinkedInURL: profileData?.LinkedInURL ? profileData?.LinkedInURL : '',
		FacebookURL: profileData?.FaceBookURL ? profileData?.FaceBookURL : '',
		InstagramURL: profileData?.InstagramURL ? profileData?.InstagramURL : '',
		TwitterURL: profileData?.TwitterURL ? profileData?.TwitterURL : '',
		Location: profileData?.AddressForMap ? profileData?.AddressForMap : '',
		Latitude: profileData?.Lattitude ? profileData?.Lattitude : '',
		Longitude: profileData?.Longitude ? profileData?.Longitude : '',
		Radius: profileData?.Radius ? profileData?.Radius : '',
		PickupTimeinminute: profileData?.PickupTime ? profileData?.PickupTime : '',
		DeliveryTimeinminute: profileData?.DeliveryTime ? profileData?.DeliveryTime : '',
		MinimumOrderPrice: profileData?.MinimumOrderPrice ? profileData?.MinimumOrderPrice : '',
		DeliveryType: {
			delivery: profileData?.DeliveryType && profileData?.DeliveryType?.includes('1') ? true : false,
			pickup: profileData?.DeliveryType && profileData?.DeliveryType?.includes('2') ? true : false,
			dine_in: profileData?.DeliveryType && profileData?.DeliveryType?.includes('3') ? true : false,
		},
		isOffline: profileData?.IsOffline == '1' ? true : false,
		IsGiveContactFree: profileData?.IsGiveContactFree == '1' ? true : false,
		IsDriverTipEnabled: profileData?.IsDriverTipEnabled == '1' ? true : false,
		Fully_Paid: profileData?.FullyPaid == '1' ? true : false,
		HeaderLogoImageName: profileData?.HeaderLogo_URL ? profileData?.HeaderLogo_URL : '',
		LogoImageName: profileData?.LogoUrl ? profileData?.LogoUrl : '',
		bannerImageName: profileData?.Home_Banner ? profileData?.Home_Banner : ''
	}

	const onsubmit = (value) => {
		dispatch(EditRestProfiletApi(value, (ress) => {
			setTimeout(() => {
				dispatch(getProfileData((res) => {
					userDetails.HeaderLogo_URL = res.data.Profile.HeaderLogo_URL
					localStorage.setItem('userDetails', JSON.stringify(userDetails))
					dispatch(loginConfirmedAction(userDetails));
					setProfileData(res.data.Profile)
					setFlag(false)
				}))
			}, 10);
		}))
	}


	return (
		<Fragment>
			<div className="row">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">{t('profile.title')}</h4>
							<Button onClick={handleModalOpen}>{t('buttons.changepassword')}</Button>
						</div>
						<div className="card-body">
							<div className="profile-tab">
								<div className="custom-tab-1">
									<Tab.Container defaultActiveKey='Restaurant Profile'>
										<Nav as='ul' className="nav nav-tabs">
											<Nav.Item as='li' className="nav-item">
												<Nav.Link to="#Restaurant_Profile" eventKey='Restaurant Profile'>{t('profile.tab.restaurantprofile')}</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li' className="nav-item">
												<Nav.Link to="#Restaurant_Images" eventKey='Restaurant Images'>{t('profile.tab.restaurantimages')}</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li' className="nav-item">
												<Nav.Link to="#Restaurant_Links" eventKey='Restaurant Links'>{t('profile.tab.restaurantlinks')}</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li' className="nav-item">
												<Nav.Link to="#Restaurant_Address" eventKey='Restaurant Address'>{t('profile.tab.restaurantaddress')}</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li' className="nav-item">
												<Nav.Link to="#Restaurant_Settings" eventKey='Restaurant Settings'>{t('profile.tab.restaurantsettings')}</Nav.Link>
											</Nav.Item>
										</Nav>
										<Tab.Content>
											<Tab.Pane id="Restaurant_Profile" eventKey='Restaurant Profile'>
												{!flag && <RestaurantProfile InitialValues={InitialValues} onsubmit={onsubmit} />}
											</Tab.Pane>
											<Tab.Pane id="Restaurant_Images" eventKey='Restaurant Images'>
												{!flag && <RestaurantImages InitialValues={InitialValues} profileData={profileData} onsubmit={onsubmit} />}
											</Tab.Pane>
											<Tab.Pane id="Restaurant_Links" eventKey='Restaurant Links'>
												{!flag && <RestaurantLinks InitialValues={InitialValues} onsubmit={onsubmit} />}
											</Tab.Pane>
											<Tab.Pane id="Restaurant_Address" eventKey='Restaurant Address'>
												{!flag && <RestaurantAddress InitialValues={InitialValues} onsubmit={onsubmit} />}
											</Tab.Pane>
											<Tab.Pane id="Restaurant_Settings" eventKey='Restaurant Settings'>
												{!flag && <RestaurantSettings InitialValues={InitialValues} onsubmit={onsubmit} />}
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{openModal && <ChangePassword openModal={openModal} setOpenModal={setOpenModal} />}
		</Fragment>
	);
};

export default AppProfile;
