import { Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addEditModifierItemApi } from '../../../../services/modifierservices';
import { symbolsArr } from '../../../../constants/constants';


const ModifierItemAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData, modifierTypeList } = props


    const onsubmit = (values) => {
        if (editData && editData.ID) {
            values.ItemId = editData.ID
        }
        dispatch(addEditModifierItemApi(values, (res) => {
            setOpenModal(false);
            setEditData()
        }))

    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()

    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                initialValues={{
                    ProductTypeId: editData ? editData.ProductTypeId : '',
                    ItemName: editData ? editData.ItemName : '',
                    ItemPrice: editData ? editData.Price : '',
                    SupplierPrice: editData ? editData.SupplierPrice : '',
                    SortDescription: editData ? editData.SortDescription : '',
                    item_calories: editData ? editData.item_calories : '',
                    IsActive: editData ? (editData.IsActive == '1' ? true : false) : true,
                }}

                validationSchema={
                    Yup.object().shape({
                        ProductTypeId: Yup.string().required("modifier Type  is required."),
                        ItemName: Yup.string().required("Name  is required."),
                    })

                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('modifieritemscreen.updatemodifieritem') : t('modifieritemscreen.addmodifieritem')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="demo-simple-select"
                                                    value={values.ProductTypeId}
                                                    label={t('modifieritemscreen.modifiertype')}
                                                    name='ProductTypeId'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.ProductTypeId) && touched.ProductTypeId}
                                                    helperText={Boolean(errors.ProductTypeId) && touched.ProductTypeId ? errors.ProductTypeId : ""}
                                                >
                                                    <MenuItem disabled value=''>Select Modifier</MenuItem>
                                                    {modifierTypeList.length > 0 && modifierTypeList.map((item, index) => {
                                                        return <MenuItem key={index} value={item.ID}>{item.Type}</MenuItem>
                                                    })}

                                                </TextField>

                                            </FormControl>
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.ItemName) && touched.ItemName}
                                                helperText={(Boolean(errors.ItemName) && touched.ItemName) ? errors.ItemName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="ItemName"
                                                label={t('modifieritemscreen.modifieritems')}
                                                value={values.ItemName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="ItemPrice"
                                                label={t('modifieritemscreen.price')}
                                                value={values.ItemPrice}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="SupplierPrice"
                                                label={t('modifieritemscreen.supplierprice')}
                                                value={values.SupplierPrice}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                multiline
                                                rows={4}
                                                error={Boolean(errors.SortDescription) && touched.SortDescription}
                                                helperText={(Boolean(errors.SortDescription) && touched.SortDescription) ? errors.SortDescription : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="SortDescription"
                                                label={t('modifieritemscreen.sortdescription')}
                                                value={values.SortDescription}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                type='number'
                                                fullWidth
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                onBlur={handleBlur}
                                                name="item_calories"
                                                label={t('modifieritemscreen.itemcalories')}
                                                value={values.item_calories}
                                                onChange={handleChange}
                                            />
                                            <FormHelperText>Note: <span className='text-red'>If 0 then it shows '0 Kcal' in restaurant</span></FormHelperText>
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.IsActive} />}
                                                label={t('modifieritemscreen.available')}
                                                name="IsActive"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default ModifierItemAddEditModal
