import { TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AutoComplete from './AutoComplete';
import MapComp from './Map';



const RestaurantAddress = (props) => {
    const {t} =  useTranslation('common');
    const { InitialValues, onsubmit } = props
    let tempValue = InitialValues
    const [position, setPosition] = useState({
        lat: InitialValues && Number(InitialValues.Latitude),
        lng: InitialValues && Number(InitialValues.Longitude)
    })
    const [location, setLocation] = useState(tempValue && tempValue.Location)

    const handleSubmit = (data) =>{
        data.Latitude = position.lat;
        data.Longitude = position.lng;
        data.Location = location;
        onsubmit(data)
    }

    return (
        <>
            <div className="pt-3">
                <div className="settings-form">
                    <h4 className="text-primary p-3">{t('profile.tab.restaurantaddress')}</h4>
                    <Formik
                        initialValues={tempValue}
                        onSubmit={handleSubmit}
                    >
                        {(props) => {
                            const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                            return (
                                <Form onSubmit={handleSubmit} noValidate>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <AutoComplete setPosition={setPosition} location={location} setLocation={setLocation}/>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <TextField
                                                error={Boolean(errors.Latitude) && touched.Latitude}
                                                helperText={(Boolean(errors.Latitude) && touched.Latitude) ? errors.Latitude : ''}
                                                fullWidth
                                                type='number'
                                                onBlur={handleBlur}
                                                name="Latitude"
                                                label={t('profile.restaurantaddress.latitude')}
                                                value={position.lat ? position.lat : values.Latitude}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <TextField
                                                error={Boolean(errors.Longitude) && touched.Longitude}
                                                helperText={(Boolean(errors.Longitude) && touched.Longitude) ? errors.Longitude : ''}
                                                fullWidth
                                                type='number'
                                                onBlur={handleBlur}
                                                name="Longitude"
                                                label={t('profile.restaurantaddress.longitude')}
                                                value={ position.lng ? position.lng:values.Longitude}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-lg-12">

                                            <MapComp position={position} />
                                        </div>
                                    </div>

                                    <div className="mt-3 d-flex float-right align-items-center justify-content-end">
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            size="large"
                                            disabled={!isValid || isDisabled}
                                        >
                                            {t('buttons.save')}
                                        </button>
                                    </div>

                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default RestaurantAddress
