import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MailchimpUrl } from '../../../../constants/constants'
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom'
import { disconnectMailchimpAPi, getMailchimpStatusApi } from '../../../../services/mailchimpServices'
import { useDispatch } from 'react-redux'

const Mailchimp = () => {
    const { t } = useTranslation('common')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [connectedFlag, setConnectedFlag] = useState(false)
    let userData = JSON.parse(localStorage.getItem('userDetails'))

    useEffect(() => {
        let obj = {
            restaurant_id: userData.restaurant_id
        }
        dispatch(getMailchimpStatusApi(obj, (res) => {
            if (res.data && res.data.mailchimp_access_token) {
                setConnectedFlag(true)
            }
        }))
    }, [])

    const handleRedirect = () => {
        let redirectUrl = MailchimpUrl.replace('{{state}}', btoa(userData.user_id))
        window.open(redirectUrl, '_self')
    }

    const handleEdit = () => {
        navigate('/mailchimp/customers')
    }

    const handleDisconnect = () => {
        let obj = {
            user_id: userData.user_id
        }
        swal({
            title: `You are about to disconnect this Mailchimp account from ${userData.restaurant_name}.`,
            text:
                `This action is permanent and cannot be undone. To complete this disconnection, you will also need to disconnect ${userData.restaurant_name} from within your settings in Mailchimp. Your data will continue to exist in ${userData.restaurant_name} and Mailchimp, but updates will no longer sync.\n\nIf you want to use this Mailchimp account with ${userData.restaurant_name} in the future, you will need to connect and set it up again.`,
            buttons: {
                cancel: "Don't Disconnect",
                confirm: 'Disconnect Mailchimp'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Mailchimp successfully disconnected", {
                    icon: "success",
                });
                dispatch(disconnectMailchimpAPi(obj, (res) => {
                    setConnectedFlag(false)
                }))
            }
        })
    }

    return (
        <div className='card'>
            <div className="card-header">
                <h4 className="card-title">{t('appscreen.title')}</h4>
            </div>
            <div className="card-body">
                <div className="table-responsive">

                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col"> {t('appscreen.name')}</th>
                                <th scope="col"> {t('appscreen.action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <th className='align-middle w-100'>Mailchimp</th>
                                <td>
                                    {connectedFlag ?
                                        <>
                                            <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit()} > <i className="fas fa-pencil-alt"></i></button>
                                            <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDisconnect()} > <i className="fa fa-trash"></i></button>
                                        </>
                                        :
                                        <Button size="large" onClick={handleRedirect}>
                                            {t('appscreen.connect')}
                                        </Button>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Mailchimp
