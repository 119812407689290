import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const RefundHistoryModal = (props) => {
    const { openRefundHistoryModal, setOpenRefundHistoryModal, orderDetails, refundData } = props
    const { t } = useTranslation('common')

    const handleRefundHistoryModalClose = () => {
        setOpenRefundHistoryModal(false)
    }


    return (
        <>
            <Modal
                className="fade bd-example-modal-lg"
                show={openRefundHistoryModal}
                // onHide={handleRefundHistoryModalClose}
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title>{t('ordermasterscreen.refundhistory')}(#{orderDetails.TransactionId})</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={handleRefundHistoryModalClose}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>

                    <table className="table">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">{t('ordermasterscreen.refunddate')}</th>
                                <th scope="col">{t('ordermasterscreen.reason')}</th>
                                <th scope="col">{t('ordermasterscreen.total')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails && orderDetails.RefundHistory.length > 0 && orderDetails.RefundHistory.map((item, index) => {
                                return <tr key={index}>
                                    <td>{t("date_format", { date: item.CreatedDateTime })}</td>
                                    <td>{item.CustomerNote}</td>
                                    <td>{t("currency", { Number: parseFloat(item.RefundAmount) })}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <h4 className='text-end'>
                        {t('ordermasterscreen.totalpaid')}: {t("currency", { Number: parseFloat(refundData.RefundAmount) })}
                    </h4>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default RefundHistoryModal
