import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { cancelOrderApi, getOrderCountByStatusApi, getOrderDetailsApi, updateOrderStatusApi } from '../../../../services/orderService';
import { useState } from 'react';
import { reasonCharLimit } from '../../../../constants/constants';

const CancleRejectModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, text, orderDetails, setOrderDetails, setTabName } = props


    const handleModalClose = () => {
        setOpenModal(false);
    }

    const onsubmit = (values) => {
        if (text == 'Reject') {
            let obj = {
                "OrderId": orderDetails.OrderId,
                "OrderStatus": '200',
                "ReasonCancel": values.leaveNote,
                "DeliveryMode": orderDetails.DeliveryMode,
                // "CrossReference": orderDetails.CrossReference,
                "CustomerId": orderDetails.Customer.CustomerId,
                "Reason": values.reason,
                "SubReason": ""
            }
            dispatch(updateOrderStatusApi(obj, (ress) => {
                const objs = { "OrderId": orderDetails.OrderId, "UserId": "0" }
                setTabName('Other')
                dispatch(getOrderCountByStatusApi((res) => { }))
                setTimeout(() => {
                    dispatch(getOrderDetailsApi(objs, (res) => {
                        setOrderDetails(res.data)
                    }))
                }, 500);
                handleModalClose()
            }))
        } else {
            let obj = {
                "YesNo": 'Yes',
                "OrderId": orderDetails.OrderId,
                "UserId": orderDetails.Customer.CustomerId,
                "Reason": values.reason,
                "SubReason": "",
                "ReasonCancel": values.leaveNote,
                "DeliveryMode": orderDetails.DeliveryMode,
                // "CrossReference": orderDetails.CrossReference,
            }
            dispatch(cancelOrderApi(obj, (ress) => {
                const objs = { "OrderId": orderDetails.OrderId, "UserId": "0" }
                setTabName('Other')
                setTimeout(() => {
                    dispatch(getOrderDetailsApi(objs, (res) => {
                        setOrderDetails(res.data)
                    }))
                }, 500);
                handleModalClose()
            }))
        }

    }

    return (
        <>
            <Modal
                className="fade bd-example-modal-lg"
                show={openModal}
                // onHide={handleModalClose}
                size="md"
                centered
            >
                <Formik
                    validateOnChange={false}
                    initialValues={{
                        reason: '',
                        leaveNote: ''
                    }}

                    validationSchema={
                        Yup.object().shape({
                            reason: Yup.string().required("Please Select reason."),
                        })

                    }
                    onSubmit={onsubmit}
                >
                    {(props) => {
                        const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                        return (
                            <>
                                <Form onSubmit={handleSubmit} noValidate>
                                    <Modal.Header>
                                        <Modal.Title>{text} Order</Modal.Title>
                                        <Button
                                            variant=""
                                            className="btn-close"
                                            onClick={handleModalClose}
                                        >

                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h3 className='text-center'>{text == "Reject" ? t('ordermasterscreen.whydoyouwanttorejectit') : t('ordermasterscreen.whydoyouwanttocancelit')}</h3>
                                        <p className='fs-16 text-center'>{t('ordermasterscreen.weneedtoletthecustomerknow')}.</p>
                                        <FormControl>
                                            <FormHelperText className="Mui-error">{Boolean(errors.reason) || touched.reason ? errors.reason : ""}</FormHelperText>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                onChange={handleChange}
                                                name="reason"
                                                value={values.reason}
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label={t('ordermasterscreen.reason1')} />
                                                <FormControlLabel value="2" control={<Radio />} label={t('ordermasterscreen.reason2')} />
                                                <FormControlLabel value="3" control={<Radio />} label={t('ordermasterscreen.reason3')} />
                                                <FormControlLabel value="4" control={<Radio />} label={t('ordermasterscreen.reason4')} />
                                                <FormControlLabel value="5" control={<Radio />} label={t('ordermasterscreen.reason5')} />
                                                <FormControlLabel value="6" control={<Radio />} label={t('ordermasterscreen.reason6')} />
                                            </RadioGroup>

                                        </FormControl>
                                        <TextField
                                            inputProps={{ maxLength: reasonCharLimit }}
                                            fullWidth
                                            onBlur={handleBlur}
                                            name="leaveNote"
                                            label={t('ordermasterscreen.leaveanote')}
                                            value={values.leaveNote}
                                            onChange={(e) => { handleChange(e); }}
                                        />
                                        <p className='text-end'>{`${reasonCharLimit - values.leaveNote.length}/${reasonCharLimit}`}</p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="danger light" onClick={handleModalClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type='submit'>
                                            {text} Order
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </>
                        );
                    }}
                </Formik>


            </Modal >
        </>
    )
}

export default CancleRejectModal
