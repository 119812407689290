import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//import ApexCharts from 'apexcharts';
//import { Link } from "react-router-dom";

const AreaChart = (props) => {
    const { graphData, customerData } = props
    const { t } = useTranslation('common')
    const [xaxisData, setXAxisData] = useState([])
    const [yaxisData, setYAxisData] = useState([])
    const [customerRepeatRate, setCustomerRepeatRate] = useState([])

    useEffect(() => {
        let tempXaxisData = []
        let tempYaxisData = []
        let tempcustomerData = []
        if (graphData != {}) {
            for (let i = 0; i < Object.keys(graphData).length; i++) {
                const element = Object.keys(graphData)[i];
                let dayName = moment(element).format('dd')
                let letter = dayName.charAt(0)
                tempXaxisData.push(letter)
                tempYaxisData.push(graphData[element].delivered)
            }
            setYAxisData(tempYaxisData)
            setXAxisData(tempXaxisData)
        }
        if (customerData != {}) {
            for (let i = 0; i < Object.keys(customerData).length; i++) {
                const element = Object.keys(customerData)[i];
                tempcustomerData.push((customerData[element].customer_repeat_rate))
            }
            setCustomerRepeatRate(tempcustomerData)
        }


    }, [graphData])


    return (
        <div id="chartBar5">
            <ReactApexChart options={{
                chart: {
                    height: 350,
                    type: "area",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [2, 2],
                    colors: ["#fc8019", "#709fba"],
                    curve: "straight",
                },
                tooltip: {
                    enabled: true,
                    marker: {
                        show: true,
                        fillColors: ["#fc8019", "#709fba"],
                    },
                },
                legend: {
                    show: false
                },
                markers: {
                    size: 0,
                    border: 0,
                    colors: ["#fc8019", "#709fba"],
                    hover: {
                        size: 4,
                    },
                },
                xaxis: {
                    categories: xaxisData,
                },
                yaxis: {
                    show: false,
                },
                fill: {
                    colors: ["#fc8019", "#709fba"],
                    type: "solid",
                    opacity: 0.4,
                },
                grid: {
                    show: false,
                },

            }} series={[
                {
                    name: t('reporting.fullfilledorders'),
                    data: yaxisData,
                },
                {
                    name: t('reporting.customerrepeate'),
                    data: customerRepeatRate,
                },
            ]} type="area" height={300} />
        </div>
    );
}

export default AreaChart;