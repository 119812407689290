import React, { createContext, useEffect, useState, useReducer } from "react";
import { dezThemeSet } from './ThemeDemo';
import { useDispatch, useSelector } from "react-redux";
import { AddEditLocalizationSettingsApi, getThemesettingsApi } from "../services/CommonServices";

export const ThemeContext = createContext();
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});




const ThemeContextProvider = (props) => {
  const Dispatch = useDispatch()
  const [flag, setFlag] = useState(false)
  const { themeConfig } = useSelector((state) => state.localization)
  const initialState = {
    sideBarStyle: "full",
    sidebarposition: "fixed",
    headerposition: "fixed",
    sidebarLayout: "vertical",
    direction: "ltr",
    primaryColor: "color_1",
    secondaryColor: "color_1",
    navigationHader: "color_3",
    headerColor: "color_3",
    sidebarColor: "color_1",
    background: 'light',
    containerPositionSize: "wide-boxed",
    iconHover: false,
    menuToggle: false,
    windowWidth: 0,
    windowHeight: 0,
    typography: "roboto",
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    sideBarStyle,
    sidebarposition,
    headerposition,
    sidebarLayout,
    direction,
    primaryColor,
    secondaryColor,
    navigationHader,
    headerColor,
    sidebarColor,
    background,
    containerPositionSize,
    iconHover,
    menuToggle,
    typography,
    windowWidth,
    windowHeight,
  } = state;


  let tempTheme = {
    'primaryColor': localStorage.getItem('primaryColor') ? localStorage.getItem('primaryColor') : (themeConfig.primaryColor ? themeConfig.primaryColor : "color_1"),
    'secondaryColor': localStorage.getItem('secondaryColor') ? localStorage.getItem('secondaryColor') : (themeConfig.secondaryColor ? themeConfig.secondaryColor : "color_1"),
    'navigationHader': localStorage.getItem('navigationHader') ? localStorage.getItem('navigationHader') : (themeConfig.navigationHader ? themeConfig.navigationHader : "color_3"),
    'headerColor': localStorage.getItem('headerColor') ? localStorage.getItem('headerColor') : (themeConfig.headerColor ? themeConfig.headerColor : "color_3"),
    'sidebarColor': localStorage.getItem('sidebarColor') ? localStorage.getItem('sidebarColor') : (themeConfig.sidebarColor ? themeConfig.sidebarColor : "color_1"),
    'sidebarposition': localStorage.getItem('sidebarposition') ? localStorage.getItem('sidebarposition') : (themeConfig.sidebarposition ? themeConfig.sidebarposition : "fixed"),
    'sidebarLayout': localStorage.getItem('sidebarLayout') ? localStorage.getItem('sidebarLayout') : (themeConfig.sidebarLayout ? themeConfig.sidebarLayout : "vertical"),
    'sideBarStyle': localStorage.getItem('sideBarStyle') ? localStorage.getItem('sideBarStyle') : (themeConfig.sideBarStyle ? themeConfig.sideBarStyle : 'full'),
    'headerposition': localStorage.getItem('headerposition') ? localStorage.getItem('headerposition') : (themeConfig.headerposition ? themeConfig.headerposition : "fixed"),
    'containerPositionSize': localStorage.getItem('containerPositionSize') ? localStorage.getItem('containerPositionSize') : (themeConfig.containerPositionSize ? themeConfig.containerPositionSize : "wide"),
    'typography': localStorage.getItem('typography') ? localStorage.getItem('typography') : (themeConfig.typography ? themeConfig.typography : "roboto"),
    'background': localStorage.getItem('background') ? localStorage.getItem('background') : (themeConfig.background ? themeConfig.background : "light")
  }
  const [themeObject, setThemeObject] = useState(tempTheme)

  useEffect(() => {
    setThemeObject(tempTheme)
  }, [flag])

  useEffect(() => {
    if (flag) {
      let finalObj = {
        type: 'themeSettings',
        value: JSON.stringify(themeObject)
      }
      Dispatch(AddEditLocalizationSettingsApi(finalObj, (res) => {
        setFlag(false)
      }))
    }
  }, [themeObject])

  const body = document.querySelector("body");
  // layout
  const layoutOption = [
    { value: "vertical", label: "Vertical" },
    { value: "horizontal", label: "Horizontal" },
  ];
  const sideBarOption = [
    { value: "compact", label: "Compact" },
    { value: "full", label: "Full" },
    { value: "mini", label: "Mini" },
    { value: "modern", label: "Modern" },
    { value: "overlay", label: "Overlay" },
    { value: "icon-hover", label: "Icon-hover" },
  ];
  const backgroundOption = [
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];
  const sidebarpositions = [
    { value: "fixed", label: "Fixed" },
    { value: "static", label: "Static" },
  ];
  const headerPositions = [
    { value: "fixed", label: "Fixed" },
    { value: "static", label: "Static" },
  ];
  const containerPosition = [
    { value: "wide-boxed", label: "Wide Boxed" },
    { value: "boxed", label: "Boxed" },
    { value: "wide", label: "Wide" },
  ];
  const colors = [
    "color_1",
    "color_2",
    "color_3",
    "color_4",
    "color_5",
    "color_6",
    "color_7",
    "color_8",
    "color_9",
    "color_10",
    "color_11",
    "color_12",
    "color_13",
    "color_14",
    "color_15",
  ];
  const directionPosition = [
    { value: "ltr", label: "LTR" },
    { value: "rtl", label: "RTL" },
  ];
  const fontFamily = [
    { value: "poppins", label: "Poppins" },
    { value: "roboto", label: "Roboto" },
    { value: "nunito", label: "Nunito" },
    { value: "opensans", label: "Open Sans" },
    { value: "HelveticaNeue", label: "HelveticaNeue" },
  ];
  const changePrimaryColor = (name) => {
    localStorage.setItem('primaryColor', name)
    dispatch({ primaryColor: name });
    body.setAttribute("data-primary", name);
    setThemeObject({
      ...themeObject,
      'primaryColor': name
    })
    setFlag(true)
  };

  const changeSecondaryColor = (name) => {
    dispatch({ secondaryColor: name });
    body.setAttribute("data-secondary", name);
    setThemeObject({
      ...themeObject,
      'secondaryColor': name
    })
    setFlag(true)
  };

  const changeNavigationHader = (name) => {
    localStorage.setItem('navigationHader', name)
    dispatch({ navigationHader: name });
    body.setAttribute("data-nav-headerbg", name);
    setThemeObject({
      ...themeObject,
      'navigationHader': name
    })
    setFlag(true)
  };

  const chnageheaderColor = (name) => {
    localStorage.setItem('headerColor', name)
    dispatch({ headerColor: name });
    body.setAttribute("data-headerbg", name);
    setThemeObject({
      ...themeObject,
      'headerColor': name
    })
    setFlag(true)
  };

  const chnageSidebarColor = (name) => {
    localStorage.setItem('sidebarColor', name)
    dispatch({ sidebarColor: name });
    body.setAttribute("data-sibebarbg", name);
    setThemeObject({
      ...themeObject,
      'sidebarColor': name
    })
    setFlag(true)
  };

  const changeSideBarPostion = (name) => {
    localStorage.setItem('sidebarposition', name.target.value)
    dispatch({ sidebarposition: name.target.value });
    body.setAttribute("data-sidebar-position", name.target.value);
    setThemeObject({
      ...themeObject,
      'sidebarposition': name.target.value
    })
    setFlag(true)
  };

  const changeDirectionLayout = (name) => {
    dispatch({ direction: name.target.value });
    body.setAttribute("direction", name.target.value);
    let html = document.querySelector("html");
    html.setAttribute("dir", name.target.value);
    html.className = name.target.value;
  };

  const changeSideBarLayout = (name) => {
    localStorage.setItem('sidebarLayout', name.target.value)
    if (name.target.value === "horizontal") {
      if (sideBarStyle === "overlay") {
        dispatch({ sidebarLayout: name.target.value });
        body.setAttribute("data-layout", name.target.value);
        dispatch({ sideBarStyle: 'full' });
        body.setAttribute("data-sidebar-style", "full");
        localStorage.setItem('sideBarStyle', 'full')
        setThemeObject({
          ...themeObject,
          'sideBarStyle': 'full'
        })
        setFlag(true)
      } else {
        dispatch({ sidebarLayout: name.target.value });
        body.setAttribute("data-layout", name.target.value);
      }
    } else {
      dispatch({ sidebarLayout: name.target.value });
      body.setAttribute("data-layout", name.target.value);
    }
    setThemeObject({
      ...themeObject,
      'sidebarLayout': name.target.value
    })
    setFlag(true)
  };

  const changeSideBarStyle = (name) => {
    localStorage.setItem('sideBarStyle', name.target.value)
    if (sidebarLayout === "horizontal") {
      if (name.target.value === "overlay") {
        alert("Sorry! Overlay is not possible in Horizontal layout.");
      } else {
        dispatch({ sideBarStyle: name.target.value });
        dispatch({ iconHover: name.target.value === "icon-hover" ? "_i-hover" : "" });
        body.setAttribute("data-sidebar-style", name.target.value);
      }
    } else {

      dispatch({ sideBarStyle: name.target.value });

      dispatch({ iconHover: name.target.value === "icon-hover" ? "_i-hover" : "" });
      body.setAttribute("data-sidebar-style", name.target.value);
    }
    setThemeObject({
      ...themeObject,
      'sideBarStyle': name.target.value
    })
    setFlag(true)
  };

  const changeHeaderPostion = (name) => {
    localStorage.setItem('headerposition', name.target.value)
    dispatch({ headerposition: name.target.value });
    body.setAttribute("data-header-position", name.target.value);
    setThemeObject({
      ...themeObject,
      'headerposition': name.target.value
    })
    setFlag(true)
  };

  const openMenuToggle = () => {

    sideBarStyle.value === "overly"
      ? dispatch({ menuToggle: true })
      : dispatch({ menuToggle: false })
  };

  const changeBackground = (name) => {
    localStorage.setItem('background', name.target.value)
    body.setAttribute("data-theme-version", name.target.value);
    dispatch({ background: name.target.value });
    setThemeObject({
      ...themeObject,
      'background': name.target.value
    })
    setFlag(true)
  };

  const changeTypography = (name) => {
    localStorage.setItem('typography', name.target.value)
    body.setAttribute("data-typography", name.target.value);
    dispatch({ typography: name.target.value });
    setThemeObject({
      ...themeObject,
      'typography': name.target.value
    })
    setFlag(true)
  };

  const changeContainerPosition = (name) => {
    localStorage.setItem('containerPositionSize', name.target.value)
    dispatch({ containerPositionSize: name.target.value });
    body.setAttribute("data-container", name.target.value);
    if (name.target.value === "boxed") {
      let obj = {
        target: {
          value: "overlay"
        }
      }
      changeSideBarStyle(obj);
    }
    setThemeObject({
      ...themeObject,
      'containerPositionSize': name.target.value
    })
    setFlag(true)
  };

  useEffect(() => {
    dispatch({ primaryColor: localStorage.getItem('primaryColor') ? localStorage.getItem('primaryColor') : (themeConfig.primaryColor ? themeConfig.primaryColor : 'color_1') });
    dispatch({ navigationHader: localStorage.getItem('navigationHader') ? localStorage.getItem('navigationHader') : (themeConfig.navigationHader ? themeConfig.navigationHader : 'color_3') });
    dispatch({ headerColor: localStorage.getItem('headerColor') ? localStorage.getItem('headerColor') : (themeConfig.headerColor ? themeConfig.headerColor : 'color_3') });
    dispatch({ sidebarColor: localStorage.getItem('sidebarColor') ? localStorage.getItem('sidebarColor') : (themeConfig.sidebarColor ? themeConfig.sidebarColor : 'color_1') });
    dispatch({ sidebarposition: localStorage.getItem('sidebarposition') ? localStorage.getItem('sidebarposition') : (themeConfig.sidebarposition ? themeConfig.sidebarposition : 'fixed') });
    dispatch({ sidebarLayout: localStorage.getItem('sidebarLayout') ? localStorage.getItem('sidebarLayout') : (themeConfig.sidebarLayout ? themeConfig.sidebarLayout : 'vertical') });
    dispatch({ sideBarStyle: localStorage.getItem('sideBarStyle') ? localStorage.getItem('sideBarStyle') : (themeConfig.sideBarStyle ? themeConfig.sideBarStyle : 'overlay') });
    dispatch({ headerposition: localStorage.getItem('headerposition') ? localStorage.getItem('headerposition') : (themeConfig.headerposition ? themeConfig.headerposition : 'fixed') });
    dispatch({ containerPositionSize: localStorage.getItem('containerPositionSize') ? localStorage.getItem('containerPositionSize') : (themeConfig.containerPositionSize ? themeConfig.containerPositionSize : 'poppins') });
    dispatch({ typography: localStorage.getItem('typography') ? localStorage.getItem('typography') : (themeConfig.typography ? themeConfig.typography : 'wide') });
    dispatch({ background: localStorage.getItem('background') ? localStorage.getItem('background') : (themeConfig.background ? themeConfig.background : 'light') });
  }, [themeConfig])


  useEffect(() => {
    const body = document.querySelector("body");
    body.setAttribute("data-primary", localStorage.getItem('primaryColor') ? localStorage.getItem('primaryColor') : 'color_1');
    body.setAttribute("data-nav-headerbg", localStorage.getItem('navigationHader') ? localStorage.getItem('navigationHader') : 'color_3');
    body.setAttribute("data-sibebarbg", localStorage.getItem('sidebarColor') ? localStorage.getItem('sidebarColor') : 'color_1');
    body.setAttribute("data-headerbg", localStorage.getItem('headerColor') ? localStorage.getItem('headerColor') : 'color_3');
    body.setAttribute("data-theme-version", localStorage.getItem('background') ? localStorage.getItem('background') : 'light');
    body.setAttribute("data-layout", localStorage.getItem('sidebarLayout') ? localStorage.getItem('sidebarLayout') : 'vertical');
    body.setAttribute("data-header-position", localStorage.getItem('headerposition') ? localStorage.getItem('headerposition') : 'fixed');
    body.setAttribute("data-sidebar-style", localStorage.getItem('sideBarStyle') ? localStorage.getItem('sideBarStyle') : 'overlay');
    body.setAttribute("data-sidebar-position", localStorage.getItem('sidebarposition') ? localStorage.getItem('sidebarposition') : 'fixed');
    body.setAttribute("data-container", localStorage.getItem('containerPositionSize') ? localStorage.getItem('containerPositionSize') : 'wide');
    body.setAttribute("data-typography", localStorage.getItem('typography') ? localStorage.getItem('typography') : "poppins");
    body.setAttribute("data-secondary", "color_1");
    body.setAttribute("direction", "ltr");
    let resizeWindow = () => {

      dispatch({ windowWidth: window.innerWidth });

      dispatch({ windowHeight: window.innerHeight });
      window.innerWidth >= 768 && window.innerWidth < 1024
        ? body.setAttribute("data-sidebar-style", "mini")
        : window.innerWidth <= 768
          ? body.setAttribute("data-sidebar-style", "overlay")
          : body.setAttribute("data-sidebar-style", "full");
    };
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        body,
        sideBarOption,
        layoutOption,
        backgroundOption,
        sidebarposition,
        headerPositions,
        containerPosition,
        directionPosition,
        fontFamily,
        primaryColor,
        secondaryColor,
        navigationHader,
        windowWidth,
        windowHeight,
        changePrimaryColor,
        changeSecondaryColor,
        changeNavigationHader,
        changeSideBarStyle,
        sideBarStyle,
        changeSideBarPostion,
        sidebarpositions,
        changeHeaderPostion,
        headerposition,
        changeSideBarLayout,
        sidebarLayout,
        changeDirectionLayout,
        changeContainerPosition,
        direction,
        colors,
        headerColor,
        chnageheaderColor,
        chnageSidebarColor,
        sidebarColor,
        iconHover,
        menuToggle,
        openMenuToggle,
        changeBackground,
        background,
        //containerPosition_,
        changeTypography,
        typography,
        containerPositionSize,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;